import { CustomModal } from 'components/common/Modal';
import React from 'react';

const MaintenanceModeModal = ({ open, setOpen }) => {
    const onClose = () => setOpen(false);
    return (
        <CustomModal
            open={open}
            onClose={onClose}
            maintainMode={true}
            title={'SITE UNDER MAINTENANCE'}
        >
            <div>Site is currently under maintenance</div>
        </CustomModal>
    );
};

export default MaintenanceModeModal;
