import { CustomModal } from 'components/common/Modal';
import styles from './shareActivity.module.scss';
import { Button, styled } from '@mui/material';
import { copyToClipboard } from 'helper/copyToClipboard';
import { useSnackBar } from 'hoc/snackbar';
import { useLocation } from 'react-router-dom';

const StyledButton = styled(Button)(({ bg }) => ({
    background: bg,
    textTransform: 'Capitalize',
    color: bg === 'none' ? '#1F62AF' : '#fff',
    borderRadius: '5px',
    width: 'fit-content',
    height: '41px',
    fontSize: 18,
    '&:hover': {
        background: bg,
    },
    '@media (max-width: 476px)': {
        fontSize: '15px !important',
    },
}));

const ShareActivityModal = ({ open, setOpen }) => {
    const { snackBarInfo } = useSnackBar();
    const onClose = () => setOpen(false);

    const handleCopyLink = () => {
        copyToClipboard(window.location.href);
        snackBarInfo('The link to the current search results has been copied to the clipboard');
    };

    const handleWhatsappLink = () => {
        alert(window.location.href);
        window.open(`https://wa.me/?text=${window.location.href}`, '_blank');
    };

    const handleMessengerLink = () => {
        window.open(`https://m.me/?ref=${window.location.href}`, '_blank');
    };

    return (
        <CustomModal open={open} onClose={onClose} title={'Share'}>
            <div className={styles.actions__box}>
                <p className={styles.share__infoText}>
                    Choose how you want to share these search results.
                </p>
                <div className={styles.action__links}>
                    <StyledButton bg="none" onClick={handleCopyLink}>
                        Copy link
                    </StyledButton>
                    <StyledButton bg="none" onClick={handleWhatsappLink}>
                        Share with Whatsapp
                    </StyledButton>
                    <StyledButton bg="none" onClick={handleMessengerLink}>
                        Share with Facebook
                    </StyledButton>
                </div>
            </div>
        </CustomModal>
    );
};

export default ShareActivityModal;
