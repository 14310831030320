import { Box, Modal, Fade, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import styles from './modal.module.scss';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // minWidth: 500,
    width: 680,
    maxHeight: 580,
    bgcolor: 'background.paper',
    background: '#FFFFFF',
    borderRadius: '20px',
    p: 4,
    overflowY: 'scroll',
    border: 'none',
    outline: 'none',
    backdropFilter: 'blur(7px)',

    '&::-webkit-scrollbar': {
        display: 'none',
    },

    '@media only screen and (max-width: 768px)': {
        width: 'calc(100% - 20px)',
    },
};

export const CustomModal = ({ open, onClose, title, children, maintainMode }) => {
    const handleClose = () => {
        onClose();
    };

    const disableBackdropCloseHandle = (event, reason) => {
        if (reason && reason == 'backdropClick') return;
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={maintainMode ? disableBackdropCloseHandle : handleClose}
            closeAfterTransition
            style={{ backdropFilter: maintainMode ? 'blur(7px)' : 'blur(0px)' }}
            disableBackdropClick={maintainMode}
        >
            <Fade in={open}>
                <Box sx={style}>
                    {title && (
                        <>
                            <Box paddingY={'8px'}>
                                <h5 className={styles.modalTitle}>{title}</h5>
                                {!maintainMode && (
                                    <button className={styles.modalClose} onClick={onClose}>
                                        &times;
                                    </button>
                                )}
                            </Box>
                            <Divider />
                        </>
                    )}
                    <Box className={styles.modalChildren}>{children}</Box>
                </Box>
            </Fade>
        </Modal>
    );
};

CustomModal.propsType = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.string,
    childrenWrapperClassName: PropTypes.string,
    disabled: PropTypes.bool,
};
