import React, { useContext, useEffect, useState } from 'react';
import styles from './ratingStats.module.scss';
import { StoreContext } from 'store/useStore';
import axios from 'axios';
import actions from 'store/action';
import { useSnackBar } from 'hoc/snackbar';
import likeLogo from 'assets/images/like-3-1.svg';
import { Link, useNavigate } from 'react-router-dom';
import routes from 'constants/routes';

const BASE_URI = process.env['REACT_APP_BASE_URI'];
// const totalActivities = 3639;

const RatingStats = ({ totalActivities }) => {
    const [loading, setLoading] = useState(false);
    const [myActivity, setMyActivity] = useState();
    const [likedActivity, setLikedActivity] = useState();
    const [doneActivity, setDoneActivty] = useState();
    const { state, dispatch } = useContext(StoreContext);
    const { user } = state;
    const { snackBarError, snackBarSuccess, snackBarInfo } = useSnackBar();
    const [likeRatedWidth, setLikeRatedWidth] = useState('0%');
    const [doneRatedWidth, setDoneRatedWidth] = useState('0%');
    const navigate = useNavigate();
    console.log(totalActivities, ':totalac')

    const logout = async () => {
        dispatch({
            type: actions.SET_USER,
            payload: null,
        });
        localStorage.removeItem('jwt');
        localStorage.removeItem('user');
        // handleClose();
    };

    const getUserActivity = async () => {
        try {
            setLoading(true);
            const jwt = localStorage.getItem('jwt');
            const options = {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            };
            const res = await axios.get(`${BASE_URI}/activities/my_activities`, options);
            const resultData = res.data;
            console.log(resultData);
            const liked = resultData.data.filter((item) => item.like !== null);
            const likedPer = ((liked?.length || 0) / totalActivities) * 100;
            console.log(likedPer ,':likedPercentage')
            setLikeRatedWidth(`${likedPer}%`);
            setLikedActivity(liked);
            const done = resultData.data.filter((item) => item.frequency !== null);
            const donePer = ((done?.length || 0) / totalActivities) * 100;
            setDoneRatedWidth(`${donePer}%`);
            setDoneActivty(done);
            // const currActivity = resultData.data.find((item) => item.activity.id === data.id);
            // setMyActivity(resultData.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.message[0]?.error == 'Expired Token') {
                logout();
                // navigate(routes.LOGIN);
                snackBarError(
                    'Your session has been expired. Please login again or continue as guest .',
                );
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        if (state?.user) {
            getUserActivity();
        }
    }, [state.user]);

    console.log(likedActivity, ':li');
    console.log(doneActivity, ':fy');
    return (
        <div className={styles.RatingStats}>
            {user ? (
                <>
                    <h2>Rate more activities</h2>
                    <div className={styles.RatingStats__Item}>
                        <div className={styles.RatingStats__LikeProgress}>
                            <div
                                className={styles.RatingStats__ProgressLine}
                                style={{ width: likeRatedWidth }}
                            ></div>
                        </div>
                        <div className={styles.RatingStats__Info}>
                            <strong>Like:</strong>
                            <span>
                                Rated {likedActivity ? likedActivity?.length : 0} activities
                            </span>
                        </div>
                        <div
                            className={styles.RatingStats__RatingActivity}
                            onClick={() => navigate(`/${user?.username}/rating_like`)}
                        >
                            Rate activities
                        </div>
                    </div>
                    <div className={styles.RatingStats__Item}>
                        <div className={styles.RatingStats__LikeProgress}>
                            <div
                                className={styles.RatingStats__ProgressLine}
                                style={{ width: likeRatedWidth }}
                            ></div>
                        </div>
                        <div className={styles.RatingStats__Info}>
                            <strong>Done:</strong>
                            <span>Rated {doneActivity ? doneActivity?.length : 0} activities</span>
                        </div>
                        <div
                            className={styles.RatingStats__RatingActivity}
                            onClick={() => navigate(`/${user?.username}/rating_done`)}
                        >
                            Rate activities
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <h2>Sign Up for Free</h2>
                    <div className={styles.notUserState}>
                        <div className={styles.notUserState__logo}>
                            <img src={likeLogo} alt="" />
                        </div>
                        <div className={styles.notUserState__info}>
                            <p>Sign up to rate activities and connect with friends.</p>
                            <Link to={routes.LOGIN} className={styles.signUpLink}>
                                Sign up
                            </Link>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default RatingStats;
