import React from 'react';

import styles from './commonBoxWrapper.module.scss';

const CommonBoxWrapper = ({ children, style, classname }) => {
    return (
        <div className={[styles.wrapper, classname ? classname : ''].join(' ')} style={style}>
            {children}
        </div>
    );
};

export default CommonBoxWrapper;
