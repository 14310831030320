import React, { useContext } from 'react';
import styles from './chats.module.scss';
import chatIcon from 'assets/images/chatIcon.png';
import Messages from 'components/Messages';
import { StoreContext } from 'store/useStore';

const Chats = ({ receiverData }) => {
    const { state } = useContext(StoreContext);
    console.log(receiverData, ':receiver');
    return (
        <div className={styles.Chats}>
            <div className={styles.Chats__Header}>
                <img src={chatIcon} alt="" />
                <h3>{receiverData ? receiverData.username : state.user?.username}</h3>
            </div>
            {!receiverData && (
                <p className={styles.Chats__InfoText}>
                    Select users from sidebar to begin the chat.
                </p>
            )}
            <Messages receiverData={receiverData} />
        </div>
    );
};

export default Chats;
