import { CustomModal } from 'components/common/Modal';
import styles from './likeRatingModal.module.scss';
import { friendsNumber, ratingNumbers } from 'constants/ratingsScale';

import { AiFillDislike } from 'react-icons/ai';
import { GiShrug } from 'react-icons/gi';
import { AiFillLike } from 'react-icons/ai';
import { BsFillHeartFill } from 'react-icons/bs';
import { BsFire } from 'react-icons/bs';
import { MdPublic } from 'react-icons/md';
import { FaUsers } from 'react-icons/fa';
import { HiUsers } from 'react-icons/hi2';
import { BiSolidUser } from 'react-icons/bi';
import { BiSolidLockAlt } from 'react-icons/bi';
import { useContext, useState } from 'react';
import { StoreContext } from 'store/useStore';
import actions from 'store/action';
import { useSnackBar } from 'hoc/snackbar';
import axios from 'axios';
import routes from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import CustomButton from 'components/common/Button/CustomButton';
import { Public, ArrowDropDown } from '@mui/icons-material';

const BASE_URI = process.env['REACT_APP_BASE_URI'];

const LikeRatingModal = ({
    data,
    open,
    setOpen,
    myActivity,
    setMyActivity,
    setCounter,
    isProfile,
    updateRatings,
    setIsLike
}) => {
    const { state, dispatch } = useContext(StoreContext);
    const { snackBarError, snackBarSuccess, snackBarInfo } = useSnackBar();
    const navigate = useNavigate();

    const [openSocialRating, setOpenSocialRating] = useState(false);

    const likeContentImg = {
        1: <AiFillDislike />,
        2: <GiShrug />,
        3: <AiFillLike />,
        4: <BsFillHeartFill />,
        5: <BsFire />,
    };

    const socialRatingImg = {
        1: <MdPublic />,
        2: <FaUsers />,
        3: <HiUsers />,
        4: <BiSolidUser />,
        5: <BiSolidLockAlt />,
    };
    console.log(data, ';:data');
    const activityName = data?.activity !== undefined ? data.activity.name : data?.name;
    const activityId = data?.activity !== undefined ? data.activity.id : data?.id;
    // const activityId =

    console.log(myActivity, ':acti');

    const onClose = () => setOpen(false);

    const setLoading = (value, msg) => {
        dispatch({
            type: actions.SET_GLOABL_LOADING,
            payload: {
                loading: value,
                text: msg ? msg : '',
            },
        });
    };

    const logout = async () => {
        dispatch({
            type: actions.SET_USER,
            payload: null,
        });
        localStorage.removeItem('jwt');
        localStorage.removeItem('user');
        // handleClose();
    };

    const getRatedActivity = async () => {
        try {
            setLoading(true);
            const jwt = localStorage.getItem('jwt');
            const options = {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            };
            const res = await axios.get(`${BASE_URI}/activities/my_activities`, options);
            const resultData = res.data;
            const currActivity = resultData.data.find((item) => item.activity.id === activityId);
            setMyActivity(currActivity);
            setLoading(false);
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.message[0]?.error == 'Expired Token') {
                logout();
                navigate(routes.LOGIN);
                snackBarError(
                    'Your session has been expired. Please Login again to continue as User or continue as guest.',
                );
            }
            setLoading(false);
        }
    };

    const handleLike = async (e) => {
        try {
            if (!state.user) throw 'Please login first';
            setLoading(true, 'Rating activity...');
            // const activityId = activity.id;
            const payload = {
                like: e,
            };
            const jwt = localStorage.getItem('jwt');
            const options = {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': 'application/json',
                },
            };
            const res = await axios.post(
                `${BASE_URI}/activities/${activityId}/like`,
                payload,
                options,
            );
            if (!(res.status >= 200 && res.status < 400)) return res.statusText;
            const resData = res.data;
            const isPrevRated = myActivity?.activity?.id === activityId && myActivity?.like != null;
            await getRatedActivity();
            if (isProfile) {
                await updateRatings();
            }
            setCounter((prev) => {
                return {
                    ...prev,
                    like_counter: isPrevRated ? prev.like_counter : prev.like_counter + 1,
                };
            });
            setIsLike && setIsLike(true);
            snackBarSuccess(`Successfully rated ${resData.data.activity.name}`);
            console.log(res);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            // snackBarError(`Error: ${JSON.stringify(error?.message)}`);

            if (error?.response?.data?.message[0]?.error == 'Expired Token') {
                logout();
                navigate(routes.LOGIN);
                snackBarError('Your session has been expired. Please Login again to continue.');
            } else {
                snackBarError(error.toString());
            }
        }
    };

    const handleSocial = async (rating) => {
        try {
            if (!state.user) throw 'Please login first';
            setLoading(true, 'Rating activity...');
            const activityId = data.id;
            const payload = {
                social: rating,
            };
            const jwt = localStorage.getItem('jwt');
            const options = {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': 'application/json',
                },
            };
            const res = await axios.post(
                `${BASE_URI}/activities/${activityId}/social`,
                payload,
                options,
            );
            if (!(res.status >= 200 && res.status < 400)) return res.statusText;
            const resData = res.data;
            await getRatedActivity();
            if (isProfile) {
                await updateRatings();
            }
            snackBarSuccess(`Successfully rated ${resData.data.activity.name}`);
            // await getStats(activityId);
            console.log(res);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            // snackBarError(`Error: ${JSON.stringify(error)}`);
            if (error?.response?.data?.message[0]?.error == 'Expired Token') {
                navigate(routes.LOGIN);
                snackBarError('Your session has been expired. Please Login again to continue.');
            } else {
                snackBarError(error.toString());
            }
        }
    };

    return (
        <CustomModal open={open} onClose={onClose} title={activityName}>
            <div className={styles.actions__grid}>
                {Object.keys(ratingNumbers)
                    .sort((keyA, keyB) => keyB - keyA)
                    .map((key, idx) => (
                        <div
                            className={[
                                styles.rating__items,
                                myActivity?.like == key ? styles.active : '',
                            ].join(' ')}
                            onClick={() => handleLike(key)}
                            key={key}
                        >
                            <div className={styles.icon}>{likeContentImg[key]}</div>
                            <p>{ratingNumbers[key]}</p>
                        </div>
                        // <span title={ratingNumbers[key]} onClick={() => onClick(key)} key={key}>
                        //     {likeContentImg[key]}
                        // </span>
                    ))}
            </div>
            <div className={styles.rating__actions}>
                {/* <CustomButton
                    onClick={() => setOpenSocialRating((prev) => !prev)}
                    bg={'#DADADA'}
                    customColor={'#000'}
                    customWidth={'70px'}
                >
                    <div className={styles.button__inner}>
                        <Public />
                        <ArrowDropDown />
                    </div>
                </CustomButton> */}
                <CustomButton onClick={onClose} bg={'#1F62AF'} customColor={'#FFF'}>
                    Save
                </CustomButton>
            </div>
            {/* {openSocialRating && (
                <div className={styles.social__ratingContainer}>
                    {Object.keys(friendsNumber)
                        .sort((keyA, keyB) => keyB - keyA)
                        .map((key, idx) => (
                            <div
                                className={[
                                    styles.rating__items,
                                    myActivity?.social == key ? styles.active : '',
                                ].join(' ')}
                                onClick={() => handleSocial(key)}
                                key={key}
                            >
                                <div className={styles.icon}>{socialRatingImg[key]}</div>
                                <p>{friendsNumber[key]}</p>
                            </div>
                        ))}
                </div>
            )} */}
        </CustomModal>
    );
};

export default LikeRatingModal;
