import React, { useState, useEffect, useContext, useCallback } from 'react';
import styles from './filter.module.scss';
import { styled } from '@mui/material/styles';
import {
    Button,
    TextField,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    Paper,
    Autocomplete,
    IconButton,
    Select,
    MenuItem,
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import routes from 'constants/routes';
import formik, { Formik, useFormik } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { StoreContext } from 'store/useStore';
import queryString from 'query-string';
import {
    disabilitiesTags,
    environmentsOptions,
    equipmentsTags,
    goalsTags,
    locationTags,
} from 'constants/tagSelectValues';
import moment from 'moment';
import actions from 'store/action';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import debounce from 'lodash.debounce';
import { fetchPlace } from 'helper/fetchPlace';

const StyledTextField = styled(TextField)`
    .MuiInputBase-root {
        background-color: #f2f2f2;
        border: none;
        border-radius: 5px;
        padding: 0px 10px;
        -webkit-appearance: none;
        box-sizing: border-box;
        min-height: 41px;
        width: 100%;

        /* &:focus {
            outline: none;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
        } */
    }

    & .MuiOutlinedInput-root {
        fieldset {
            border: none;
        }
        &:hover fieldset {
            border: none;
        }
        &.Mui-focused fieldset {
            border-color: #fff;
            outline: none;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
        }
    }

    .MuiInputBase-input {
        padding: 0;
        font-family: 'Arimo', sans-serif;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0;
    }

    .Mui-error {
        font-family: 'Arimo', sans-serif;
        color: #ec4453;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
    }
`;

const StyledSelect = styled(Select)`
    width: 100%;
    font-family: 'Arimo', sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    background-color: #f2f2f2;
    padding: 0px 10px;
    -webkit-appearance: none;
    box-sizing: border-box;
    min-height: 41px !important;

    .MuiInputBase-input {
        padding: 0;
        font-family: 'Arimo', sans-serif;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0;
        background-color: #f2f2f2;
        border: none;
        border-radius: 5px;
        padding: 0px 10px;
        -webkit-appearance: none;
        box-sizing: border-box;
        min-height: 41px !important;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .MuiOutlinedInput-root {
        width: 100%;
    }

    & .MuiOutlinedInput-notchedOutline {
        border: none;
        fieldset {
            border: none;
        }
        &:hover fieldset {
            border: none;
        }
        &.Mui-focused fieldset {
            border-color: #fff;
            outline: none;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
        }
    }

    .Mui-error {
        font-family: 'Arimo', sans-serif;
        color: #ec4453;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
    }
`;

const StyledMenuItem = styled(MenuItem)`
    /* .MuiMenuItem-root {
        background-color: var(--input-surface-primary-default);
        border-radius: 8px !important;
        font-family: 'Plus Jakarta Sans', san-serif;
        padding: 12px 18px;
    }
    .MuiButtonBase-root {
        background-color: var(--input-surface-primary-default);
        border-radius: 8px !important;
        font-family: 'Plus Jakarta Sans', san-serif;
        padding: 12px 18px;
    } */
`;

const StyledButton = styled(Button)(({ bg, customFontWeight }) => ({
    background: bg,
    textTransform: 'Capitalize',
    color: bg === 'none' ? '#1F62AF' : '#fff',
    borderRadius: '5px',
    width: 'fit-content',
    height: '50px',
    fontSize: 18,
    fontWeight: customFontWeight ?? 400,
    '@media (max-width: 476px)': {
        fontSize: '15px !important',
    },
}));

// const DATA_API = 'example.com'
const WEATHER_API_KEY = process.env['REACT_APP_WEATHER_API_KEY'];
const WEATHER_BASE_URI = 'https://api.openweathermap.org/data/2.5/forecast';
const BASE_URI = process.env['REACT_APP_BASE_URI'];

const Filter = () => {
    const [expand, setExpand] = useState(false);
    const { state, dispatch } = useContext(StoreContext);
    const { userLocation, user } = state;
    const location = useLocation();
    const [presetFilterValues, setPresetFilterValues] = useState(
        JSON.parse(localStorage.getItem('filterValues')) || '',
    );
    const navigate = useNavigate();
    const [autocompleteCities, setAutocompleteCities] = useState([]);
    const [coordinates, setCoordinates] = useState([
        userLocation?.longitude,
        userLocation?.latitude,
    ]);
    const [autocompleteErr, setAutocompleteErr] = useState('');
    const [weatherInfo, setWeatherInfo] = useState(null);

    const setCurrTime = (date) => {
        let currTime = moment(date).format('hh:mm a');
        return currTime;
    };

    const getAge = () => {
        const birthdate = user?.user_detail?.birthdate
            ? new Date(user?.user_detail?.birthdate)
            : null;
        if (!birthdate) return 0;
        const currDate = new Date();
        const ageDiff = currDate - birthdate;
        const ageDate = new Date(ageDiff);
        const age = Math.abs(ageDate.getUTCFullYear() - 1970);
        return age;
    };

    const initialValues = {
        date: new Date(),
        startTime: new Date(),
        place: userLocation?.city || '',
        distance: '',
        distanceCheck: true,
        locationType: [],
        numberOfperson: '',
        personAge: getAge() || '',
        personWeight: user?.user_detail.weight || '',
        personFitness: user?.user_detail.fitness || '',
        personMinAge: '',
        personMaxAge: '',
        personMinWeight: '',
        personMaxWeight: '',
        personMinFitness: '',
        personMaxFitness: '',
        temperature: '',
        visibility: '',
        wind: '',
        minBudget: [],
        maxBudget: [],
        goals: [],
        disabilities: [],
        locations: [],
        equipments: [],
        equipmentsCheck: true,
        budgetCheck: true,
        personCheck: true,
        tags: [],
        searchTerm: '',
        searchTermCheck: true,
        goalsCheck: true,
        tagsCheck: true,
        currentDateChecked: true,
        currentTimeChecked: true,
        currentLocationCheck: true,
        placesCheck: false,
        environmentChecks: true,
        currentAgeCheck: true,
        currentFitnessCheck: true,
        currentTemperatureCheck: true,
        currentVisibilityCheck: true,
        currentWindCheck: true,
        currentWeightCheck: true,
        // lat: userLocation?.longitude || '',
        // lon: userLocation?.longitude || '',
        timeString: moment(new Date()).format('HH:mm'),
    };

    const formik = useFormik({
        initialValues,
        onSubmit: (values) => {
            let stTime = moment(values.startTime).format('HH:mm');
            console.log(stTime, ':time');
            handleDataSubmit(values, stTime);
        },
    });

    const getPayload = (values, stTime) => {
        let payload = {
            search_term: values.searchTerm,
            Goals: values.goals,
            Places: values.locations,
            Location: values.place,
            Equipment: values.equipments,
            Budget: Number(values.maxBudget),
            Age: Number(values.personAge),
            Latitude: Number(coordinates[1]),
            Longitude: Number(coordinates[0]),
            Weight: Number(values.personWeight),
            Fitness: Number(values.personFitness),
            Temperature: Number(values.temperature),
            Wind: Number(values.wind),
            Weather: values.visibility ? [values.visibility] : [],
            LocationType: values.locationType,
            People: Number(values.numberOfperson),
            'Age Min': Number(values.personMinAge),
            'Age Max': Number(values.personMaxAge),
            'Weight Min': Number(values.personMinWeight),
            'Weight Max': Number(values.personMaxWeight),
            Disabilities: values.disabilities,
            Time: stTime,
        };

        return payload;
    };

    const queryParametersFromPayload = (payload) => {
        const filterPayload = Object.entries(payload).reduce((acc, [key, value]) => {
            if (value !== '' && value !== 0 && !(Array.isArray(value) && value.length === 0)) {
                acc[key] = value;
            }
            return acc;
        }, {});
        return filterPayload;
    };

    const handleDataSubmit = async (values, stTime) => {
        try {
            console.log(values);
            setLoading(true, 'Submitting form...');
            let payload = getPayload(values, stTime);
            const queryPayload = queryParametersFromPayload(payload);
            let options = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const res = await axios.post(`${BASE_URI}/activities`, queryPayload, options);
            if (res.status !== 200) throw res.statusText;
            let saveDataToLocalStorage = {
                ...values,
                coordinates,
            };
            localStorage.setItem('filterValues', JSON.stringify(saveDataToLocalStorage));
            localStorage.setItem('coordinates', JSON.stringify(coordinates));

            const { data } = res;
            console.log(data);
            dispatch({
                type: actions.SET_DATA,
                payload: {
                    data: data.data,
                    totalActivities: data.total_rows
                },
            });
            dispatch({
                type: actions.SET_LOCATION,
                payload: {
                    city: formik.values.place,
                    latitude: coordinates[1],
                    longitude: coordinates[0],
                },
            });
            // dispatch({
            //     type: actions.CLEAR_SEARCH_DATA,
            // });
            // const queryPayload = queryParametersFromPayload(payload);
            // console.log(queryPayload, ':qurry');
            const queryString = new URLSearchParams(queryPayload).toString();
            setLoading(false);
            navigate(`/?${queryString}`);
        } catch (error) {
            setLoading(false);
        }
    };

    const getVisibilityInitialValue = (value) => {
        console.log(value);
        switch (value.toLowerCase()) {
            case 'clouds':
                return 'cloudy';
            case 'rain':
                return 'rainy';
            case 'clear':
                return 'clearsky';
            default:
                break;
        }
    };

    const setLoading = (value, msg) => {
        dispatch({
            type: actions.SET_GLOABL_LOADING,
            payload: {
                loading: value,
                text: msg ? msg : '',
            },
        });
    };

    const debounceBack = useCallback(
        debounce(async (newValue) => {
            const res = await fetchPlace(newValue);
            console.log(res);
            !autocompleteCities.includes(newValue) &&
                res.features &&
                setAutocompleteCities(res.features.map((place) => place.place_name));
            setCoordinates(res.features[0]?.geometry?.coordinates);
            res.error ? setAutocompleteErr(res.error) : setAutocompleteErr('');
        }, 1000),
        [],
    );

    const handleCityChange = (e) => {
        formik.setFieldValue('place', e.target.value);
        debounceBack(e.target.value);
    };

    const getCurrentData = (data) => {
        let enteredDate = moment(formik.values.date).format('MM-DD-YYYY');
        let currData = data.list.find((item) => {
            let dtDate = moment(item.dt_txt).format('MM-DD-YYYY');
            return dtDate == enteredDate;
        });
        return currData;
    };

    const getCelcius = (k) => {
        let c = Number(k) - 273.15;
        return c.toFixed(0);
    };

    const getWeatherDetails = async () => {
        try {
            setLoading(true, 'Fetching Weather Details...');
            const url = queryString.stringifyUrl({
                url: WEATHER_BASE_URI,
                query: {
                    appid: WEATHER_API_KEY,
                    lon: coordinates.length > 0 && coordinates[0],
                    lat: coordinates.length > 0 && coordinates[1],
                },
            });
            const res = await axios.get(url);
            if (res.status !== 200) throw res.statusText;
            const data = res.data;
            let todayData = getCurrentData(data);
            setWeatherInfo({
                temperature: getCelcius(todayData.main.temp),
                wind: todayData.wind.speed,
                visibility: getVisibilityInitialValue(todayData.weather[0].main),
            });
            // formik.setFieldValue('temperature', getCelcius(todayData.main.temp));
            // formik.setFieldValue('wind', todayData.wind.speed);
            // setVisibilityInitialalue(todayData.weather[0].main);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const getMaxDate = () => {
        let currDate = new Date();
        let maxDate = moment(currDate, 'DD-MM-YYYY').add('days', 5);
        console.log(new Date(maxDate));
        return new Date(maxDate);
    };

    const handleReset = () => {
        localStorage.removeItem('filterValues');
        formik.resetForm({});
        formik.setTouched(false);
        formik.handleReset();

        Object.keys(formik.values).forEach((key) => {
            console.log(key);
            if (
                formik.values[key] !== '' &&
                !Array.isArray(formik.values[key]) &&
                key !== 'place' &&
                key !== 'date'
            ) {
                formik.setFieldValue(key, '');
            }
            if (Array.isArray(formik.values[key])) {
                formik.setFieldValue(key, []);
            }
        });

        // formik.setFieldValue('locationType', []);
    };
    useEffect(() => {
        getWeatherDetails();
    }, [formik.values.date, coordinates]);

    return (
        <div className={styles.filter}>
            <div className={styles.filter__container}>
                <h3 className={styles.page__title}>Search things to do</h3>
                {/* <p className={styles.primary__text}>
                    The filters are used, to filter the activities. If no value is defined, default
                    values are used.{' '}
                </p> */}
                <form onSubmit={formik.handleSubmit}>
                    <div className={styles.filter__form}>
                        <div className={styles.form__box}>
                            <label className={styles.form__label}>What</label>
                            <div className={styles.form__control}>
                                <p className={styles.label__primary}>Search term</p>
                                <div className={styles.checkbox__field}>
                                    <input
                                        type="checkbox"
                                        name="searchTermCheck"
                                        checked={formik.values.searchTermCheck}
                                        onChange={() => {
                                            formik.setFieldValue(
                                                'searchTermCheck',
                                                !formik.values.searchTermCheck,
                                            );
                                            formik.setFieldValue('searchTerm', '');
                                        }}
                                    />
                                    <label
                                        htmlFor="searchTermCheck"
                                        className={styles.label__primary}
                                    >
                                        Don&apos;t filter by search term
                                    </label>
                                </div>
                                {!formik.values.searchTermCheck && (
                                    <div className={styles.form__field}>
                                        <input
                                            type="text"
                                            placeholder="Enter a search term (e.g. walking)"
                                            name="searchTerm"
                                            value={formik.values.searchTerm}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={styles.form__control}>
                                <p className={styles.label__primary}>Goals</p>
                                <div className={styles.checkbox__field}>
                                    <input
                                        type="checkbox"
                                        name="goalsCheck"
                                        checked={formik.values.goalsCheck}
                                        onChange={() => {
                                            formik.setFieldValue(
                                                'goalsCheck',
                                                !formik.values.goalsCheck,
                                            );
                                            formik.setFieldValue('goals', []);
                                        }}
                                    />
                                    <label htmlFor="goalsCheck" className={styles.label__primary}>
                                        Don&apos;t filter by goals
                                    </label>
                                </div>
                                {!formik.values.goalsCheck && (
                                    <Autocomplete
                                        sx={{ minWidth: '50%' }}
                                        multiple
                                        id="tags-standard-goals"
                                        options={goalsTags}
                                        getOptionLabel={(option) => option}
                                        value={formik.values.goals}
                                        onChange={(e, newValue) => {
                                            formik.setFieldValue('goals', newValue);
                                        }}
                                        renderInput={(params) => (
                                            <StyledTextField
                                                {...params}
                                                placeholder="Choose goals (e.g. relaxing)"
                                            />
                                        )}
                                    />
                                )}
                            </div>
                            <div className={styles.form__control}>
                                <p className={styles.label__primary}>Tags</p>
                                <div className={styles.checkbox__field}>
                                    <input
                                        type="checkbox"
                                        name="tagsCheck"
                                        checked={formik.values.tagsCheck}
                                        onChange={() => {
                                            formik.setFieldValue(
                                                'tagsCheck',
                                                !formik.values.tagsCheck,
                                            );
                                            formik.setFieldValue('tags', []);
                                        }}
                                    />
                                    <label htmlFor="tagsCheck" className={styles.label__primary}>
                                        Don&apos;t filter by tags
                                    </label>
                                </div>
                                {!formik.values.tagsCheck && (
                                    <Autocomplete
                                        sx={{ minWidth: '50%' }}
                                        multiple
                                        id="tags-standard-tags"
                                        options={goalsTags}
                                        getOptionLabel={(option) => option}
                                        freeSolo
                                        value={formik.values.tags}
                                        onChange={(e, newValue) => {
                                            formik.setFieldValue('tags', newValue);
                                        }}
                                        renderInput={(params) => (
                                            <StyledTextField
                                                {...params}
                                                placeholder="Choose tags (e.g. sports)"
                                            />
                                        )}
                                    />
                                )}
                            </div>
                        </div>

                        <div className={styles.form__box}>
                            <label className={styles.form__label}>When</label>
                            <div className={styles.form__control}>
                                <p className={styles.label__primary}>Date</p>
                                <div className={styles.checkbox__field}>
                                    <input
                                        type="checkbox"
                                        name="currentDateChecked"
                                        checked={formik.values.currentDateChecked}
                                        onChange={() => {
                                            formik.setFieldValue(
                                                'currentDateChecked',
                                                !formik.values.currentDateChecked,
                                            );
                                            formik.setFieldValue('date', new Date());
                                        }}
                                    />
                                    <label
                                        htmlFor="currentDateChecked"
                                        className={styles.label__primary}
                                    >
                                        Use Current Date
                                    </label>
                                </div>
                                {!formik.values.currentDateChecked && (
                                    <div className={styles.form__field}>
                                        <DatePicker
                                            selected={formik.values.date}
                                            minDate={new Date()}
                                            maxDate={getMaxDate()}
                                            onChange={(date) => formik.setFieldValue('date', date)}
                                            placeholderText="Select the date"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={styles.form__control}>
                                <p className={styles.label__primary}>Time</p>
                                <div className={styles.checkbox__field}>
                                    <input
                                        type="checkbox"
                                        name="currentTimeChecked"
                                        checked={formik.values.currentTimeChecked}
                                        onChange={() => {
                                            formik.setFieldValue(
                                                'currentTimeChecked',
                                                !formik.values.currentTimeChecked,
                                            );
                                            formik.setFieldValue('startTime', new Date());
                                        }}
                                    />
                                    <label htmlFor="time" className={styles.label__primary}>
                                        Use Current time
                                    </label>
                                </div>
                                {!formik.values.currentTimeChecked && (
                                    <div className={styles.form__field}>
                                        <DatePicker
                                            selected={formik.values.startTime}
                                            onChange={(date) => {
                                                formik.setFieldValue('startTime', date);
                                                formik.setFieldValue(
                                                    'timeString',
                                                    moment(date).format('HH:mm'),
                                                );
                                            }}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                            placeholderText="Select the time"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={styles.form__box}>
                            <label className={styles.form__label}>Where</label>
                            <div className={styles.form__control}>
                                <p className={styles.label__primary}>Location</p>
                                <div className={styles.checkbox__field}>
                                    <input
                                        type="checkbox"
                                        name="currentLocationCheck"
                                        checked={formik.values.currentLocationCheck}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'currentLocationCheck',
                                                !formik.values.currentLocationCheck,
                                            );
                                            formik.setFieldValue(
                                                'place',
                                                e.target.checked ? userLocation?.city : '',
                                            );
                                        }}
                                    />
                                    <label htmlFor="currLocation" className={styles.label__primary}>
                                        Use device location
                                    </label>
                                </div>
                                {!formik.values.currentLocationCheck && (
                                    <div className={styles.form__field}>
                                        <input
                                            type="text"
                                            placeholder="Enter location (e.g. Berlin, Germany)"
                                            name="place"
                                            value={formik.values.place}
                                            onChange={handleCityChange}
                                            // pattern={autocompleteCities.join('|')}
                                            autoComplete="off"
                                            list="places"
                                        />
                                        <datalist id="places">
                                            {autocompleteCities?.map((city, i) => (
                                                <option key={i}>{city}</option>
                                            ))}
                                        </datalist>
                                        {autocompleteErr && <p>{autocompleteErr}</p>}
                                    </div>
                                )}
                            </div>
                            <div className={styles.form__control}>
                                <p className={styles.label__primary}>Places</p>
                                <div className={styles.checkbox__field}>
                                    <input
                                        type="checkbox"
                                        name="placesCheck"
                                        checked={formik.values.placesCheck}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'placesCheck',
                                                !formik.values.placesCheck,
                                            );
                                            formik.setFieldValue(
                                                'locations',
                                                e.target.checked ? [] : [],
                                            );
                                        }}
                                    />
                                    <label htmlFor="currLocation" className={styles.label__primary}>
                                        Use places from map
                                    </label>
                                </div>
                                {!formik.values.placesCheck && (
                                    <Autocomplete
                                        sx={{ minWidth: '50%' }}
                                        multiple
                                        id="tags-standard-location"
                                        options={locationTags}
                                        getOptionLabel={(option) => option}
                                        value={formik.values.locations}
                                        onChange={(e, newValue) => {
                                            formik.setFieldValue('locations', newValue);
                                        }}
                                        renderInput={(params) => (
                                            <StyledTextField
                                                {...params}
                                                placeholder="Choose Places"
                                            />
                                        )}
                                    />
                                )}
                            </div>
                            <div className={styles.form__control}>
                                <p className={styles.label__primary}>Radius in km</p>
                                <div className={styles.checkbox__field}>
                                    <input
                                        type="checkbox"
                                        name="distanceCheck"
                                        checked={formik.values.distanceCheck}
                                        onChange={() => {
                                            formik.setFieldValue(
                                                'distanceCheck',
                                                !formik.values.distanceCheck,
                                            );
                                            formik.setFieldValue('distance', '5');
                                        }}
                                    />
                                    <label
                                        htmlFor="distanceCheck"
                                        className={styles.label__primary}
                                    >
                                        Use 5km radius
                                    </label>
                                </div>
                                {!formik.values.distanceCheck && (
                                    <div className={styles.form__field}>
                                        <input
                                            type="text"
                                            placeholder="Enter radius in km (e.g. 20)"
                                            name="distance"
                                            value={formik.values.distance}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={styles.form__control}>
                                <p className={styles.label__primary}>Environment</p>
                                <div className={styles.checkbox__field}>
                                    <input
                                        type="checkbox"
                                        name="environmentChecks"
                                        checked={formik.values.environmentChecks}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'environmentChecks',
                                                !formik.values.environmentChecks,
                                            );
                                            formik.setFieldValue(
                                                'locationType',
                                                e.target.checked ? ['indoor', 'outdoor'] : [],
                                            );
                                        }}
                                    />
                                    <label htmlFor="currLocation" className={styles.label__primary}>
                                        Use indoor and outdoor
                                    </label>
                                </div>
                                {!formik.values.environmentChecks && (
                                    <Autocomplete
                                        sx={{ minWidth: '50%' }}
                                        multiple
                                        id="tags-standard-locationType"
                                        options={environmentsOptions}
                                        getOptionLabel={(option) => option}
                                        value={formik.values.locationType}
                                        onChange={(e, newValue) => {
                                            formik.setFieldValue('locationType', newValue);
                                        }}
                                        renderInput={(params) => (
                                            <StyledTextField
                                                {...params}
                                                placeholder="Indoor,Outdoor"
                                            />
                                        )}
                                    />
                                )}
                            </div>
                        </div>

                        <div className={styles.form__box}>
                            <label className={styles.form__label}>Who</label>
                            <div className={styles.form__control}>
                                <p className={styles.label__primary}>Age</p>
                                <div className={styles.checkbox__field}>
                                    <input
                                        type="checkbox"
                                        name="currentAgeCheck"
                                        checked={formik.values.currentAgeCheck}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'currentAgeCheck',
                                                !formik.values.currentAgeCheck,
                                            );
                                            formik.setFieldValue(
                                                'personAge',
                                                e.target.checked ? getAge() : '',
                                            );
                                        }}
                                    />
                                    <label htmlFor="age" className={styles.label__primary}>
                                        Use profile age
                                    </label>
                                </div>
                                {!formik.values.currentAgeCheck && (
                                    <div className={styles.form__field}>
                                        <input
                                            type="text"
                                            placeholder="Enter age (e.g. 42)"
                                            name="personAge"
                                            value={formik.values.personAge}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={styles.form__control}>
                                <p className={styles.label__primary}>Weight</p>
                                <div className={styles.checkbox__field}>
                                    <input
                                        type="checkbox"
                                        name="currentWeightCheck"
                                        checked={formik.values.currentWeightCheck}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'currentWeightCheck',
                                                !formik.values.currentWeightCheck,
                                            );
                                            formik.setFieldValue(
                                                'personWeight',
                                                e.target.checked
                                                    ? user?.user_detail.weight || 0
                                                    : '',
                                            );
                                        }}
                                    />
                                    <label htmlFor="age" className={styles.label__primary}>
                                        Use profile weight
                                    </label>
                                </div>
                                {!formik.values.currentWeightCheck && (
                                    <div className={styles.form__field}>
                                        <input
                                            type="text"
                                            placeholder="Enter weight in kg (e.g. 72)"
                                            name="personWeight"
                                            value={formik.values.personWeight}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={styles.form__control}>
                                <p className={styles.label__primary}>Fitness</p>
                                <div className={styles.checkbox__field}>
                                    <input
                                        type="checkbox"
                                        name="currentFitnessCheck"
                                        checked={formik.values.currentFitnessCheck}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'currentFitnessCheck',
                                                !formik.values.currentFitnessCheck,
                                            );
                                            formik.setFieldValue(
                                                'personFitness',
                                                e.target.checked
                                                    ? user?.user_detail?.fitness || 0
                                                    : '',
                                            );
                                        }}
                                    />
                                    <label htmlFor="weight" className={styles.label__primary}>
                                        Use profile fitness
                                    </label>
                                </div>
                                {!formik.values.currentFitnessCheck && (
                                    <div className={styles.form__field}>
                                        {/* <input
                                            type="text"
                                            placeholder="Enter Fitness value"
                                            name="personFitness"
                                            value={formik.values.personFitness}
                                            onChange={formik.handleChange}
                                        /> */}
                                        <StyledSelect
                                            name="personFitness"
                                            value={formik.values.personFitness}
                                            onChange={formik.handleChange}
                                            displayEmpty
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return (
                                                        <span className={styles.placeholder}>
                                                            Select Fitness Value
                                                        </span>
                                                    );
                                                }

                                                return selected;
                                            }}
                                        >
                                            <MenuItem value="" disabled>
                                                <span className={styles.placeholder}>
                                                    Select Fitness Value
                                                </span>
                                            </MenuItem>
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </StyledSelect>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={styles.form__box}>
                            <label className={styles.form__label}>Weather</label>
                            <div className={styles.form__control}>
                                <p className={styles.label__primary}>Temperature</p>
                                <div className={styles.checkbox__field}>
                                    <input
                                        type="checkbox"
                                        name="currentTemperatureCheck"
                                        checked={formik.values.currentTemperatureCheck}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'currentTemperatureCheck',
                                                !formik.values.currentTemperatureCheck,
                                            );
                                            formik.setFieldValue(
                                                'temperature',
                                                e.target.checked ? weatherInfo?.temperature : '',
                                            );
                                        }}
                                    />
                                    <label htmlFor="temp" className={styles.label__primary}>
                                        Use local temperature
                                    </label>
                                </div>
                                {!formik.values.currentTemperatureCheck && (
                                    <div className={styles.form__field}>
                                        <input
                                            type="text"
                                            placeholder="Enter temperature in °C (e.g. 20)  "
                                            name="temperature"
                                            value={formik.values.temperature}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={styles.form__control}>
                                <p className={styles.label__primary}>Visibility</p>
                                <div className={styles.checkbox__field}>
                                    <input
                                        type="checkbox"
                                        name="currentTemperatureCheck"
                                        checked={formik.values.currentVisibilityCheck}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'currentVisibilityCheck',
                                                !formik.values.currentVisibilityCheck,
                                            );
                                            formik.setFieldValue(
                                                'visibility',
                                                e.target.checked ? weatherInfo?.visibility : '',
                                            );
                                        }}
                                    />
                                    <label htmlFor="visb" className={styles.label__primary}>
                                        Use local visibility
                                    </label>
                                </div>
                                {!formik.values.currentVisibilityCheck && (
                                    <div className={styles.form__field}>
                                        {/* <select
                                            name="visibility"
                                            value={formik.values.visibility}
                                            onChange={formik.handleChange}
                                        >
                                            <option value="" disabled>
                                                Select Visibility
                                            </option>
                                            <option value="clearsky">Clear sky</option>
                                            <option value="cloudy">Cloudy</option>
                                            <option value="rainy">Rainy</option>
                                        </select> */}
                                        <StyledSelect
                                            name="visibility"
                                            value={formik.values.visibility}
                                            onChange={formik.handleChange}
                                            displayEmpty
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return (
                                                        <span className={styles.placeholder}>
                                                            Select Visibility
                                                        </span>
                                                    );
                                                }

                                                return selected;
                                            }}
                                        >
                                            <MenuItem value="" disabled>
                                                <span className={styles.placeholder}>
                                                    Select Visibility
                                                </span>
                                            </MenuItem>
                                            <MenuItem value="clearsky">Clear sky</MenuItem>
                                            <MenuItem value="cloudy">Cloudy</MenuItem>
                                            <MenuItem value="rainy">Rainy</MenuItem>
                                        </StyledSelect>
                                    </div>
                                )}
                            </div>
                            <div className={styles.form__control}>
                                <p className={styles.label__primary}>Wind</p>
                                <div className={styles.checkbox__field}>
                                    <input
                                        type="checkbox"
                                        name="currentWindCheck"
                                        checked={formik.values.currentWindCheck}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'currentWindCheck',
                                                !formik.values.currentWindCheck,
                                            );
                                            formik.setFieldValue(
                                                'wind',
                                                e.target.checked ? weatherInfo?.wind : '',
                                            );
                                        }}
                                    />
                                    <label htmlFor="wind" className={styles.label__primary}>
                                        Use local wind
                                    </label>
                                </div>
                                {!formik.values.currentWindCheck && (
                                    <div className={styles.form__field}>
                                        <input
                                            type="text"
                                            placeholder="Enter wind speed in knots (e.g. 10)"
                                            name="wind"
                                            value={formik.values.wind}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={styles.form__box}>
                            <label className={styles.form__label}>Requirements</label>
                            <div className={styles.form__control}>
                                <p className={styles.label__primary}>Equipments</p>
                                <div className={styles.checkbox__field}>
                                    <input
                                        type="checkbox"
                                        name="searchTermCequipmentsCheckeck"
                                        checked={formik.values.equipmentsCheck}
                                        onChange={() => {
                                            formik.setFieldValue(
                                                'equipmentsCheck',
                                                !formik.values.equipmentsCheck,
                                            );
                                            formik.setFieldValue('equipments', []);
                                        }}
                                    />
                                    <label
                                        htmlFor="equipmentsCheck"
                                        className={styles.label__primary}
                                    >
                                        Don&apos;t filter by equipments
                                    </label>
                                </div>
                                {!formik.values.equipmentsCheck && (
                                    <Autocomplete
                                        sx={{ minWidth: '50%' }}
                                        multiple
                                        id="tags-standard-equipment"
                                        options={equipmentsTags}
                                        getOptionLabel={(option) => option}
                                        value={formik.values.equipments}
                                        onChange={(e, newValue) => {
                                            formik.setFieldValue('equipments', newValue);
                                        }}
                                        renderInput={(params) => (
                                            <StyledTextField
                                                {...params}
                                                placeholder="Choose available equipment"
                                            />
                                        )}
                                    />
                                )}
                            </div>
                            <div className={styles.form__control}>
                                <p className={styles.label__primary}>Budget</p>
                                <div className={styles.checkbox__field}>
                                    <input
                                        type="checkbox"
                                        name="budgetCheck"
                                        checked={formik.values.budgetCheck}
                                        onChange={() => {
                                            formik.setFieldValue(
                                                'budgetCheck',
                                                !formik.values.budgetCheck,
                                            );
                                            formik.setFieldValue('minBudget', '');
                                            formik.setFieldValue('maxBudget', '');
                                        }}
                                    />
                                    <label htmlFor="budgetCheck" className={styles.label__primary}>
                                        Don&apos;t filter by budget
                                    </label>
                                </div>
                            </div>
                            {!formik.values.budgetCheck && (
                                <>
                                    <div className={styles.form__control}>
                                        <p className={styles.label__primary}>Budget min</p>
                                        <div className={styles.form__field}>
                                            <input
                                                type="text"
                                                placeholder="Enter min budget in $ (e.g. 10)"
                                                name="minBudget"
                                                value={formik.values.minBudget}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.form__control}>
                                        <p className={styles.label__primary}>Budget max</p>
                                        <div className={styles.form__field}>
                                            <input
                                                type="text"
                                                placeholder="Enter max budget in $ (e.g. 100)"
                                                name="maxBudget"
                                                value={formik.values.maxBudget}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className={styles.form__box}>
                            <label className={styles.form__label}>Group</label>
                            <div className={styles.form__control}>
                                <p className={styles.label__primary}>Number of persons</p>
                                <div className={styles.checkbox__field}>
                                    <input
                                        type="checkbox"
                                        name="personCheck"
                                        checked={formik.values.personCheck}
                                        onChange={() => {
                                            formik.setFieldValue(
                                                'personCheck',
                                                !formik.values.personCheck,
                                            );
                                            formik.setFieldValue('numberOfperson', '1');
                                        }}
                                    />
                                    <label htmlFor="personCheck" className={styles.label__primary}>
                                        Fill for myself
                                    </label>
                                </div>
                                {!formik.values.personCheck && (
                                    <div className={styles.form__field}>
                                        <input
                                            type="text"
                                            placeholder="Enter number (e.g. 2)"
                                            name="numberOfperson"
                                            value={formik.values.numberOfperson}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                )}
                            </div>
                            {!formik.values.personCheck && (
                                <>
                                    <div className={styles.form__control}>
                                        <p className={styles.label__primary}>
                                            Age of youngest person
                                        </p>
                                        <div className={styles.form__field}>
                                            <input
                                                type="text"
                                                placeholder="Enter min age (e.g. 10)"
                                                name="personMinAge"
                                                value={formik.values.personMinAge}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.form__control}>
                                        <p className={styles.label__primary}>
                                            Age of oldest person
                                        </p>
                                        <div className={styles.form__field}>
                                            <input
                                                type="text"
                                                placeholder="Enter max age (e.g. 100)"
                                                name="personMaxAge"
                                                value={formik.values.personMaxAge}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>

                        {!formik.values.personCheck && (
                            <div className={styles.form__box}>
                                <label
                                    className={styles.form__label}
                                    style={{ visibility: 'hidden' }}
                                >
                                    Group
                                </label>

                                <div className={styles.form__control}>
                                    <p className={styles.label__primary}>
                                        Lowest participant fitness
                                    </p>
                                    <div className={styles.form__field}>
                                        {/* <input
                                        type="text"
                                        placeholder="Enter lowest fitness value"
                                        name="personMinFitness"
                                        value={formik.values.personMinFitness}
                                        onChange={formik.handleChange}
                                    /> */}
                                        <StyledSelect
                                            name="personMinFitness"
                                            value={formik.values.personMinFitness}
                                            onChange={formik.handleChange}
                                            displayEmpty
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return (
                                                        <span className={styles.placeholder}>
                                                            Select Lowest Fitness Value
                                                        </span>
                                                    );
                                                }

                                                return selected;
                                            }}
                                        >
                                            <MenuItem value="" disabled>
                                                <span className={styles.placeholder}>
                                                    Select Lowest Fitness Value
                                                </span>
                                            </MenuItem>
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </StyledSelect>
                                    </div>
                                </div>
                                <div className={styles.form__control}>
                                    <p className={styles.label__primary}>
                                        Highest participant fitness
                                    </p>
                                    <div className={styles.form__field}>
                                        {/* <input
                                        type="text"
                                        placeholder="Enter highest fitness value"
                                        name="personMaxFitness"
                                        value={formik.values.personMaxFitness}
                                        onChange={formik.handleChange}
                                    /> */}
                                        <StyledSelect
                                            name="personMaxFitness"
                                            value={formik.values.personMaxFitness}
                                            onChange={formik.handleChange}
                                            displayEmpty
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return (
                                                        <span className={styles.placeholder}>
                                                            Select Hightest Fitness Value
                                                        </span>
                                                    );
                                                }

                                                return selected;
                                            }}
                                        >
                                            <MenuItem value="" disabled>
                                                <span className={styles.placeholder}>
                                                    Select Hightest Fitness Value
                                                </span>
                                            </MenuItem>
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </StyledSelect>
                                    </div>
                                </div>

                                <div className={styles.form__control}>
                                    <p className={styles.label__primary}>
                                        Lowest participant weight
                                    </p>
                                    <div className={styles.form__field}>
                                        <input
                                            type="text"
                                            placeholder="Enter min weight in kg (e.g. 40)"
                                            name="personMinWeight"
                                            value={formik.values.personMinWeight}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className={styles.form__control}>
                                    <p className={styles.label__primary}>
                                        Hightest participant weight
                                    </p>
                                    <div className={styles.form__field}>
                                        <input
                                            type="text"
                                            placeholder="Enter max weight in kg (e.g. 100)"
                                            name="personMaxWeight"
                                            value={formik.values.personMaxWeight}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={styles.action__buttons}>
                        <Link to={routes.HOMEPAGE} style={{ textDecoration: 'none' }}>
                            <StyledButton bg={'none'}>Cancel</StyledButton>
                        </Link>
                        <StyledButton type="reset" onClick={handleReset} bg={'none'}>
                            Clear Filter
                        </StyledButton>
                        <StyledButton bg={'none'} customFontWeight="700" type="submit">
                            Save
                        </StyledButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Filter;
