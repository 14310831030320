import { CustomModal } from 'components/common/Modal';
import styles from './createList.module.scss';
import { Button, MenuItem, Select, TextField, styled } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import { useFormik } from 'formik';
import { useContext } from 'react';
import { StoreContext } from 'store/useStore';
import actions from 'store/action';

const StyledTextField = styled(TextField)`
    .MuiInputBase-root {
        background-color: #f2f2f2;
        border: none;
        border-radius: 5px;
        padding: 0px 10px;
        -webkit-appearance: none;
        box-sizing: border-box;
        min-height: 41px;
        width: 100%;

        /* &:focus {
            outline: none;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
        } */
    }

    & .MuiOutlinedInput-root {
        fieldset {
            border: none;
        }
        &:hover fieldset {
            border: none;
        }
        &.Mui-focused fieldset {
            border-color: #fff;
            outline: none;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
        }
    }

    .MuiInputBase-input {
        padding: 0;
        font-family: 'Arimo', sans-serif;
        font-size: 16px;
    }

    .Mui-error {
        font-family: 'Arimo', sans-serif;
        color: #ec4453;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
    }
`;

const StyledButton = styled(Button)(({ bg }) => ({
    background: bg,
    textTransform: 'Capitalize',
    color: bg === 'none' ? '#1F62AF' : '#fff',
    borderRadius: '5px',
    width: 'fit-content',
    height: '41px',
    fontSize: 18,
    fontWeight: 700,
    '&:hover': {
        background: bg,
    },
    '@media (max-width: 476px)': {
        fontSize: '15px !important',
    },
}));

const StyledSelect = styled(Select)`
    width: 100%;
    font-family: 'Arimo', sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    background-color: #f2f2f2;
    padding: 0px 10px;
    -webkit-appearance: none;
    box-sizing: border-box;
    min-height: 41px !important;

    .MuiInputBase-input {
        padding: 0;
        font-family: 'Arimo', sans-serif;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0;
        background-color: #f2f2f2;
        border: none;
        border-radius: 5px;
        padding: 0px 10px;
        -webkit-appearance: none;
        box-sizing: border-box;
        min-height: 41px !important;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .MuiOutlinedInput-root {
        width: 100%;
    }

    & .MuiOutlinedInput-notchedOutline {
        border: none;
        fieldset {
            border: none;
        }
        &:hover fieldset {
            border: none;
        }
        &.Mui-focused fieldset {
            border-color: #fff;
            outline: none;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
        }
    }

    .Mui-error {
        font-family: 'Arimo', sans-serif;
        color: #ec4453;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
    }
`;

const CreateListModal = ({ open, setOpen, setOpenInitialModal }) => {
    const onClose = () => setOpen(false);
    const { state, dispatch } = useContext(StoreContext);

    const initialValues = {
        listTitle: '',
        listDescription: '',
        seeListRule: '',
        inviteListRule: '',
        editListRule: '',
        place: '',
        time: '',
        section: '',
    };

    const formik = useFormik({
        initialValues,
        onSubmit: (values) => {
            console.log(values);
            createList(values);
        },
    });

    const createList = (values) => {
        dispatch({
            type: actions.ADD_LISTS,
            payload: values,
        });
        setOpenInitialModal(true);
        setOpen(false);
    };
    return (
        <CustomModal open={open} onClose={onClose} title={'Create a new list'}>
            <div className={styles.createList__Box}>
                <form onSubmit={formik.handleSubmit}>
                    <div className={styles.form__field}>
                        <StyledTextField
                            name="listTitle"
                            placeholder="Title*"
                            value={formik.values.listTitle}
                            onChange={formik.handleChange}
                            sx={{ width: '100%' }}
                            error={formik.touched.listTitle && Boolean(formik.errors.listTitle)}
                            helperText={formik.touched.listTitle && formik.errors.listTitle}
                        />
                    </div>
                    <div className={styles.form__field}>
                        <StyledTextField
                            name="listDescription"
                            placeholder="Description"
                            value={formik.values.listDescription}
                            onChange={formik.handleChange}
                            sx={{ width: '100%' }}
                            error={
                                formik.touched.listDescription &&
                                Boolean(formik.errors.listDescription)
                            }
                            helperText={
                                formik.touched.listDescription && formik.errors.listDescription
                            }
                        />
                    </div>
                    <div className={styles.form__field}>
                        <StyledSelect
                            name="seeListRule"
                            sx={{ width: '100%' }}
                            value={formik.values.seeListRule}
                            onChange={formik.handleChange}
                            displayEmpty
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return (
                                        <span className={styles.placeholder}>
                                            Select who can see
                                        </span>
                                    );
                                }

                                return selected;
                            }}
                        >
                            <MenuItem value="" disabled>
                                <span className={styles.placeholder}>Select who can see</span>
                            </MenuItem>
                            <MenuItem value="only you">Only you can see</MenuItem>
                            <MenuItem value="only friends">Only friends can see</MenuItem>
                            <MenuItem value="everyone">Everyone can see</MenuItem>
                        </StyledSelect>
                    </div>
                    <div className={styles.form__field}>
                        <StyledSelect
                            name="inviteListRule"
                            sx={{ width: '100%' }}
                            value={formik.values.inviteListRule}
                            onChange={formik.handleChange}
                            displayEmpty
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return (
                                        <span className={styles.placeholder}>
                                            Select who can invite
                                        </span>
                                    );
                                }

                                return selected;
                            }}
                        >
                            <MenuItem value="" disabled>
                                <span className={styles.placeholder}>Select who can invite</span>
                            </MenuItem>
                            <MenuItem value="only you">Only you can invite</MenuItem>
                            <MenuItem value="only friends">Only friends can invite</MenuItem>
                            <MenuItem value="everyone">Everyone can invite</MenuItem>
                        </StyledSelect>
                    </div>
                    <div className={styles.form__field}>
                        <StyledSelect
                            name="editListRule"
                            sx={{ width: '100%' }}
                            value={formik.values.editListRule}
                            onChange={formik.handleChange}
                            displayEmpty
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return (
                                        <span className={styles.placeholder}>
                                            Select who can edit
                                        </span>
                                    );
                                }

                                return selected;
                            }}
                        >
                            <MenuItem value="" disabled>
                                <span className={styles.placeholder}>Select who can edit</span>
                            </MenuItem>
                            <MenuItem value="only you">Only you can edit</MenuItem>
                            <MenuItem value="only friends">Only friends can edit</MenuItem>
                            <MenuItem value="everyone">Everyone can edit</MenuItem>
                        </StyledSelect>
                    </div>

                    <div className={styles.form__field}>
                        <StyledTextField
                            name="place"
                            placeholder="Place"
                            value={formik.values.place}
                            onChange={formik.handleChange}
                            sx={{ width: '100%' }}
                            error={formik.touched.place && Boolean(formik.errors.place)}
                            helperText={formik.touched.place && formik.errors.place}
                        />
                    </div>
                    <div className={styles.form__field}>
                        <StyledTextField
                            name="time"
                            placeholder="Time"
                            value={formik.values.time}
                            onChange={formik.handleChange}
                            sx={{ width: '100%' }}
                            error={formik.touched.time && Boolean(formik.errors.time)}
                            helperText={formik.touched.time && formik.errors.time}
                        />
                    </div>

                    <div className={styles.form__field}>
                        <StyledSelect
                            name="section"
                            sx={{ width: '100%' }}
                            value={formik.values.section}
                            onChange={formik.handleChange}
                            displayEmpty
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return (
                                        <span className={styles.placeholder}>Select section</span>
                                    );
                                }

                                return selected;
                            }}
                        >
                            <MenuItem value="" disabled>
                                <span className={styles.placeholder}>Select section</span>
                            </MenuItem>
                            <MenuItem value="1">1 section</MenuItem>
                            <MenuItem value="2">2 section</MenuItem>
                            <MenuItem value="3">3 section</MenuItem>
                        </StyledSelect>
                    </div>

                    <div className={styles.form__actions}>
                        <StyledButton bg={'none'} type="submit">
                            Save
                        </StyledButton>
                    </div>
                </form>
            </div>
        </CustomModal>
    );
};

export default CreateListModal;
