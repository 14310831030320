import React, { useContext } from 'react';
import styles from './register.module.scss';
import { useFormik } from 'formik';
import { Button, TextField, styled } from '@mui/material';
import * as yup from 'yup';
import { auth, db } from 'helper/firebase';
import {
    createUserWithEmailAndPassword,
    sendEmailVerification,
    updateProfile,
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { useSnackBar } from 'hoc/snackbar';
import { getErrorMsg } from 'helper/getFirebaseErrorMsg';
import axios from 'axios';
import { StoreContext } from 'store/useStore';
import actions from 'store/action';

const StyledButton = styled(Button)(() => ({
    background: '#ec4453',
    textTransform: 'Capitalize',
    color: '#fff',
    borderRadius: '5px',
    width: '260px',
    height: '50px',
    fontSize: 18,
    '&:hover': {
        background: '#1F62AF',
    },
    '@media (max-width: 476px)': {
        fontSize: '15px !important',
    },
}));

const StyledTextField = styled(TextField)`
    .MuiInputBase-root {
        background-color: #f3f3f3;
        border: solid 1px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 0px 10px;
        -webkit-appearance: none;
        box-sizing: border-box;
        min-height: 41px;
        width: 100%;

        /* &:focus {
            outline: none;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
        } */
    }

    & .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
            border-color: #fff;
            outline: none;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
        }
    }

    .MuiInputBase-input {
        padding: 0;
        font-family: 'Arimo', sans-serif;
        font-size: 16px;
    }

    .Mui-error {
        font-family: 'Arimo', sans-serif;
        color: #ec4453;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
    }
`;

const BASE_URI = process.env['REACT_APP_BASE_URI'];

const RegisterForm = ({ setSelected, handleClose }) => {
    const { snackBarError, snackBarSuccess, snackBarInfo } = useSnackBar();
    const { state, dispatch } = useContext(StoreContext);
    const initialValues = {
        userName: '',
        email: '',
        password: '',
    };

    const validationSchema = yup.object({
        userName: yup.string().required('Username is required'),
        email: yup.string().email('Email should be valid').required('Email is required'),
        password: yup
            .string()
            .min(6, 'Password should be of minimum 6 in length')
            .max(100, 'Password should be of maximum of 100 in length')
            .required('Password is required'),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            handleLogin(values);
        },
    });

    const setLoading = (value, msg) => {
        dispatch({
            type: actions.SET_GLOABL_LOADING,
            payload: {
                loading: value,
                text: msg ? msg : '',
            },
        });
    };

    const addToFirestore = async (user) => {
        try {
            await setDoc(doc(db, 'users', `${user?.id}`), {
                username: user?.username,
                email: user?.email,
                userId: user?.id,
                timestamp: user?.date_created,
            });
        } catch (error) {
            console.log(error);
            setLoading(false);
            snackBarError(`Error: ${JSON.stringify(error.toString())}`);
        }
    };

    const handleLogin = async (values) => {
        try {
            setLoading(true, 'Registering new user');
            let payload = {
                username: values.userName,
                email: values.email,
                password: values.password,
            };
            let options = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const res = await axios.post(`${BASE_URI}/auth/register`, payload, options);
            console.log(res);
            if (res.status !== 201) throw res.statusText;
            const resultData = res.data;
            // to firestore

            console.log(resultData.data);

            await addToFirestore(resultData.data);
            snackBarSuccess(resultData.message);
            formik.resetForm();
            setSelected(1);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            let errorMsg = error?.response?.data?.message[0]?.error;
            // let errorMsg = getErrorMsg(error);
            snackBarError(`Error: ${JSON.stringify(errorMsg.toString())}`);
        }
    };
    // const handleLogin = async (values) => {
    //     try {
    //         console.log(`Email: ${values.email}, Password: ${values.password}`);
    //         const authRes = await createUserWithEmailAndPassword(
    //             auth,
    //             values.email,
    //             values.password,
    //         );

    //         const update = await updateProfile(auth.currentUser, {
    //             displayName: values.userName,
    //         });

    //         const user = authRes.user;

    //         await setDoc(doc(db, 'users', user.uid), {
    //             username: values.userName,
    //             email: values.email,
    //             userId: user.uid,
    //             timestamp: new Date(),
    //         });

    //         console.log(authRes);
    //         if (authRes.user) {
    //             snackBarSuccess('User Successfully Registered');
    //             console.log(authRes.operationType);
    //             await sendEmailVerification(auth.currentUser);
    //             snackBarInfo(
    //                 'A verification link has been sent to your email. Please verify your email.',
    //             );
    //             formik.resetForm();
    //             setSelected(1);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //         let errorMsg = getErrorMsg(error);
    //         snackBarError(`Error: ${JSON.stringify(errorMsg.toString())}`);
    //     }
    // };

    return (
        <div className={styles.LoginForm}>
            <h3 className={styles.LoginForm__Title}>Register</h3>
            <form className={styles.form} onSubmit={formik.handleSubmit}>
                <div className={styles.form__field}>
                    <StyledTextField
                        name="userName"
                        placeholder="Username"
                        value={formik.values.userName}
                        onChange={formik.handleChange}
                        sx={{ width: '100%' }}
                        error={formik.touched.userName && Boolean(formik.errors.userName)}
                        helperText={formik.touched.userName && formik.errors.userName}
                    />
                </div>
                <div className={styles.form__field}>
                    <StyledTextField
                        name="email"
                        placeholder="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        sx={{ width: '100%' }}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </div>
                <div className={styles.form__field}>
                    <StyledTextField
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        sx={{ width: '100%' }}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                    />
                </div>
                <div className={styles.form__actions}>
                    <StyledButton type="submit">Register</StyledButton>
                </div>
            </form>
        </div>
    );
};

export default RegisterForm;
