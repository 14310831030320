import { v4 as uuidv4 } from 'uuid';

export const liked = [
    {
        id: uuidv4(),
        title: 'Graffiti',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 5,
    },
    {
        id: uuidv4(),
        title: 'Travelling',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 5,
    },
    {
        id: uuidv4(),
        title: 'Kitesurfing',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 5,
    },
    {
        id: uuidv4(),
        title: 'Sleeping',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 5,
    },
    {
        id: uuidv4(),
        title: 'Graffiti',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 4,
    },
    {
        id: uuidv4(),
        title: 'Travelling',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 4,
    },
    {
        id: uuidv4(),
        title: 'Kitesurfing',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 4,
    },
    {
        id: uuidv4(),
        title: 'Sleeping',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 4,
    },
    {
        id: uuidv4(),
        title: 'Graffiti',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 3,
    },
    {
        id: uuidv4(),
        title: 'Travelling',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 3,
    },
    {
        id: uuidv4(),
        title: 'Kitesurfing',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 3,
    },
    {
        id: uuidv4(),
        title: 'Sleeping',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 3,
    },
    {
        id: uuidv4(),
        title: 'Graffiti',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 2,
    },
    {
        id: uuidv4(),
        title: 'Travelling',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 1,
    },
    {
        id: uuidv4(),
        title: 'Kitesurfing',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 1,
    },
    {
        id: uuidv4(),
        title: 'Sleeping',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 1,
    },
    {
        id: uuidv4(),
        title: 'Graffiti',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 1,
    },
    {
        id: uuidv4(),
        title: 'Travelling',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 1,
    },
    {
        id: uuidv4(),
        title: 'Kitesurfing',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 1,
    },
    {
        id: uuidv4(),
        title: 'Sleeping',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 1,
    },
];
export const frequency = [
    {
        id: uuidv4(),
        title: 'Graffiti',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 5,
    },
    {
        id: uuidv4(),
        title: 'Travelling',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 5,
    },
    {
        id: uuidv4(),
        title: 'Kitesurfing',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 5,
    },
    {
        id: uuidv4(),
        title: 'Sleeping',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 5,
    },
    {
        id: uuidv4(),
        title: 'Graffiti',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 4,
    },
    {
        id: uuidv4(),
        title: 'Travelling',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 4,
    },
    {
        id: uuidv4(),
        title: 'Kitesurfing',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 4,
    },
    {
        id: uuidv4(),
        title: 'Sleeping',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 4,
    },
    {
        id: uuidv4(),
        title: 'Graffiti',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 3,
    },
    {
        id: uuidv4(),
        title: 'Travelling',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 3,
    },
    {
        id: uuidv4(),
        title: 'Kitesurfing',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 3,
    },
    {
        id: uuidv4(),
        title: 'Sleeping',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 3,
    },
    {
        id: uuidv4(),
        title: 'Graffiti',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 2,
    },
    {
        id: uuidv4(),
        title: 'Travelling',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 1,
    },
    {
        id: uuidv4(),
        title: 'Kitesurfing',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 1,
    },
    {
        id: uuidv4(),
        title: 'Sleeping',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 1,
    },
    {
        id: uuidv4(),
        title: 'Graffiti',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 1,
    },
    {
        id: uuidv4(),
        title: 'Travelling',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 1,
    },
    {
        id: uuidv4(),
        title: 'Kitesurfing',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 1,
    },
    {
        id: uuidv4(),
        title: 'Sleeping',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, pariatur modi! Nesciunt expedita repudiandae reprehenderit libero nihil tempore nulla quo, voluptatem dolorem. Mollitia molestias tempora ea aliquid! Amet eveniet minima aliquam aspernatur sapiente tempore ipsam praesentium atque, iusto velit facilis quia. Aut, architecto. Eum, eos explicabo vitae ratione non corporis.',
        video: 'https://www.youtube.com/watch?v=K-RvJQxqVQc',
        pexels: [
            {
                id: 1655329,
                width: 3024,
                height: 4032,
                url: 'https://www.pexels.com/photo/men-and-women-standing-infront-of-dining-table-1655329/',
                photographer: 'Lisa Fotios',
                photographer_url: 'https://www.pexels.com/@fotios-photos',
                photographer_id: 26735,
                avg_color: '#6B5F61',
                src: {
                    original: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1655329/pexels-photo-1655329.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men and Women Standing Infront of Dining Table',
            },
            {
                id: 207896,
                width: 7360,
                height: 3906,
                url: 'https://www.pexels.com/photo/adventure-backlit-dawn-dusk-207896/',
                photographer: 'Pixabay',
                photographer_url: 'https://www.pexels.com/@pixabay',
                photographer_id: 2659,
                avg_color: '#765028',
                src: {
                    original: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Silhouette of People during Golden Hour',
            },
            {
                id: 1036804,
                width: 5998,
                height: 3999,
                url: 'https://www.pexels.com/photo/men-sits-of-sofa-1036804/',
                photographer: 'Afta Putta Gunawan',
                photographer_url: 'https://www.pexels.com/@apgpotr',
                photographer_id: 92074,
                avg_color: '#8B755C',
                src: {
                    original: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/1036804/pexels-photo-1036804.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Men Sits of Sofa',
            },
            {
                id: 4877857,
                width: 3429,
                height: 5143,
                url: 'https://www.pexels.com/photo/clear-drinking-glasses-on-table-4877857/',
                photographer: 'cottonbro studio',
                photographer_url: 'https://www.pexels.com/@cottonbro',
                photographer_id: 1437723,
                avg_color: '#88736B',
                src: {
                    original: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg',
                    large2x:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
                    large: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
                    medium: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=350',
                    small: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&h=130',
                    portrait:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800',
                    landscape:
                        'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200',
                    tiny: 'https://images.pexels.com/photos/4877857/pexels-photo-4877857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
                },
                liked: false,
                alt: 'Clear Drinking Glasses on Table',
            },
        ],
        rating: 1,
    },
];
