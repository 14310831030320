import { Backdrop } from '@mui/material';
import { ThreeDotLoader } from 'components/common/Loader';

import useStore from 'store/useStore';

export const GlobalLoader = ({ children }) => {
    const { state } = useStore();
    const { globalLoading, loadingText } = state;

    return (
        <main>
            {globalLoading && (
                <Backdrop
                    sx={{
                        color: 'inherit',
                        zIndex: '9999',
                        display: 'flex',
                        flexDirection: 'Column',
                        gap: '10px',
                        '& p': { color: '#fff' },
                    }}
                    open={globalLoading}
                >
                    <p>{loadingText}</p>
                    <ThreeDotLoader />
                </Backdrop>
            )}
            {children}
        </main>
    );
};
