import React, { useState } from 'react';
import styles from './loginModal.module.scss';
import { Modal, Box, Fade, Button, styled, IconButton } from '@mui/material';
import LoginForm from 'components/LoginForm';
import RegisterForm from 'components/RegisterForm';
import { Close } from '@mui/icons-material';
import PasswordResetForm from 'components/PasswordResetForm';
import brandLogo from 'assets/images/logoNew.png';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import FAVICON from 'assets/icons/favicon.ico';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // minWidth: 500,
    width: 680,
    maxHeight: 580,
    bgcolor: 'background.paper',
    background: '#FFFFFF',
    borderRadius: '20px',
    p: 4,
    overflowY: 'scroll',
    border: 'none',
    outline: 'none',
    backdropFilter: 'blur(7px)',

    '&::-webkit-scrollbar': {
        display: 'none',
    },
};

const StyledButton = styled(Button)(({ bg }) => ({
    background: bg,
    textTransform: 'Capitalize',
    color: bg !== '#fff' ? '#fff' : '#1F62AF',
    borderRadius: '5px',
    width: '160px',
    height: '50px',
    fontSize: 18,
    '&:hover': {
        background: bg,
    },
    '@media (max-width: 476px)': {
        fontSize: '15px !important',
    },
}));

const LoginModal = ({ open, setOpen }) => {
    const [selected, setSelected] = useState(1);
    const handleClose = (event, reason) => {
        if (reason && reason == 'backdropClick') return;
        setOpen(false);
    };
    return (
        // <Modal open={open} onClose={handleClose} style={{ backdropFilter: 'blur(7px)' }}>
        //     <Fade in={open}>
        <div className={styles.Modal}>
            <Helmet>
                <title>ActivityList Login</title>
                <link rel="icon" href={FAVICON} />
                <meta
                    name="description"
                    content="Activitylist wants to help people to make more good experiences with friends and like-minded people in their free time – to achieve it’s ultimate goal of making humans happier"
                />
            </Helmet>
            {/* <div className={styles.close}>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </div> */}
            <div className={styles.Brand__Img}>
                <Link to={'/'}>
                    <img src={brandLogo} alt="Activitylist" />
                </Link>
            </div>
            <div className={styles.Modal__Tabs}>
                <StyledButton
                    onClick={() => setSelected(1)}
                    bg={selected === 1 ? '#1F62AF' : '#fff'}
                >
                    Login
                </StyledButton>
                <StyledButton
                    onClick={() => setSelected(2)}
                    bg={selected === 2 ? '#1F62AF' : '#fff'}
                >
                    Register
                </StyledButton>
            </div>
            <div className={styles.Form__Box}>
                {selected === 1 ? (
                    <LoginForm setSelected={setSelected} handleClose={handleClose} />
                ) : selected === 2 ? (
                    <RegisterForm setSelected={setSelected} handleClose={handleClose} />
                ) : (
                    <PasswordResetForm setSelected={setSelected} handleClose={handleClose} />
                )}
            </div>
        </div>
        //     </Fade>
        // </Modal>
    );
};

export default LoginModal;
