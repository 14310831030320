import { Button, styled } from '@mui/material';
import React from 'react';

const StyledButton = styled(Button)(({ bg, customColor, customWidth }) => ({
    background: bg,
    textTransform: 'Capitalize',
    color: customColor,
    borderRadius: '5px',
    width: customWidth ? customWidth : 'max-content',
    height: '41px',
    fontSize: 18,
    '&:hover': {
        background: bg,
    },
    '@media (max-width: 476px)': {
        fontSize: '15px !important',
    },
}));

const CustomButton = (props) => {
    const { children } = props;
    return <StyledButton {...props}>{children}</StyledButton>;
};

export default CustomButton;
