export const BUDGET_FILTER = [
    {
        label: 'Free',
        value: '0-0',
    },
    {
        label: '$1 - $24',
        value: '1-24',
    },
    {
        label: '$25 - $49',
        value: '25-49',
    },
    {
        label: '$50 - $99',
        value: '50-99',
    },
    {
        label: '$100 - $199',
        value: '100-199',
    },
    {
        label: '$200 - $299',
        value: '200-299',
    },
    {
        label: '$300 - $499',
        value: '300-499',
    },
    {
        label: '$500 - $999',
        value: '500-999',
    },
    {
        label: '$1000 and above',
        value: '1000-1000000',
    },
];
