import { CustomModal } from 'components/common/Modal';
import styles from '../LikeModal/likeModal.module.scss';
import { frequencyNumbers } from 'constants/ratingsScale';
import SyncIcon from '@mui/icons-material/Sync';

const FrequencyModal = ({ open, setOpen, onClick }) => {
    const likeContent = {
        1: '0',
        2: '1',
        3: '2+',
        4: '20+',
        5: <SyncIcon />,
    };

    const onClose = () => setOpen(false);
    return (
        <CustomModal
            open={open}
            onClose={onClose}
            title={'How often have you done this activity in the past?'}
        >
            <div className={styles.actions__grid}>
                {Object.keys(frequencyNumbers).map((key, idx) => (
                    <span
                        className={styles.textBoldSpan}
                        title={frequencyNumbers[key]}
                        onClick={() => onClick(key)}
                        key={key}
                    >
                        {likeContent[key]}
                    </span>
                ))}
            </div>
        </CustomModal>
    );
};

export default FrequencyModal;
