import FilterMenu from 'components/FilterSection';
import styles from './filters.module.scss';
import { ArrowBack } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import routes from 'constants/routes';

const FiltersPage = () => {
    return (
        <div className={styles.filter_container}>
            <div className={styles.header}>
                <Link to={routes.HOMEPAGE} style={{ margin: '0' }}>
                    <ArrowBack />
                </Link>
                <h1>Filter</h1>
                <div></div>
            </div>
            <div className={styles.content}>
                <FilterMenu />
            </div>
        </div>
    );
};

export default FiltersPage;
