import React, { useState } from 'react';
import styles from './lists.module.scss';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Button } from '@mui/material';
import styled from '@emotion/styled';
import avatar1 from 'assets/images/avatar1.png';
import avatar2 from 'assets/images/avatar2.png';
import MyListItem from 'components/ListItem';
import EditListModal from 'components/EditListModal';
import InviteToListModal from 'components/InviteToListModal';
import EditListSectionModal from 'components/EditListSectionModal';

const StyledButton = styled(Button)(({ bg, customFontWeight, customFontSize }) => ({
    background: bg,
    textTransform: 'Capitalize',
    color: bg === 'none' ? '#1F62AF' : '#fff',
    borderRadius: '5px',
    width: 'fit-content',
    height: '50px',
    fontSize: customFontSize ?? 20,
    fontWeight: customFontWeight,
    transition: 'all 0.3s',

    '@media (max-width: 476px)': {
        fontSize: '15px !important',
    },
}));

const MyLists = () => {
    const [openInviteModal, setOpenInviteModal] = useState(false);
    const [openEditListModal, setOpenEditListModal] = useState(false);
    const [openEditSectionModal, setOpenEditSectionModal] = useState(false);
    return (
        <div className={styles.MyLists}>
            <div className={styles.MyLists__Container}>
                <div className={styles.MyLists__Header}>
                    <div className={styles.MyLists__Title}>
                        <FormatListBulletedIcon />
                        <h3>Class 3A Diploma trip 2025</h3>
                    </div>
                    <StyledButton
                        bg={'none'}
                        customFontWeight={400}
                        onClick={() => setOpenEditListModal(true)}
                    >
                        Edit
                    </StyledButton>
                </div>
                <div className={styles.MyLists__Grid}>
                    <div className={styles.MyLists__aSide}>
                        <div className={styles.MyLists__ListItem}>
                            <div className={styles.ListItem__Header}>
                                <h4>Nightlife</h4>
                                <div className={styles.ListItem__Actions}>
                                    <StyledButton
                                        bg={'none'}
                                        customFontWeight={400}
                                        onClick={() => setOpenEditSectionModal(true)}
                                    >
                                        Edit
                                    </StyledButton>
                                </div>
                            </div>
                            <div className={styles.ListItem__Items}>
                                <MyListItem />
                                <MyListItem />
                                <MyListItem />
                            </div>
                        </div>
                        <div className={styles.MyLists__ListItem}>
                            <div className={styles.ListItem__Header}>
                                <h4>Nightlife</h4>
                                <div className={styles.ListItem__Actions}>
                                    <StyledButton bg={'none'} customFontWeight={400}>
                                        Edit
                                    </StyledButton>
                                </div>
                            </div>
                            <div className={styles.ListItem__Items}>
                                <MyListItem />
                                <MyListItem />
                                <MyListItem />
                            </div>
                        </div>
                    </div>
                    <div className={styles.MyLists__bSide}>
                        <div className={styles.participant__header}>
                            <h5>Participant</h5>
                            <StyledButton
                                bg={'none'}
                                customFontSize={'20px'}
                                customFontWeight={400}
                                onClick={() => setOpenInviteModal(true)}
                            >
                                Invite people
                            </StyledButton>
                        </div>
                        <div className={styles.participant__list}>
                            <div className={styles.participant__item}>
                                <div className={styles.participant__img}>
                                    <img src={avatar1} alt="" />
                                </div>
                                <div className={styles.participant__info}>
                                    <p className={styles.participant__name}>John Donny</p>
                                    <span className={styles.participant__type}>Creator</span>
                                </div>
                            </div>
                            <div className={styles.participant__item}>
                                <div className={styles.participant__img}>
                                    <img src={avatar2} alt="" />
                                </div>
                                <div className={styles.participant__info}>
                                    <p className={styles.participant__name}>John Donny</p>
                                    <span className={styles.participant__type}>Contributor</span>
                                </div>
                            </div>
                        </div>

                        <div className={styles.MyLists__About}>
                            <p>
                                <strong>About:</strong>
                                List of activities that you can do at home when you are bored or
                                just have some free time but not much of a plan :-
                            </p>
                        </div>

                        <div className={styles.MyLists__Info}>
                            <div className={styles.MyLists__About}>
                                <strong>Privacy:</strong>
                                <p>Public</p>
                            </div>
                            <div className={styles.MyLists__About}>
                                <strong>Edit:</strong>
                                <p>Everyone can edit</p>
                            </div>
                            <div className={styles.MyLists__About}>
                                <strong>Location:</strong>
                                <p>Worldwide</p>
                            </div>
                            <div className={styles.MyLists__About}>
                                <strong>Date:</strong>
                                <p>Anytime</p>
                            </div>
                            <div className={styles.MyLists__About}>
                                <strong>Sections:</strong>
                                <p>1</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <EditListModal open={openEditListModal} setOpen={setOpenEditListModal} />
            <InviteToListModal open={openInviteModal} setOpen={setOpenInviteModal} />
            <EditListSectionModal open={openEditSectionModal} setOpen={setOpenEditSectionModal} />
            <EditListSectionModal open={openEditSectionModal} setOpen={setOpenEditSectionModal} />
        </div>
    );
};

export default MyLists;
