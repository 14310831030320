import React from 'react';
import { CustomModal } from 'components/common/Modal';
import styles from './invite.module.scss';
import { Button, styled } from '@mui/material';

const StyledButton = styled(Button)(({ bg }) => ({
    background: bg,
    textTransform: 'Capitalize',
    color: bg === 'none' ? '#1F62AF' : '#fff',
    borderRadius: '5px',
    width: 'fit-content',
    height: '41px',
    fontSize: 20,
    fontWeight: 400,
    '&:hover': {
        background: bg,
    },
    '@media (max-width: 476px)': {
        fontSize: '15px !important',
    },
}));

const InviteToListModal = ({ open, setOpen }) => {
    const onClose = () => {
        setOpen(false);
    };
    return (
        <CustomModal open={open} onClose={onClose} title={'Invite people'}>
            <div className={styles.Invite__Container}>
                <p className={styles.text__primary}>Choose how you want to invite people.</p>
                <StyledButton bg={'none'}>Invite friends on Activitylist</StyledButton>
                <StyledButton bg={'none'}>Invite with Whatsapp</StyledButton>
                <StyledButton bg={'none'}>Invite with invitation link</StyledButton>
            </div>
        </CustomModal>
    );
};

export default InviteToListModal;
