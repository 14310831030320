import React, { useContext } from 'react';
import styles from './loginForm.module.scss';
import { useFormik } from 'formik';
import { Button, TextField, styled } from '@mui/material';
import * as yup from 'yup';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from 'helper/firebase';
import { StoreContext } from 'store/useStore';
import actions from 'store/action';
import { useSnackBar } from 'hoc/snackbar';
import { getErrorMsg } from 'helper/getFirebaseErrorMsg';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const StyledButton = styled(Button)(() => ({
    background: '#1F62AF',
    textTransform: 'Capitalize',
    color: '#fff',
    borderRadius: '5px',
    width: '260px',
    height: '50px',
    fontSize: 18,
    '&:hover': {
        background: '#1F62AF',
    },
    '@media (max-width: 476px)': {
        fontSize: '15px !important',
    },
}));

const StyledTextField = styled(TextField)`
    .MuiInputBase-root {
        background-color: #f3f3f3;
        border: solid 1px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 0px 10px;
        -webkit-appearance: none;
        box-sizing: border-box;
        min-height: 41px;
        width: 100%;

        /* &:focus {
            outline: none;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
        } */
    }

    & .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
            border-color: #fff;
            outline: none;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
        }
    }

    .MuiInputBase-input {
        padding: 0;
        font-family: 'Arimo', sans-serif;
        font-size: 16px;
    }

    .Mui-error {
        font-family: 'Arimo', sans-serif;
        color: #ec4453;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
    }
`;

const BASE_URI = process.env['REACT_APP_BASE_URI'];

const LoginForm = ({ setSelected, handleClose }) => {
    const { state, dispatch } = useContext(StoreContext);
    const { snackBarError, snackBarSuccess, snackBarWarning } = useSnackBar();
    const navigate = useNavigate();

    const initialValues = {
        userName: '',
        // email: '',
        password: '',
    };

    const validationSchema = yup.object({
        userName: yup.string().required('Username is required'),
        // email: yup.string().email('Email should be valid').required('Email is required'),
        password: yup
            .string()
            .min(6, 'Password should be of minimum 6 in length')
            .max(100, 'Password should be of maximum of 100 in length')
            .required('Password is required'),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            handleLogin(values);
        },
    });

    const setLoading = (value, msg) => {
        dispatch({
            type: actions.SET_GLOABL_LOADING,
            payload: {
                loading: value,
                text: msg ? msg : '',
            },
        });
    };

    const fetchProfiePicture = async (userId) => {
        try {
            // const options = {
            //     headers: {
            //         Authorization: `Bearer ${jwt}`,
            //         Origin: '*',
            //         'ngrok-skip-browser-warning': '69420',
            //         responseType: 'arraybuffer',
            //     },
            // };
            const res = await axios.get(`${BASE_URI}/user_details/${userId}/profile_picture`, {
                responseType: 'arraybuffer',
            });
            // if (res.statusCode === 404) {
            //     setProfilePic(null);
            // }
            const resultData = new Blob([res.data], { type: 'image/png' });
            // // Create URL from Blob
            var imageUrl = URL.createObjectURL(resultData);
            // setProfilePic(imageUrl);
            dispatch({
                type: actions.SET_USER_PROFILE_PIC,
                payload: imageUrl,
            });
        } catch (error) {
            console.log(error);
            // setProfilePic('');
            dispatch({
                type: actions.SET_USER_PROFILE_PIC,
                payload: '',
            });
        }
    };

    const handleLogin = async (values) => {
        try {
            setLoading(true, 'Logging in');
            let payload = {
                username: values.userName,
                password: values.password,
            };
            let options = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const res = await axios.post(`${BASE_URI}/auth/login`, payload, options);
            console.log(res);
            if (res.status !== 200) throw res.statusText;
            const resultData = res.data;
            console.log(resultData);
            await fetchProfiePicture(resultData.data.id);
            dispatch({
                type: actions.SET_USER,
                payload: resultData.data,
            });

            localStorage.setItem('jwt', resultData.data.access_token);
            localStorage.setItem('user', JSON.stringify(resultData.data));

            snackBarSuccess('User Successfully Logged In');

            formik.resetForm();
            setLoading(false);
            navigate(-1);
        } catch (error) {
            console.log(error);
            setLoading(false);
            let errorMsg = error?.response?.data?.message[0]?.error;
            // let errorMsg = getErrorMsg(error);
            snackBarError(`Error: ${JSON.stringify(errorMsg.toString())}`);
        }
    };

    // const handleLogin = async (values) => {
    //     try {
    //         console.log(`Email: ${values.email}, Password: ${values.password}`);
    //         const authRes = await signInWithEmailAndPassword(auth, values.email, values.password);
    //         console.log(authRes);
    //         if (authRes.user) {
    //             console.log(authRes.operationType);
    //             dispatch({
    //                 type: actions.SET_USER,
    //                 payload: authRes.user,
    //             });
    //             snackBarSuccess('User Successfully Logged In');
    //             formik.resetForm();
    //             handleClose();
    //         }
    //     } catch (error) {
    //         console.log(error);
    //         let errorMsg = getErrorMsg(error);
    //         snackBarError(`Error: ${JSON.stringify(errorMsg.toString())}`);
    //     }
    // };

    return (
        <div className={styles.LoginForm}>
            <h3 className={styles.LoginForm__Title}>Sign In</h3>
            <form className={styles.form} onSubmit={formik.handleSubmit}>
                <div className={styles.form__field}>
                    <StyledTextField
                        name="userName"
                        placeholder="username"
                        value={formik.values.userName}
                        onChange={formik.handleChange}
                        sx={{ width: '100%' }}
                        error={formik.touched.userName && Boolean(formik.errors.userName)}
                        helperText={formik.touched.userName && formik.errors.userName}
                    />
                </div>
                <div className={styles.form__field}>
                    <StyledTextField
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        sx={{ width: '100%' }}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                    />
                </div>
                <div className={styles.form__actions}>
                    <p className={styles.forgot__btn} onClick={() => setSelected(3)}>
                        Forgot your password?
                    </p>
                    <StyledButton type="submit">Login</StyledButton>
                </div>
            </form>
        </div>
    );
};

export default LoginForm;
