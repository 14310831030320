import React, { useContext, useState } from 'react';
import styles from './mapSnapshot.module.scss';
import L from 'leaflet';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import { StoreContext } from 'store/useStore';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png',
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png',
});

const interactionOptions = {
    zoomControl: false,
    doubleClickZoom: false,
    closePopupOnClick: false,
    dragging: false,
    zoomSnap: false,
    zoomDelta: false,
    trackResize: false,
    touchZoom: false,
    scrollWheelZoom: false,
};

const osmProviders = {
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
        '&copy; <a href="https://www.maptiler.com/">MapTiler</a> &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
};

const MapSnapshot = () => {
    const { state } = useContext(StoreContext);
    const { userLocation } = state;
    const coordinates = JSON.parse(localStorage.getItem('coordinates')) || '';

    const [marker, setMarker] = useState({
        lat: coordinates ? coordinates[1] : userLocation?.latitude,
        lng: coordinates ? coordinates[0] : userLocation?.longitude,
    });
    return (
        <div className={styles.Map}>
            <div className={styles.Map__Container}>
                <MapContainer
                    center={[marker.lat, marker.lng]}
                    zoom={13}
                    className={styles.staticMap}
                    {...interactionOptions}
                >
                    <TileLayer
                        // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url={osmProviders.url}
                    />
                    <Marker position={[marker.lat, marker.lng]}></Marker>
                </MapContainer>
            </div>
        </div>
    );
};

export default MapSnapshot;
