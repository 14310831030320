import styled from '@emotion/styled';

export const ThreeDotLoader = ({ style, text, className, dotLoaderClassName }) => {
    return (
        <Wrapper className={className ?? ''}>
            <div className="three-dot-flexbox">
                <div style={style}>
                    {text && <p>{text}</p>}
                    <div className={`dot-loader ${dotLoaderClassName}`} />
                    <div className={`dot-loader dot-loader--2 ${dotLoaderClassName}`} />
                    <div className={`dot-loader dot-loader--3 ${dotLoaderClassName}`} />
                </div>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    .three-dot-flexbox > div {
        width: 100px;
        height: 25px;
        flex: 0 0 25%;
        box-sizing: border-box;
        margin: 0;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .dot-loader {
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        background-color: #153afb;
        position: relative;
        animation: 1.2s grow ease-in-out infinite;
    }

    .dot-loader--2 {
        animation: 1.2s grow ease-in-out infinite 0.15555s;
        margin: 0 20px;
    }

    .dot-loader--3 {
        animation: 1.2s grow ease-in-out infinite 0.3s;
    }

    .dot-loader-color-gray {
        background-color: #868686 !important;
    }

    @-webkit-keyframes grow {
        0%,
        40%,
        100% {
            transform: scale(0);
        }

        40% {
            transform: scale(1);
        }
    }

    @keyframes grow {
        0%,
        40%,
        100% {
            transform: scale(0);
        }

        40% {
            transform: scale(1);
        }
    }
`;
