import { CustomModal } from 'components/common/Modal';
import React, { useContext, useState, useCallback, useEffect } from 'react';
import styles from './profileEdit.module.scss';
import { Autocomplete, Button, IconButton, TextField, Tooltip, styled } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import { DeleteOutline } from '@mui/icons-material';
import { StoreContext } from 'store/useStore';
import actions from 'store/action';
import axios from 'axios';
import { useSnackBar } from 'hoc/snackbar';
import DatePicker from 'react-datepicker';
import moment from 'moment/moment';
import debounce from 'lodash.debounce';
import { fetchPlace } from 'helper/fetchPlace';
import { Country, State, City } from 'country-state-city';

const StyledTextField = styled(TextField)`
    .MuiInputBase-root {
        background-color: #f2f2f2;
        border: none;
        border-radius: 5px;
        padding: 0px 10px;
        -webkit-appearance: none;
        box-sizing: border-box;
        min-height: 41px;
        width: 100%;

        /* &:focus {
            outline: none;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
        } */
    }

    & .MuiOutlinedInput-root {
        fieldset {
            border: none;
        }
        &:hover fieldset {
            border: none;
        }
        &.Mui-focused fieldset {
            border-color: #fff;
            outline: none;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
        }
    }

    .MuiInputBase-input {
        padding: 0;
        font-family: 'Arimo', sans-serif;
        font-size: 16px;
    }

    .Mui-error {
        font-family: 'Arimo', sans-serif;
        color: #ec4453;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
    }
`;

const StyledButton = styled(Button)(() => ({
    background: '#1F62AF',
    textTransform: 'Capitalize',
    color: '#fff',
    borderRadius: '5px',
    width: '260px',
    height: '50px',
    fontSize: 18,
    '&:hover': {
        background: '#1F62AF',
    },
    '@media (max-width: 476px)': {
        fontSize: '15px !important',
    },
}));

const BASE_URI = process.env['REACT_APP_BASE_URI'];

const ProfileEditModal = ({ open, onClose, userId, getUserDetails, userDetails, profilePict }) => {
    const { state, dispatch } = useContext(StoreContext);
    const { userLocation } = state;
    const [autocompleteCities, setAutocompleteCities] = useState([]);
    const [coordinates, setCoordinates] = useState([
        userLocation?.longitude,
        userLocation?.latitude,
    ]);
    console.log(profilePict, ':profilePict1')
    const [allStates, setAllStates] = useState([]);
    const [allCities, setAllCities] = useState([]);
    const [autocompleteErr, setAutocompleteErr] = useState('');
    const initialValues = {
        country: { name: userDetails?.user_detail?.country } || '',
        state: { name: userDetails?.user_detail?.state } || '',
        city: { name: userDetails?.user_detail?.city } || '',
        instagram: userDetails?.user_detail?.insta_profile || '',
        facebook: userDetails?.user_detail?.facebook_profile || '',
        phone: userDetails?.user_detail?.whatsapp_contact || '',
        firstname: userDetails?.user_detail?.first_name || '',
        lastname: userDetails?.user_detail?.last_name || '',
        profilePicture: profilePict || '',
        weight: userDetails?.user_detail?.weight || '',
        fitness: userDetails?.user_detail?.fitness || '',
        birthDate: userDetails?.user_detail?.birthdate
            ? new Date(userDetails?.user_detail?.birthdate)
            : new Date(),
    };
    const validationSchema = yup.object({
        country: yup.object({}).required('Country is required'),
        state: yup.object({}).required('State is required'),
        city: yup.object({}).required('City is required'),
    });

    const { snackBarError, snackBarSuccess, snackBarInfo } = useSnackBar();

    const [image, setImage] = useState(state.userProfilePic);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            updateProfile(values);
        },
    });

    console.log(formik);

    const debounceBack = useCallback(
        debounce(async (newValue) => {
            const res = await fetchPlace(newValue);
            console.log(res);
            !autocompleteCities.includes(newValue) &&
                res.features &&
                setAutocompleteCities(res.features.map((place) => place.place_name));
            setCoordinates(res.features[0]?.geometry?.coordinates);
            res.error ? setAutocompleteErr(res.error) : setAutocompleteErr('');
        }, 1000),
        [],
    );

    const handleCityChange = (e) => {
        formik.setFieldValue('address', e.target.value);
        debounceBack(e.target.value);
    };

    const setLoading = (value, msg) => {
        dispatch({
            type: actions.SET_GLOABL_LOADING,
            payload: {
                loading: value,
                text: msg ? msg : '',
            },
        });
    };

    const updateProfile = async (values) => {
        try {
            setLoading(true, 'Updating profile...');
            const payload = new FormData();
            payload.append('address', `${values.country?.name},${values.city?.name}`);
            payload.append('country', values.country?.name);
            payload.append('state', values.state?.name);
            payload.append('city', values.city?.name);
            payload.append('insta_profile', values.instagram);
            payload.append('facebook_profile', values.facebook);
            payload.append('profile_picture', values.profilePicture);
            payload.append('whatsapp_contact', values.phone);
            payload.append('first_name', values.firstname);
            payload.append('last_name', values.lastname);
            payload.append('weight', values.weight);
            payload.append('fitness', values.fitness);
            payload.append('birthdate', moment(values.birthDate).format('YYYY-MM-DD'));
           
            const res = await axios.post(`${BASE_URI}/user_details/${userId}`, payload);
            const resultData = res.data;
            console.log(resultData);
            // setUserDetails(resultData.data);
            console.log(res);
            if (!(res.status >= 200 && res.status < 400)) return res.statusText;
            const userAuth = JSON.parse(localStorage.getItem('user') || '{}')
            const newDetails = {
                ...userAuth
            }

            newDetails.user_detail = resultData.data
            localStorage.setItem('user', JSON.stringify(newDetails))
            await getUserDetails();
            dispatch({
                type: actions.SET_USER_PROFILE_PIC,
                payload: image,
            });
            snackBarSuccess('Successfully updated user details');
            setLoading(false);
            onClose();
        } catch (error) {
            console.log(error);
            snackBarError('Error: Profile Update Failed');
            setLoading(false);
        }
    };

    // const getCountryIsoCode = async () => {
    //     try {
    //         const response = await axios.get(
    //             `https://restcountries.com/v2/name/${userDetails?.user_detail?.country}`,
    //         );
    //         if (response.data && response.data.length > 0) {
    //             const isoCode = response.data[0].alpha2Code; // Kode negara (ISO code)
    //             formik.setFieldValue('country', {
    //                 name: userDetails?.user_detail?.country,
    //                 isoCode: isoCode,
    //             });
    //         } else {
    //             // console.error(`Tidak dapat menemukan kode negara untuk ${countryName}`);
    //             return null;
    //         }
    //     } catch (error) {
    //         console.error(`Error mengambil data negara: ${error.message}`);
    //         return null;
    //     }
    // };

    const getStates = (countryValue) => {
        const state = State.getStatesOfCountry(countryValue?.isoCode);
        return state;
    };

    const getCities = (stateValue) => {
        const city = City.getCitiesOfState(formik.values.country?.isoCode, stateValue?.isoCode);
        console.log(city, ':cit');
        return city;
    };

    const handleFileChange = (e) => {
        const files = e.target.files;
        console.log(files);
        formik.setFieldValue('profilePicture', files[0]);
        setImage(URL.createObjectURL(files[0]));
    };

    // useEffect(() => {
    //     getCountryIsoCode();
    // }, []);
    return (
        <CustomModal open={open} onClose={onClose} title={'Edit Profile'}>
            <div className={styles.FormBox}>
                <form onSubmit={formik.handleSubmit}>
                    <div className={styles.form__field}>
                        {image ? (
                            <div className={styles.Display__Img}>
                                <div className={styles.Img}>
                                    <img src={image} alt="" />
                                </div>
                                <span>
                                    <Tooltip title="Remove Image">
                                        <IconButton
                                            onClick={() => {
                                                formik.setFieldValue('profilePicture', '');
                                                setImage('');
                                            }}
                                        >
                                            <DeleteOutline />
                                        </IconButton>
                                    </Tooltip>
                                </span>
                            </div>
                        ) : (
                            <Button
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    textTransform: 'capitalize',
                                }}
                                component="label"
                            >
                                <PhotoCameraBackIcon />
                                Upload Image
                                <input
                                    hidden
                                    onChange={handleFileChange}
                                    accept="image/*"
                                    type="file"
                                />
                            </Button>
                        )}
                    </div>
                    {/* <div className={styles.form__field}>
                        <input
                            type="text"
                            name="address"
                            placeholder="Address"
                            value={formik.values.address}
                            onChange={handleCityChange}
                            style={{ width: '100%' }}
                            autoComplete="off"
                            list="address"
                        />
                        <datalist id="address">
                            {autocompleteCities?.map((city, i) => (
                                <option key={i}>{city}</option>
                            ))}
                        </datalist>
                        {autocompleteErr && <p>{autocompleteErr}</p>}
                    </div> */}
                    <div className={styles.form__field}>
                        {/* <StyledTextField
                            name="country"
                            placeholder="Country"
                            value={formik.values.country}
                            onChange={formik.handleChange}
                            sx={{ width: '100%' }}
                            error={formik.touched.country && Boolean(formik.errors.country)}
                            helperText={formik.touched.country && formik.errors.country}
                        /> */}
                        <Autocomplete
                            sx={{ minWidth: '100%' }}
                            // multiple
                            id="tags-standard-location"
                            options={Country.getAllCountries()}
                            getOptionLabel={(option) => option.name}
                            value={formik.values.country}
                            onChange={(e, newValue) => {
                                // formik.setFieldValue('country', newValue);
                                // setAllStates(getStates(newValue));
                                // Menetapkan value menjadi null untuk memicu perubahan
                                formik.setFieldValue('country', null);

                                // Lakukan apa yang perlu dilakukan ketika nilai berubah
                                setAllStates(getStates(newValue));

                                // Mengembalikan value ke nilai sebelumnya
                                formik.setFieldValue('country', newValue);
                            }}
                            renderInput={(params) => (
                                <StyledTextField
                                    {...params}
                                    placeholder="Choose Country"
                                    error={formik.touched.country && Boolean(formik.errors.country)}
                                    helperText={formik.touched.country && formik.errors.country}
                                />
                            )}
                        />
                    </div>
                    {formik.values.country && (
                        <div className={styles.form__field}>
                            <Autocomplete
                                sx={{ minWidth: '100%' }}
                                // multiple
                                id="tags-standard-location"
                                options={allStates}
                                getOptionLabel={(option) => option.name}
                                value={formik.values.state}
                                onChange={(e, newValue) => {
                                    formik.setFieldValue('state', newValue);
                                    setAllCities(getCities(newValue));
                                }}
                                renderInput={(params) => (
                                    <StyledTextField
                                        {...params}
                                        placeholder="Choose state"
                                        error={formik.touched.state && Boolean(formik.errors.state)}
                                        helperText={formik.touched.state && formik.errors.state}
                                    />
                                )}
                            />
                        </div>
                    )}
                    {formik.values.country && formik.values.state && (
                        <div className={styles.form__field}>
                            <Autocomplete
                                sx={{ minWidth: '100%' }}
                                // multiple
                                id="tags-standard-location"
                                options={allCities}
                                getOptionLabel={(option) => option.name}
                                value={formik.values.city}
                                onChange={(e, newValue) => {
                                    formik.setFieldValue('city', newValue);
                                }}
                                renderInput={(params) => (
                                    <StyledTextField
                                        {...params}
                                        placeholder="Choose City"
                                        error={formik.touched.city && Boolean(formik.errors.city)}
                                        helperText={formik.touched.city && formik.errors.city}
                                    />
                                )}
                            />
                        </div>
                    )}
                    <div className={styles.form__field}>
                        <StyledTextField
                            name="firstname"
                            placeholder="First name"
                            value={formik.values.firstname}
                            onChange={formik.handleChange}
                            sx={{ width: '100%' }}
                            error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                            helperText={formik.touched.firstname && formik.errors.firstname}
                        />
                    </div>
                    <div className={styles.form__field}>
                        <StyledTextField
                            name="lastname"
                            placeholder="Last name"
                            value={formik.values.lastname}
                            onChange={formik.handleChange}
                            sx={{ width: '100%' }}
                            error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                            helperText={formik.touched.lastname && formik.errors.lastname}
                        />
                    </div>
                    <div className={styles.form__field}>
                        <StyledTextField
                            name="phone"
                            placeholder="Whatsapp No."
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            sx={{ width: '100%' }}
                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone}
                        />
                    </div>
                    <div className={styles.form__field}>
                        <StyledTextField
                            name="instagram"
                            placeholder="Instagram username"
                            value={formik.values.instagram}
                            onChange={formik.handleChange}
                            sx={{ width: '100%' }}
                            error={formik.touched.instagram && Boolean(formik.errors.instagram)}
                            helperText={formik.touched.instagram && formik.errors.instagram}
                        />
                    </div>
                    <div className={styles.form__field}>
                        <StyledTextField
                            name="facebook"
                            placeholder="Facebook username"
                            value={formik.values.facebook}
                            onChange={formik.handleChange}
                            sx={{ width: '100%' }}
                            error={formik.touched.facebook && Boolean(formik.errors.facebook)}
                            helperText={formik.touched.facebook && formik.errors.facebook}
                        />
                    </div>
                    <div className={styles.form__field}>
                        <StyledTextField
                            name="weight"
                            placeholder="Weight eg: 40"
                            value={formik.values.weight}
                            onChange={formik.handleChange}
                            sx={{ width: '100%' }}
                            error={formik.touched.weight && Boolean(formik.errors.weight)}
                            helperText={formik.touched.weight && formik.errors.weight}
                        />
                    </div>
                    <div className={styles.form__field}>
                        <StyledTextField
                            name="fitness"
                            placeholder="Fitness eg: 4"
                            value={formik.values.fitness}
                            onChange={formik.handleChange}
                            sx={{ width: '100%' }}
                            error={formik.touched.fitness && Boolean(formik.errors.fitness)}
                            helperText={formik.touched.fitness && formik.errors.fitness}
                        />
                    </div>

                    <div className={styles.form__field}>
                        <DatePicker
                            selected={formik.values.birthDate}
                            onChange={(date) => formik.setFieldValue('birthDate', date)}
                            placeholderText="Select birth date"
                        />
                    </div>

                    <div className={styles.form__actions}>
                        <StyledButton type="submit">Update Profile</StyledButton>
                    </div>
                </form>
            </div>
        </CustomModal>
    );
};

export default ProfileEditModal;
