import React, { useCallback, useContext, useState } from 'react';
import { Alert, Snackbar, Typography } from '@mui/material';

const SnackbarContext = React.createContext();

const SNACKBAR_TYPE = {
    INFO: 'info',
    WARNING: 'warning',
    SUCCESS: 'success',
    ERROR: 'error',
};

export const useSnackBar = () => useContext(SnackbarContext);

const initialValue = {
    display: false,
    type: '',
    message: undefined,
    duration: 3000,
};

export function SnackbarProvider({ children }) {
    const [snackBarState, setSnackBarState] = useState({ ...initialValue });

    const snackBarError = useCallback((message, duration = 3000) => {
        setSnackBarState({
            display: true,
            message,
            type: 'error',
            duration,
        });
    }, []);
    const snackBarWarning = useCallback((message, duration = 3000) => {
        setSnackBarState({
            display: true,
            message,
            type: 'warning',
            duration,
        });
    }, []);
    const snackBarInfo = useCallback((message, duration = 3000) => {
        setSnackBarState({
            display: true,
            message,
            type: 'info',
            duration,
        });
    }, []);

    const snackBarSuccess = useCallback((message, duration = 3000) => {
        setSnackBarState({
            display: true,
            message,
            type: 'success',
            duration,
        });
    }, []);

    const resetSnackBarState = useCallback(() => setSnackBarState({ ...initialValue }), []);

    return (
        <SnackbarContext.Provider
            value={{ snackBarError, snackBarInfo, snackBarWarning, snackBarSuccess }}
        >
            <Snackbar
                open={snackBarState?.display}
                autoHideDuration={snackBarState?.duration}
                onClose={() => resetSnackBarState()}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                <Alert
                    onClose={() => resetSnackBarState()}
                    severity={
                        snackBarState.type === SNACKBAR_TYPE.SUCCESS
                            ? 'success'
                            : snackBarState.type === SNACKBAR_TYPE.ERROR
                            ? 'error'
                            : snackBarState.type === SNACKBAR_TYPE.INFO
                            ? 'info'
                            : 'warning'
                    }
                    sx={{
                        padding: '8px 16px',
                    }}
                >
                    {typeof snackBarState.message === 'string' ? (
                        <Typography fontSize={{ xs: 12, sm: 16 }} fontWeight={500}>
                            {snackBarState.message}
                        </Typography>
                    ) : (
                        snackBarState.message
                    )}
                </Alert>
            </Snackbar>

            {children}
        </SnackbarContext.Provider>
    );
}
