import { CustomModal } from 'components/common/Modal';
import styles from './rateActivity.module.scss';
import { frequencyNumbers, friendsNumber, ratingNumbers } from 'constants/ratingsScale';
import { Button, styled } from '@mui/material';
import axios from 'axios';
import { useContext } from 'react';
import { StoreContext } from 'store/useStore';
import actions from 'store/action';
import { useSnackBar } from 'hoc/snackbar';

const StyledButton = styled(Button)(({ bg }) => ({
    background: bg,
    textTransform: 'Capitalize',
    color: bg === 'none' ? '#1F62AF' : '#fff',
    borderRadius: '5px',
    width: '123px',
    height: '41px',
    fontSize: 18,
    fontWeight: 700,
    '&:hover': {
        background: bg,
    },
    '@media (max-width: 476px)': {
        fontSize: '15px !important',
    },
}));

const BASE_URI = process.env['REACT_APP_BASE_URI'];

const RateActivityModal = ({ open, setOpen, activity, myActivity, setMyActivity }) => {
    const { state, dispatch } = useContext(StoreContext);
    const { snackBarError, snackBarSuccess, snackBarInfo } = useSnackBar();

    const onClose = () => setOpen(false);

    const setLoading = (value, msg) => {
        dispatch({
            type: actions.SET_GLOABL_LOADING,
            payload: {
                loading: value,
                text: msg ? msg : '',
            },
        });
    };

    const getRatedActivity = async () => {
        try {
            setLoading(true);
            const jwt = localStorage.getItem('jwt');
            const options = {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            };
            const res = await axios.get(`${BASE_URI}/activities/my_activities`, options);
            const resultData = res.data;
            const currActivity = resultData.data.find((item) => item.activity.id === activity.id);
            setMyActivity(currActivity);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handleLike = async (e) => {
        try {
            if (!state.user) throw 'Please login first';
            setLoading(true, 'Rating activity...');
            const activityId = activity.id;
            const payload = {
                like: e.target.value,
            };
            const jwt = localStorage.getItem('jwt');
            const options = {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': 'application/json',
                },
            };
            const res = await axios.post(
                `${BASE_URI}/activities/${activityId}/like`,
                payload,
                options,
            );
            if (!(res.status >= 200 && res.status < 400)) return res.statusText;
            const resData = res.data;
            await getRatedActivity();
            snackBarSuccess(`Successfully rated ${resData.data.activity.name}`);
            console.log(res);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            snackBarError(`Error: ${JSON.stringify(error)}`);
        }
    };

    const handleFrequency = async (e) => {
        try {
            if (!state.user) throw 'Please login first';
            setLoading(true, 'Rating activity...');
            const activityId = activity.id;
            const payload = {
                frequency: e.target.value,
            };
            const jwt = localStorage.getItem('jwt');
            const options = {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': 'application/json',
                },
            };
            const res = await axios.post(
                `${BASE_URI}/activities/${activityId}/frequency`,
                payload,
                options,
            );
            if (!(res.status >= 200 && res.status < 400)) return res.statusText;
            const resData = res.data;
            await getRatedActivity();
            snackBarSuccess(`Successfully rated ${resData.data.activity.name}`);
            console.log(res);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            snackBarError(`Error: ${JSON.stringify(error)}`);
        }
    };

    const handleSocial = async (e) => {
        try {
            if (!state.user) throw 'Please login first';
            setLoading(true, 'Rating activity...');
            const activityId = activity.id;
            const payload = {
                social: e.target.value,
            };
            const jwt = localStorage.getItem('jwt');
            const options = {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': 'application/json',
                },
            };
            const res = await axios.post(
                `${BASE_URI}/activities/${activityId}/social`,
                payload,
                options,
            );
            if (!(res.status >= 200 && res.status < 400)) return res.statusText;
            const resData = res.data;
            await getRatedActivity();
            snackBarSuccess(`Successfully rated ${resData.data.activity.name}`);
            console.log(res);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            snackBarError(`Error: ${JSON.stringify(error)}`);
        }
    };

    return (
        <CustomModal open={open} onClose={onClose} title={activity?.name}>
            <div className={styles.actions__box}>
                <div className={styles.activity__actions}>
                    <select onChange={handleLike} value={myActivity?.like || ''}>
                        <option value={''} disabled>
                            How do you like it?
                        </option>
                        {Object.keys(ratingNumbers).map((key, idx) => (
                            <option key={idx} value={key}>
                                {ratingNumbers[key]}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={styles.activity__actions}>
                    <select onChange={handleFrequency} value={myActivity?.frequency || ''}>
                        <option value={''} disabled>
                            Have you done it?
                        </option>
                        {Object.keys(frequencyNumbers).map((key, idx) => (
                            <option key={idx} value={key}>
                                {frequencyNumbers[key]}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={styles.activity__actions}>
                    <select onChange={handleSocial} value={myActivity?.social || ''}>
                        <option value={''} disabled>
                            Who can see you?
                        </option>
                        {Object.keys(friendsNumber).map((key, idx) => (
                            <option key={idx} value={key}>
                                {friendsNumber[key]}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={styles.submitBtn}>
                    <StyledButton onClick={onClose} bg="none">
                        Done
                    </StyledButton>
                </div>
            </div>
        </CustomModal>
    );
};

export default RateActivityModal;
