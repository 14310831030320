export const ratingNumbers = {
    1: "I don't like",
    2: "I don't mind",
    3: 'I like',
    4: 'I love',
    5: "I'm passionate",
};

export const friendsNumber = {
    1: 'Just me',
    2: 'Selected friends',
    3: 'Friends',
    4: 'Like-minded',
    5: 'Everyone',
};

export const frequencyNumbers = {
    1: 'I never did',
    2: 'I did at least once',
    3: 'I did several times',
    4: 'I did many times',
    5: "I'm doing it regularly",
};
