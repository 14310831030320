const actions = {
    SET_DATA: 'SET_DATA',
    SET_SEARCH_DATA: 'SET_SEARCH_DATA',
    CLEAR_SEARCH_DATA: 'CLEAR_SEARCH_DATA',
    SET_LOCATION: 'SET_LOCATION',
    SET_GLOABL_LOADING: 'SET_GLOABL_LOADING',
    SET_USER: 'SET_USER',
    SET_USERS: 'SET_USERS',
    ADD_LISTS: 'ADD_LISTS',
    SET_WEATHER_INFO: 'SET_WEATHER_INFO',
    SET_USER_PROFILE_PIC: 'SET_USER_PROFILE_PIC',
};

export default actions;
