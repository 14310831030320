import React, { useState, useContext, useEffect } from 'react';
import styles from './profile.module.scss';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Button, IconButton, Tooltip, styled } from '@mui/material';
import ProfileEditModal from 'components/ProfileEditModal';
// import profilePic from 'assets/images/profilePic.png';
import PeopleLists from 'components/PeopleLists';
import placeList1 from 'assets/images/buckList1.png';
import placeList2 from 'assets/images/buckList2.png';
import placeList3 from 'assets/images/buckList3.png';
import placeList4 from 'assets/images/buckList4.png';
import locationIcon from 'assets/images/location.png';
import whatsappIcon from 'assets/images/whatsapp.png';
import instagramIcon from 'assets/images/instagram.png';
import facebookIcon from 'assets/images/facebook.png';
import { liked } from 'constants/userLiking';
import avatar1 from 'assets/images/avatar1.png';
import avatar2 from 'assets/images/avatar2.png';
import avatar3 from 'assets/images/avatar3.png';
import avatar4 from 'assets/images/avatar4.png';
import avatar5 from 'assets/images/avatar5.png';
import CreateListModal from 'components/CreateListModal';
import {
    Link,
    Router,
    useLocation,
    useRoutes,
    useR,
    useParams,
    useNavigate,
} from 'react-router-dom';
import { StoreContext } from 'store/useStore';
import actions from 'store/action';
import axios from 'axios';
import { useSnackBar } from 'hoc/snackbar';
import UserActivityTabs from 'components/UserActivityTabs';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ArrowLeft from 'assets/images/angle-left.png';
import { StarBorderOutlined } from '@mui/icons-material';
import Card from 'components/Card';
import MobileHeader from 'components/MobileHeader';
import ProfilePicture from 'components/ProfilePicture';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div style={{ paddingBottom: '30px' }}>{children}</div>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const StyledButton = styled(Button)(({ bg, customFontWeight, customFontSize }) => ({
    background: bg,
    textTransform: 'Capitalize',
    color: bg === 'none' ? '#1F62AF' : '#fff',
    borderRadius: '5px',
    width: 'fit-content',
    height: '50px',
    fontSize: customFontSize ?? 18,
    fontWeight: customFontWeight ?? 400,
    '@media (max-width: 476px)': {
        fontSize: '15px !important',
    },
}));

const BASE_URI = process.env['REACT_APP_BASE_URI'];

const tabMapping = {
    ALL: 0,
    LIKES: 1,
    DONE: 2,
};


const UserProfile = () => {
    const { snackBarError, snackBarSuccess, snackBarInfo } = useSnackBar();
    const [openProfileEditModal, setOpenProfileEditModal] = useState(false);
    const [openListModal, setOpenListModal] = useState(false);
    const [selectedTab, setSelectedTab] = useState(1);
    const { state, dispatch } = useContext(StoreContext);
    const { user } = state;
    const location = useLocation();
    const { profileTab, userId } = useParams();
    const [userDetails, setUserDetails] = useState();
    const [profilePic, setProfilePic] = useState('');
    const [mutualLikes, setMutualLikes] = useState();
    const [likeUser, setLikeUser] = useState();
    const [doneUser, setDoneUser] = useState();
    const [tabLike, setTabLike] = useState();
    const [tabDone, setTabDone] = useState();
    const jwt = localStorage.getItem('jwt');
    const [value, setValue] = React.useState(0);
    const [mobileOpen, setMobileOpen] = useState(true);
    const userLocal = JSON.parse(localStorage.getItem('user'));
    console.log(state, ':state')

    const isOwnProfile = () => {
        console.log(userLocal !== null && userLocal.id == userId, ':userId')
        return userLocal !== null && userLocal.id == userId
    }

    const navigate = useNavigate();

    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 672);

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    console.log(mutualLikes, ':mutualLikes')
    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };


    

    useEffect(() => {
        if (!profileTab) {
            setValue(0);
        } else {
            const tabValue = profileTab.toUpperCase();
            setValue(tabMapping[tabValue]);
        }
    }, [profileTab]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (windowSize.width > 672) {
            setMobileOpen(true);
        }
    }, [windowSize]);

    console.log(windowSize);

    console.log(userDetails, ':userdetails1')

    const handleChange = (event, newValue) => {
        setValue(newValue);
        switch (newValue) {
            case tabMapping.ALL:
                navigate(`/profile/${userId}`);
                break;
            case tabMapping.LIKES:
                navigate(`/profile/${userId}/likes`);
                break;
            case tabMapping.DONE:
                navigate(`/profile/${userId}/done`);
                break;
            default:
                break;
        }
    };

    const getUserDisplayName = () => {
        const displayName = userDetails?.user_detail?.first_name ? `${userDetails?.user_detail?.first_name} ${userDetails?.user_detail?.last_name || ''}` : userDetails?.username
        return displayName
    }

    const setLoading = (value, msg) => {
        dispatch({
            type: actions.SET_GLOABL_LOADING,
            payload: {
                loading: value,
                text: msg ? msg : '',
            },
        });
    };

    const getUserDetails = async () => {
        try {
            setLoading(true, 'Fetching User Details...');
            const options = {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    Origin: '*',
                    'ngrok-skip-browser-warning': '69420',
                },
            };
            const res = await axios.get(`${BASE_URI}/user${'/' + userId}`, options);
            if (res.status !== 200) throw res.statusText;
            const resultData = res.data;
            console.log(resultData.data, ':userDetails')

            // dispatch({
            //     type: actions.SET_USER,
            //     payload: resultData.data,
            // });

            // localStorage.setItem('user', JSON.stringify(resultData.data));

            if(userLocal && (userLocal.id === resultData.data.id)) {
                const userProfileData = {
                    access_token: userLocal?.access_token,
                    ...resultData.data
                }
                console.log('own user')
                dispatch({
                    type: actions.SET_USER,
                    payload: resultData.data,
                });
                localStorage.setItem('user', JSON.stringify(userProfileData))
            }

            setUserDetails(resultData.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            // snackBarError(error.toString());
        }
    };

    // const getUserProfile = async () => {
    //     try {
    //         setLoading(true, 'Fetching User Details...');
    //         const res = await axios.get(`${BASE_URI}/user${'/' + userId}`);
    //         if (res.status !== 200) throw res.statusText;
    //         const resultData = res.data;

    //         // dispatch({
    //         //     type: actions.SET_USER,
    //         //     payload: resultData.data,
    //         // });

    //         localStorage.setItem('user', JSON.stringify(resultData.data));

    //         setUserDetails(resultData.data);
    //         setLoading(false);
    //     } catch (error) {
    //         console.log(error);
    //         setLoading(false);
    //         // snackBarError(error.toString());
    //     }
    // };

    const getProfilePic = async () => {
        try {
            const options = {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    Origin: '*',
                    'ngrok-skip-browser-warning': '69420',
                    responseType: 'arraybuffer',
                },
            };
            const res = await axios.get(`${BASE_URI}/user_details/${userId}/profile_picture`, {
                responseType: 'arraybuffer',
            });
            // if (res.statusCode === 404) {
            //     setProfilePic(null);
            // }
            const resultData = new Blob([res.data], { type: 'image/png' });
            // // Create URL from Blob
            var imageUrl = URL.createObjectURL(resultData);
            setProfilePic(imageUrl);
            if(isOwnProfile) {
                dispatch({
                    type: actions.SET_USER_PROFILE_PIC,
                    payload: imageUrl,
                });
            }
            
        } catch (error) {
            console.log(error);
            setProfilePic('');
            dispatch({
                type: actions.SET_USER_PROFILE_PIC,
                payload: '',
            });
        }
    };
    const getMutualLikes = async () => {
        try {
            setLoading(true, 'Fetching User Details...');
            const options = {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    Origin: '*',
                    'ngrok-skip-browser-warning': '69420',
                },
            };
            const res = await axios.get(`${BASE_URI}/profile/bothlike${'/' + userId}`, options);
            if (res.status !== 200) {
                setMutualLikes(null);
                throw res.statusText;
            }
            const resultData = res.data;

            setMutualLikes(resultData.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            // snackBarError(error.toString());
        }
    };

    // const getUserLikes = async () => {
    //     try {
    //         setLoading(true, 'Fetching User Details...');
    //         const options = {
    //             headers: {
    //                 Authorization: `Bearer ${jwt}`,
    //                 Origin: '*',
    //                 'ngrok-skip-browser-warning': '69420',
    //             },
    //         };
    //         const res = await axios.get(`${BASE_URI}/profile/like${'/' + userId}`, options);
    //         if (res.status !== 200) throw res.statusText;
    //         const resultData = res.data;

    //         setLikeUser(resultData.data);
    //         setLoading(false);
    //     } catch (error) {
    //         console.log(error);
    //         setLoading(false);
    //         // snackBarError(error.toString());
    //     }
    // };

    // const getUserDone = async () => {
    //     try {
    //         setLoading(true, 'Fetching User Details...');
    //         const options = {
    //             headers: {
    //                 Authorization: `Bearer ${jwt}`,
    //                 Origin: '*',
    //                 'ngrok-skip-browser-warning': '69420',
    //             },
    //         };
    //         const res = await axios.get(`${BASE_URI}/profile/done${'/' + userId}`, options);
    //         if (res.status !== 200) throw res.statusText;
    //         const resultData = res.data;

    //         setDoneUser(resultData.data);
    //         setLoading(false);
    //     } catch (error) {
    //         console.log(error);
    //         setLoading(false);
    //         // snackBarError(error.toString());
    //     }
    // };

    // const getTabLike = async () => {
    //     try {
    //         setLoading(true, 'Fetching User Details...');
    //         const options = {
    //             headers: {
    //                 Authorization: `Bearer ${jwt}`,
    //                 Origin: '*',
    //                 'ngrok-skip-browser-warning': '69420',
    //             },
    //         };
    //         const res = await axios.get(`${BASE_URI}/profile/tab/like/${userId}`, options);
    //         if (res.status !== 200) throw res.statusText;
    //         const resultData = res.data;
    //         const activityData = resultData.data[0].activities?.map((item) => {
    //             const obj = {
    //                 total_frequencies: item?.total_done,
    //                 total_likes: item?.total_like,
    //                 activity: item,
    //             };
    //             return obj;
    //         });
    //         console.log(activityData, ':activuty');
    //         console.log(resultData.data, ':tab like');
    //         const like_rating = resultData.data[0].like_rating;

    //         setTabLike({
    //             activityData,
    //             like_rating,
    //         });
    //         setLoading(false);
    //     } catch (error) {
    //         console.log(error);
    //         setLoading(false);
    //         // snackBarError(error.toString());
    //     }

    //     console.log(tabLike, ':tabLike1');
    // };

    // const getTabDone = async () => {
    //     try {
    //         setLoading(true, 'Fetching User Details...');
    //         const options = {
    //             headers: {
    //                 Authorization: `Bearer ${jwt}`,
    //                 Origin: '*',
    //                 'ngrok-skip-browser-warning': '69420',
    //             },
    //         };
    //         const res = await axios.get(`${BASE_URI}/profile/tab/done/${userId}`, options);
    //         if (res.status !== 200) throw res.statusText;
    //         const resultData = res.data;

    //         setTabDone(resultData.data);
    //         setLoading(false);
    //     } catch (error) {
    //         console.log(error);
    //         setLoading(false);
    //         // snackBarError(error.toString());
    //     }
    // };

    const userRating = async () => {
        try {
            setLoading(true, 'Fetching User Details...');
            const res = await axios.get(`${BASE_URI}/user/${userId}/activity_ratings`);
            const result = res.data;
            const activityData = result.data;
            console.log(result, ':activity rating ');

            if (activityData?.length === 0) return;

            // The liked rating should include count of likes ratings among 3,4,5
            const likedData = activityData.filter((item) => item.like && item.like > 2);
            console.log(likedData, ':likedData');

            const formattedLikeActivityData = likedData.map((item) => {
                const obj = {
                    activity: item.activity,
                    ...item,
                };
                return obj;
            });

            setTabLike(formattedLikeActivityData);

            // The done rating should include count of likes ratings among 2,3,4,5
            const doneData = activityData.filter((item) => item.frequency && item.frequency > 1);
            console.log(doneData, ':doneData');

            const formattedDoneActivityData = doneData.map((item) => {
                const obj = {
                    activity: item.activity,
                    ...item,
                };
                return obj;
            });

            setTabDone(formattedDoneActivityData);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handleCloseModal = () => {
        setOpenProfileEditModal(false);
    };

    function renderAdress(text) {
        const kataTerpisah = text.split(',');
        const kataHasil = kataTerpisah.join(', ');
        return kataHasil;
    }

    const handleSeeAllClick = (index) => {
        setValue(index);
        setMobileOpen(true);
        switch (index) {
            case tabMapping.ALL:
                navigate(`/profile/${userId}`);
                break;
            case tabMapping.LIKES:
                navigate(`/profile/${userId}/likes`);
                break;
            case tabMapping.DONE:
                navigate(`/profile/${userId}/done`);
                break;
            default:
                break;
        }
    };

    const handleBackkMobile = (index) => {
        setValue(index);
        setMobileOpen(false);
        switch (index) {
            case tabMapping.ALL:
                navigate(`/profile/${userId}`);
                break;
            case tabMapping.LIKES:
                navigate(`/profile/${userId}/likes`);
                break;
            case tabMapping.DONE:
                navigate(`/profile/${userId}/done`);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        getUserDetails();
        userRating();
        // getUserLikes();
        // getUserDone();
        getMutualLikes();
        // getTabLike();
        // getTabDone();
    }, [userId]);

    useEffect(() => {
        getProfilePic();
    }, [userDetails]);

    useEffect(() => {
        if(!userLocal?.id) {
            navigate('/')
        }
    }, [userLocal])

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [profileTab]);

    console.log(tabLike, ':tabLike');

    if (!userDetails) {
        if (state.globalLoading) {
            return <></>;
        } else {
            return <p>User not found</p>;
        }
    }

    const getCapitalizeName = (text) => {
        const capitalize = text.charAt(0).toUpperCase() + text.slice(1, text.length);
        return capitalize;
    };
    
    

    console.log(tabDone, ':TAB');

    return (
        <div className={styles.profile}>
            <MobileHeader title={userDetails?.user_detail?.first_name ? `${userDetails?.user_detail?.first_name} ${userDetails?.user_detail?.last_name || ''}` : userDetails?.username} />
            <div className={styles.profile__container}>
                <div className={styles.profile__aSide}>
                    <div className={styles.userInfo}>
                        <div className={styles.userInfo__avatar}>
                            {profilePic ? (
                                <div className={styles.img}>
                                    <img src={profilePic} alt="" />
                                </div>
                            ) : (
                                <div className={styles.blankProfile}></div>
                            )}
                        </div>
                        <div className={styles.userInfo__content}>
                            <div className={styles.UserInfo__Header}>
                                <h4 className={styles.name}>{userDetails?.user_detail?.first_name ? `${userDetails?.user_detail?.first_name} ${userDetails?.user_detail?.last_name || ''}` : userDetails?.username}</h4>
                                {/* <div className={styles.userInfo__Ctx}>
                                    {userDetails?.user_detail?.address && (
                                        <p className={styles.text__primary}>
                                            {userDetails?.user_detail?.address}
                                        </p>
                                    )}
                                    <p className={styles.text__primary}>
                                            Hi, i&apos;m John, this is my bio text, you can read it
                                            if you like
                                        </p>
                                    <p
                                        className={`${styles.text__secondary} ${styles.edit__desktop}`}
                                        onClick={() => setOpenProfileEditModal(true)}
                                    >
                                        Edit profile
                                    </p>
                                </div> */}
                                <div className={styles.user__frequency}>
                                    <p>
                                        <span>{tabLike ? tabLike?.length : 0}</span> Likes
                                    </p>
                                    <p>
                                        <span>{tabDone ? tabDone?.length : 0}</span> Done
                                    </p>
                                    <p>
                                        <span>0</span> Friends
                                    </p>
                                </div>
                                <div className={styles.userInfo__Contact}>
                                    {/* <h4 className={styles.edit__desktop}>Contact</h4> */}
                                    {userDetails?.user_detail?.address ||
                                    userDetails?.user_detail?.whatsapp_contact ||
                                    userDetails?.user_detail?.insta_profile ? (
                                        <>
                                            {userDetails?.user_detail?.address && (
                                                <p className={styles.text__primary}>
                                                    {renderAdress(
                                                        userDetails?.user_detail?.address,
                                                    )}
                                                    {/* {userDetails?.user_detail?.address} */}
                                                </p>
                                            )}
                                            <p className={styles.dot}>•</p>

                                            {userDetails?.user_detail?.whatsapp_contact && (
                                                <div className={styles.userInfo__ContactItem}>
                                                    <a
                                                        href={`https://wa.me/${userDetails?.user_detail?.whatsapp_contact}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <span className={styles.text__primary}>
                                                            Whatsapp
                                                        </span>
                                                    </a>
                                                    {/* <span className={styles.text__secondary}>
                                            {userDetails?.user_detail?.whatsapp_contact}
                                        </span> */}
                                                </div>
                                            )}
                                            <p className={styles.dot}>•</p>
                                            {userDetails?.user_detail?.insta_profile && (
                                                <div className={styles.userInfo__ContactItem}>
                                                    <a
                                                        href={`https://www.instagram.com/${userDetails?.user_detail?.insta_profile}/`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <span className={styles.text__primary}>
                                                            Instagram
                                                        </span>
                                                    </a>
                                                    {/* <span className={styles.text__secondary}>
                                            {userDetails?.user_detail?.insta_profile}
                                        </span> */}
                                                </div>
                                            )}
                                            <p className={styles.dot}>•</p>
                                            {userDetails?.user_detail?.facebook_profile && (
                                                <div className={styles.userInfo__ContactItem}>
                                                    <a
                                                        href={`https://www.facebook.com/${userDetails?.user_detail?.facebook_profile}/`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <span className={styles.text__primary}>
                                                            Facebook
                                                        </span>
                                                    </a>
                                                    {/* <span className={styles.text__secondary}>
                                            {userDetails?.user_detail?.facebook_profile}
                                        </span> */}
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div></div>
                                    )}

                                    {/* <p
                                        className={`${styles.text__secondary} ${styles.edit__mobile}`}
                                        onClick={() => setOpenProfileEditModal(true)}
                                    >
                                        Edit profile
                                    </p> */}
                                </div>
                            </div>
                            <div className={styles.user__action}>
                                {userLocal !== null && userLocal.id == userId ? (
                                    <button
                                        className={styles.user__buttonSecondary}
                                        onClick={() => setOpenProfileEditModal(true)}
                                    >
                                        Edit profile
                                    </button>
                                ) : (
                                    <>
                                        <button className={styles.user__buttonPrimary}>
                                            Add Friend
                                        </button>
                                        <Link
                                            to={`/chat?user=${userId}`}
                                            className={styles.user__buttonSecondary}
                                        >
                                            Message
                                        </Link>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                            >
                                <Tab label="All" {...a11yProps(0)} />
                                <Tab label="Like" {...a11yProps(1)} />
                                <Tab label="Done" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                    </Box>
                </div>

                <div className={styles.mobile_profile}>
                    <div className={styles.mobile_profile__header}>
                        <div className={styles.userInfo__avatar}>
                            <div className={styles.img}>
                                <img src={profilePic} alt="" />
                            </div>
                        </div>
                        <div className={styles.user__frequency}>
                            <p>
                                <span>{tabLike ? tabLike?.length : 0}</span> Likes
                            </p>
                            <p>
                                <span>{tabDone ? tabDone?.length : 0}</span> Done
                            </p>
                            <p>
                                <span>0</span> Friends
                            </p>
                        </div>
                    </div>
                    <h4 className={styles.name}>{getUserDisplayName()}</h4>
                    <div className={styles.user__action}>
                        {userLocal !== null && userLocal.id == userId ? (
                            <>
                                <button
                                    className={styles.user__buttonSecondary}
                                    onClick={() => setOpenProfileEditModal(true)}
                                >
                                    Edit profile
                                </button>
                                <button
                                    className={styles.user__buttonSecondary}
                                    onClick={() => setOpenProfileEditModal(true)}
                                >
                                    •••
                                </button>
                            </>
                        ) : (
                            <>
                                <button className={styles.user__buttonPrimary}>Add Friend</button>
                                <Link
                                    to={`/chat?user=${userId}`}
                                    className={styles.user__buttonSecondary}
                                >
                                    Message
                                </Link>
                            </>
                        )}
                        {/* <button className={styles.user__buttonPrimary}>Add Friend</button> */}
                        {/* <button className={styles.user__buttonSecondary}>Message</button> */}
                    </div>
                    <div className={styles.userInfo__Contact}>
                        {/* <h4 className={styles.edit__desktop}>Contact</h4> */}
                        {userDetails?.user_detail?.address && (
                            <div className={styles.userInfo__ContactItem}>
                                <img src={locationIcon} alt="" />
                                <p className={styles.text__primary}>
                                    {renderAdress(userDetails?.user_detail?.address)}
                                </p>
                            </div>
                        )}
                        {userDetails?.user_detail?.whatsapp_contact && (
                            <div className={styles.userInfo__ContactItem}>
                                <img src={whatsappIcon} alt="" />
                                <a
                                    href={`https://wa.me/${userDetails?.user_detail?.whatsapp_contact}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span className={styles.text__primary}>Whatsapp</span>
                                </a>
                                {/* <span className={styles.text__primary}>Whatsapp</span> */}
                                {/* <span className={styles.text__secondary}>
                                            {userDetails?.user_detail?.whatsapp_contact}
                                        </span> */}
                            </div>
                        )}
                        {userDetails?.user_detail?.insta_profile && (
                            <div className={styles.userInfo__ContactItem}>
                                <img src={instagramIcon} alt="" />
                                <a
                                    href={`https://www.instagram.com/${userDetails?.user_detail?.insta_profile}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span className={styles.text__primary}>Instagram</span>
                                </a>
                                {/* <span className={styles.text__primary}>Instagram</span> */}
                                {/* <span className={styles.text__secondary}>
                                            {userDetails?.user_detail?.insta_profile}
                                        </span> */}
                            </div>
                        )}
                        {userDetails?.user_detail?.facebook_profile && (
                            <div className={styles.userInfo__ContactItem}>
                                <img src={facebookIcon} alt="" />
                                <a
                                    href={`https://www.facebook.com/${userDetails?.user_detail?.facebook_profile}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span className={styles.text__primary}>Facebook</span>
                                </a>
                                {/* <span className={styles.text__primary}>Facebook</span> */}
                                {/* <span className={styles.text__secondary}>
                                            {userDetails?.user_detail?.facebook_profile}
                                        </span> */}
                            </div>
                        )}
                    </div>
                </div>

                <CustomTabPanel value={value} index={0}>
                    <div className={styles.user__content}>
                        <div>
                            {userLocal !== null && userLocal?.id != userId ? (
                                <div className={styles.user__activityList}>
                                    <div className={styles.user__activityHeader}>
                                        <h3>{mutualLikes?.length} Mutual likes</h3>
                                    </div>
                                    <div className={styles.user__lists}>
                                        {mutualLikes?.map((item) => (
                                            <Link
                                                key={item.activity.id}
                                                to={`/activity/${item.activity.name}`}
                                                style={{ textDecoration: 'none', color: 'black' }}
                                            >
                                                <div className={styles.user__lists__card}>
                                                    <div className={styles.user__lists__image}>
                                                        <img
                                                            src={item.activity.pexel_images[0]?.src}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className={styles.user__lists__content}>
                                                        <h1>{item.activity.name}</h1>
                                                        <div
                                                            className={
                                                                styles.user__lists__frequency
                                                            }
                                                        >
                                                            <p>{item.total_like} Likes</p>
                                                            <p>{item.total_frequencie} Done</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div></div>
                            )}

                            <div className={styles.user__activityList}>
                                <div className={styles.user__activityHeader}>
                                    <h3>{isOwnProfile() ? 'I Like' : getCapitalizeName(getUserDisplayName()) + ' Likes'}</h3>
                                </div>
                                <div className={styles.user__lists}>
                                    {/* {likeUser?.map((item) => (
                                        <Card key={item?.activity?.name} data={item?.activity} />
                                    ))} */}
                                    {tabLike !== undefined &&
                                        tabLike
                                            ?.slice(0, 4)
                                            ?.map((item, key) => (
                                                <Card
                                                    key={item?.name}
                                                    data={item?.activity}
                                                    isProfile={true}
                                                    updateRatings={userRating}
                                                />
                                            ))}
                                </div>
                                <div style={{ marginTop: '20px' }}>
                                    <button
                                        onClick={() => handleSeeAllClick(1)}
                                        className={styles.button__showmore}
                                    >
                                        See all
                                    </button>
                                </div>
                            </div>

                            <div className={styles.user__activityList}>
                                <div className={styles.user__activityHeader}>
                                    <h3>{isOwnProfile() ? 'I Did' : getCapitalizeName(getUserDisplayName()) + ' Done'}</h3>
                                </div>
                                <div className={styles.user__lists}>
                                    {tabDone !== undefined &&
                                        tabDone
                                            ?.slice(0, 4)
                                            ?.map((item) => (
                                                <Card
                                                    key={item?.activity?.name}
                                                    data={item?.activity}
                                                    isProfile={true}
                                                    updateRatings={userRating}
                                                />
                                            ))}
                                </div>
                                <div style={{ marginTop: '20px' }}>
                                    <button
                                        onClick={() => handleSeeAllClick(2)}
                                        className={styles.button__showmore}
                                    >
                                        See all
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* <UserActivityTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} /> */}
                        {/* <div className={styles.profile__grid}> */}
                        <div className={styles.profile__bSide}>
                            <div className={styles.friend__list}>
                                <div className={styles.friend__header}>
                                    <h4>Friends</h4>
                                    <StyledButton bg={'none'} customFontSize={'16px'}>
                                        See all friends
                                    </StyledButton>
                                </div>
                                <div className={styles.user__friends}>
                                    <p>Friends will be displayed here</p>
                                    {/* <PeopleLists title={''} itemList={friends} grid={1} /> */}
                                </div>
                                <div className={styles.user__friends}>
                                    {/* <PeopleLists
                                        title={'Like minded people'}
                                        itemList={likeMinded}
                                        grid={1}
                                    /> */}
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <div className={mobileOpen && !isLargeScreen ? styles.ContentTab : ''}>
                        <div className={styles.card__header}>
                            <button onClick={() => handleBackkMobile(0)}>
                                <img src={ArrowLeft} alt="" />
                            </button>
                            <div className={styles.card__header__title}>
                                <p>{isOwnProfile() ? 'I Like' : getCapitalizeName(getUserDisplayName()) + ' Likes'}</p>
                            </div>
                        </div>
                        <div className={styles.card__content}>
                            <div className={styles.user__activityHeader}>
                                <h3>{isOwnProfile() ? 'I am passionate' : getCapitalizeName(getUserDisplayName()) + ' is passionnate'}</h3>
                            </div>
                            <div className={styles.card__content__lists}>
                                {tabLike !== undefined &&
                                    tabLike
                                        ?.filter((item) => item?.like === 5)
                                        ?.map((item) => (
                                            <Card
                                                key={item?.activity?.name}
                                                data={item?.activity}
                                                isProfile={true}
                                                updateRatings={userRating}
                                            />
                                        ))}
                            </div>
                        </div>
                        <div className={styles.card__content}>
                            <div className={styles.user__activityHeader}>
                                <h3>{isOwnProfile() ? 'I Love' : getCapitalizeName(getUserDisplayName()) + ' Loves'}</h3>
                            </div>
                            <div className={styles.card__content__lists}>
                                {tabLike !== undefined &&
                                    tabLike
                                        ?.filter((item) => item?.like === 4)
                                        ?.map((item) => (
                                            <Card
                                                key={item?.activity?.name}
                                                data={item?.activity}
                                                isProfile={true}
                                                updateRatings={userRating}
                                            />
                                        ))}
                            </div>
                        </div>
                        <div className={styles.card__content}>
                            <div className={styles.user__activityHeader}>
                                <h3>{isOwnProfile() ? 'I Like' : getCapitalizeName(getUserDisplayName()) + ' like'}</h3>
                            </div>
                            <div className={styles.card__content__lists}>
                                {tabLike !== undefined &&
                                    tabLike
                                        ?.filter((item) => item?.like === 3)
                                        ?.map((item) => (
                                            <Card
                                                key={item?.activity?.name}
                                                data={item?.activity}
                                                isProfile={true}
                                                updateRatings={userRating}
                                            />
                                        ))}
                            </div>
                        </div>
                        {userLocal?.id == userId && (
                            <div className={styles.card__content}>
                                <div className={styles.user__activityHeader}>
                                    {/* <h3>
                                        {' '}
                                        {getCapitalizeName(getUserDisplayName())} don&apos;t mind
                                    </h3> */}
                                    <h3>
                                        {' '}
                                        {isOwnProfile() ? 'I don\'t mind' : getCapitalizeName(getUserDisplayName()) + ' don\'t mind'} 
                                    </h3>
                                </div>
                                <div className={styles.card__content__lists}>
                                    {tabLike !== undefined &&
                                        tabLike
                                            ?.filter((item) => item?.like === 2)
                                            ?.map((item) => (
                                                <Card
                                                    key={item?.activity?.name}
                                                    data={item?.activity}
                                                    isProfile={true}
                                                    updateRatings={userRating}
                                                />
                                            ))}
                                </div>
                            </div>
                        )}

                        {userLocal?.id == userId && (
                            <div className={styles.card__content}>
                                <div className={styles.user__activityHeader}>
                                    <h3>
                                        {isOwnProfile() ? 'I don\'t like' : getCapitalizeName(getUserDisplayName()) + ' don\'t like'}
                                    </h3>
                                </div>
                                <div className={styles.card__content__lists}>
                                    {tabLike !== undefined &&
                                        tabLike
                                            ?.filter((item) => item?.like === 1)
                                            ?.map((item) => (
                                                <Card
                                                    key={item?.activity?.name}
                                                    data={item?.activity}
                                                    isProfile={true}
                                                    updateRatings={userRating}
                                                />
                                            ))}
                                </div>
                            </div>
                        )}
                    </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <div className={mobileOpen && !isLargeScreen ? styles.ContentTab : ''}>
                        <div className={styles.card__header}>
                            <button onClick={() => handleBackkMobile(0)}>
                                <img src={ArrowLeft} alt="" />
                            </button>
                            <div className={styles.card__header__title}>
                                <p>{getUserDisplayName()} Done</p>
                            </div>
                        </div>

                        <div className={styles.card__content}>
                            <div className={styles.user__activityHeader}>
    
                                <h3>
                                    {isOwnProfile() ? 'I am' : getCapitalizeName(getUserDisplayName()) + ' is'} doing it regularly
                                </h3>
                            </div>
                            <div className={styles.card__content__lists}>
                                {tabDone !== undefined &&
                                    tabDone
                                        ?.filter((item) => item?.frequency === 5)
                                        ?.map((item) => (
                                            <Card
                                                key={item?.activity?.name}
                                                data={item?.activity}
                                                isProfile={true}
                                                updateRatings={userRating}
                                            />
                                        ))}
                            </div>
                        </div>
                        <div className={styles.card__content}>
                            <div className={styles.user__activityHeader}>
                                <h3>{isOwnProfile() ? 'I ' : getCapitalizeName(getUserDisplayName())} did many times</h3>
                            </div>
                            <div className={styles.card__content__lists}>
                                {tabDone !== undefined &&
                                    tabDone
                                        ?.filter((item) => item?.frequency === 4)
                                        ?.map((item) => (
                                            <Card
                                                key={item?.activity?.name}
                                                data={item?.activity}
                                                isProfile={true}
                                                updateRatings={userRating}
                                            />
                                        ))}
                            </div>
                        </div>
                        <div className={styles.card__content}>
                            <div className={styles.user__activityHeader}>
                                <h3>
                                    {isOwnProfile() ? 'I' : getCapitalizeName(getUserDisplayName())} did several times
                                </h3>
                            </div>
                            <div className={styles.card__content__lists}>
                                {tabDone !== undefined &&
                                    tabDone
                                        ?.filter((item) => item?.frequency === 3)
                                        ?.map((item) => (
                                            <Card
                                                key={item?.activity?.name}
                                                data={item?.activity}
                                                isProfile={true}
                                                updateRatings={userRating}
                                            />
                                        ))}
                            </div>
                        </div>
                        <div className={styles.card__content}>
                            <div className={styles.user__activityHeader}>
                                <h3>
                                    {isOwnProfile() ? 'I' : getCapitalizeName(getUserDisplayName())} did at least once
                                </h3>
                            </div>
                            <div className={styles.card__content__lists}>
                                {tabDone !== undefined &&
                                    tabDone
                                        ?.filter((item) => item?.frequency === 2)
                                        ?.map((item) => (
                                            <Card
                                                key={item?.activity?.name}
                                                data={item?.activity}
                                                isProfile={true}
                                                updateRatings={userRating}
                                            />
                                        ))}
                            </div>
                        </div>
                        <div className={styles.card__content}>
                            <div className={styles.user__activityHeader}>
                                <h3>{isOwnProfile() ? 'I' : getCapitalizeName(getUserDisplayName())} never did</h3>
                            </div>
                            <div className={styles.card__content__lists}>
                                {tabDone !== undefined &&
                                    tabDone
                                        ?.filter((item) => item?.frequency === 1)
                                        ?.map((item) => (
                                            <Card
                                                key={item?.activity?.name}
                                                data={item?.activity}
                                                isProfile={true}
                                                updateRatings={userRating}
                                            />
                                        ))}
                            </div>
                        </div>
                    </div>
                </CustomTabPanel>
            </div>
            <ProfileEditModal
                open={openProfileEditModal}
                onClose={handleCloseModal}
                userId={userId}
                getUserDetails={getUserDetails}
                userDetails={userDetails}
                profilePict={profilePic}
            />
            <CreateListModal open={openListModal} setOpen={setOpenListModal} />
        </div>
    );
};

export default UserProfile;
