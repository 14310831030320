import React, { useState } from 'react';
import styles from './listItem.module.scss';
import { Button, styled } from '@mui/material';
import listImg from 'assets/images/related1.png';
import EditListItemModal from 'components/EditListItemModal';

const StyledButton = styled(Button)(({ bg, customFontWeight, customFontSize }) => ({
    background: bg,
    textTransform: 'Capitalize',
    color: bg === 'none' ? '#1F62AF' : '#fff',
    borderRadius: '5px',
    width: 'fit-content',
    height: '50px',
    fontSize: customFontSize ?? 20,
    fontWeight: customFontWeight,
    transition: 'all 0.3s',

    '@media (max-width: 476px)': {
        fontSize: '15px !important',
    },
}));

const MyListItem = ({ data }) => {
    const [openEditItemModal, setOpenEditItemModal] = useState(false);
    return (
        <div className={styles.listItem}>
            <div className={styles.listItem__img}>
                <img src={listImg} alt="" />
            </div>
            <div className={styles.listItem__content}>
                <div className={styles.listItem__header}>
                    <h3 className={styles.listItem__title}>{'A capella singing'}</h3>
                    <div className={styles.listItem__actions}>
                        <StyledButton
                            bg={'none'}
                            customFontSize={'20px'}
                            customFontWeight={400}
                            onClick={() => setOpenEditItemModal(true)}
                        >
                            Edit
                        </StyledButton>
                        <StyledButton bg={'none'} customFontSize={'20px'} customFontWeight={400}>
                            Share
                        </StyledButton>
                        <StyledButton bg={'none'} customFontSize={'20px'} customFontWeight={400}>
                            Remove
                        </StyledButton>
                    </div>
                </div>
                {/* {data?.description && ( */}
                <p className={styles.text__primary}>
                    A capella singing is a style of vocal music that is performorded without the...
                </p>
                {/* )} */}
                <p className={styles.text__muted}>
                    2/3 based on 5 votings . Your vote: <span>2/3</span>{' '}
                </p>
            </div>
            <EditListItemModal open={openEditItemModal} setOpen={setOpenEditItemModal} />
        </div>
    );
};

export default MyListItem;
