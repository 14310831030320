import { useEffect, useRef, useState } from 'react';
import styles from './filterSection.module.scss';
import dropdownIcon from '../../assets/images/dropdown.svg';
import closeIcon from '../../assets/images/close.png';
import { Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useClickOutside } from 'hooks/useOutsideClick';
import { allFilters } from '.';

const DropdownFilter = ({
    itemFilter,
    nameFilter,
    filterType,
    setFilterValue,
    type,
    onClear,
    hasParams,
    searchField,
    disabled,
    isClearAll,
    queryFilter,
    defaultLabel,
    defaultValue,
    filterParams,
}) => {
    const location = useLocation();
    const [isShowMenu, setIsShowMenu] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const targetRef = useRef(null);
    const [visibilityPercentage, setVisibilityPercentage] = useState(0);
    const radioRef = useRef(null);

    // const [urlQuery, setUrlQuery] = useState({});

    // useEffect(() => {
    //     const getParams = () => {
    //         const searchParams = new URLSearchParams(location.search);
    //         const urlQuery = {};
    //         searchParams.forEach((value, key) => {
    //             if (allFilters.includes(key)) {
    //                 urlQuery[key] = value;
    //             }
    //         });
    //         return urlQuery;
    //     };

    //     const urlParams = getParams();
    //     console.log(urlParams, ':urlParams');
    //     setUrlQuery(urlParams);
    // }, []);

    const searchParams = new URLSearchParams(location.search);

    const urlQuery = {};
    searchParams.forEach((value, key) => {
        if (allFilters.includes(key)) {
            urlQuery[key] = value;
        }
    });

    const showMenu = () => {
        setIsShowMenu(!isShowMenu);
    };

    console.log(selectedTags, ':tags Selected');

    const handleTagsSelection = (tag) => {
        let newSelectedTags;
        if (selectedTags.includes(tag)) {
            newSelectedTags = selectedTags.filter((selectedTag) => selectedTag !== tag);
        } else {
            newSelectedTags = [...selectedTags, tag];
        }

        if (filterParams && Object.keys(filterParams).includes(filterType)) {
            setSelectedTags([tag]);
            setFilterValue(filterType, [tag]);
        } else {
            setSelectedTags(newSelectedTags);
            setFilterValue(filterType, newSelectedTags);
        }
    };

    const handleTagSelection = (tag) => {
        let newSelectedTag;
        newSelectedTag = [tag];

        setSelectedTag(tag);
        setFilterValue(filterType, newSelectedTag);
    };

    // Fungsi untuk mengupdate nilai input pencarian
    const handleSearchInputChange = (e) => {
        setSearchInput(e.target.value);
    };

    useEffect(() => {
        setSelectedTags([]);
    }, [filterType]);

    useEffect(() => {
        if (hasParams) {
            setSelectedTags([]);
            setSelectedTag('');
        }
    }, [hasParams]);

    const clearTags = () => {
        setSelectedTags([]);
    };

    const clearTag = () => {
        setSelectedTag('');
    };

    // Filter item berdasarkan input pencarian
    const filteredItemFilter = itemFilter.filter((item) => {
        if (item.label !== undefined && typeof item.label === 'string') {
            return item.label.toLowerCase().includes(searchInput.toLowerCase());
        } else if (item.value !== undefined && typeof item.value === 'string') {
            return item.value.toLowerCase().includes(searchInput.toLowerCase());
        }
    });

    const outsideClickRef = useClickOutside(() => setIsShowMenu(false));

    useEffect(() => {
        if (targetRef.current) {
            const elementRect = targetRef.current?.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            // console.log(viewportHeight);

            // console.log(elementRect);
            // console.log(`${nameFilter}`);

            // const elementTop = elementRect?.top;
            const elementBottom = elementRect?.bottom;

            const elementPercentage = (elementBottom / viewportHeight) * 100;

            const clampedPercentage = Math.min(100, Math.max(0, elementPercentage));

            setVisibilityPercentage(clampedPercentage);
        }
    }, []);

    useEffect(() => {
        if (isClearAll) {
            clearTag();
            clearTags();
        }
    }, [isClearAll]);

    const isParamsWithQuery = (itemValue) => {
        const res =
            Object.values(urlQuery).length > 0 && Object.values(urlQuery).includes(itemValue);
        return res;
    };

    useEffect(() => {
        // console.log(Object.values(urlQuery), ':queryValues');
        if (Object.values(urlQuery).length !== 0 && Object.keys(urlQuery).includes(filterType)) {
            Object.keys(urlQuery).forEach((item) => {
                if (item === 'Radius') {
                    handleTagSelection(urlQuery[item]);
                    radioRef.current.value = urlQuery[item];
                } else {
                    handleTagsSelection(urlQuery[item]);
                }
            });
        }
    }, []);

    return (
        <div className={styles.filter__itemWrapper} ref={outsideClickRef}>
            <button
                className={`${styles.filter_dropdown} ${
                    selectedTags.length == 0 && selectedTag === ''
                        ? styles.unSelect
                        : styles.selected
                } `}
                // style={selectedTags.length == 0 ? { background: 'none' } : { background: 'white' }}
                onClick={disabled ? console.log('loading') : showMenu}
                ref={targetRef}
            >
                <p style={{ fontSize: '15px' }}>{nameFilter}</p>
                <div
                    style={
                        selectedTags.length == 0 && selectedTag === ''
                            ? { display: 'flex', alignItems: 'center', gap: '0' }
                            : { display: 'flex', alignItems: 'center', gap: '22px' }
                    }
                >
                    <img src={dropdownIcon} />
                    {selectedTags.length == 0 && selectedTag === '' ? (
                        <p></p>
                    ) : (
                        <p
                            onClick={type == 'radio' ? clearTag : clearTags}
                            style={{ fontWeight: 'bold', height: '100%', width: '13px' }}
                        >
                            <img src={closeIcon} />
                        </p>
                    )}
                </div>
            </button>
            {isShowMenu && (
                <div
                    className={styles.menu_item}
                    style={{
                        bottom: visibilityPercentage < 60 ? 'inset' : `${visibilityPercentage}%`,
                    }}
                >
                    {searchField && (
                        <div className={styles.searchField__container}>
                            <input
                                type="text"
                                placeholder="Search"
                                value={searchInput}
                                onChange={handleSearchInputChange}
                                className={styles.searchField}
                            />
                        </div>
                    )}
                    <div className={styles.filter_item}>
                        <ul style={{ listStyle: 'none' }}>
                            {type === 'radio' ? (
                                <li>
                                    <RadioGroup
                                        value={selectedTag}
                                        onChange={(e) => handleTagSelection(e.target.value)}
                                        ref={radioRef}
                                    >
                                        {itemFilter.map((item) => (
                                            <FormControlLabel
                                                key={item.value}
                                                value={item.value}
                                                control={<Radio color="primary" />}
                                                label={item.label ? item.label : item.value}
                                            />
                                        ))}
                                    </RadioGroup>
                                </li>
                            ) : (
                                <>
                                    {filteredItemFilter.map((item) => (
                                        <li key={item.value}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            selectedTags.includes(item.value) ||
                                                            isParamsWithQuery(item.value)
                                                            // Object.values(urlQuery).includes(
                                                            //     item.value,
                                                            // )
                                                        }
                                                        onChange={() =>
                                                            handleTagsSelection(item.value)
                                                        }
                                                    />
                                                }
                                                label={item.label ? item.label : item.value}
                                            />
                                        </li>
                                    ))}
                                </>
                            )}
                        </ul>
                    </div>
                    {defaultLabel && (
                        <div className={styles.default__filter}>
                            <li>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={() => handleTagsSelection(defaultValue)}
                                            disabled={!defaultValue}
                                        />
                                    }
                                    label={defaultLabel}
                                />
                            </li>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default DropdownFilter;
