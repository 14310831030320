import React from 'react';
import styles from './message.module.scss';
import ProfilePicture from 'components/ProfilePicture';

const Message = ({ owner, message }) => {
    return (
        <div className={`${styles.Message} ${owner && styles.owner}`}>
            <div className={styles.Message__Info}>
                <ProfilePicture
                    userId={message?.uid}
                    customStyle={{ width: '70px', height: '70px', borderRadius: '50%' }}
                />
            </div>
            <div className={styles.Message__Content}>
                <p>{message?.text}</p>
            </div>
        </div>
    );
};

export default Message;
