import routes from 'constants/routes';
import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './card.module.scss';
import homepage1 from 'assets/images/homepage1.png';
import homepage2 from 'assets/images/homepage2.png';
import homepage3 from 'assets/images/homepage3.png';
import homepage4 from 'assets/images/homepage4.png';
import { FormatListBulletedOutlined, StarBorderOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import RateActivityModal from 'components/RateActivityModal';
import AddToList from 'components/AddToListModal';
import actions from 'store/action';
import { StoreContext } from 'store/useStore';
import axios from 'axios';
import CustomButton from 'components/common/Button/CustomButton';
import LikeRatingModal from 'components/LikeRatingModal/LikeRatingModal';
import DoneRatingModal from 'components/DoneRatingModal/DoneRatingModal';
import { useSnackBar } from 'hoc/snackbar';

const BASE_URI = process.env['REACT_APP_BASE_URI'];

const Card = ({ data, activity, isProfile, updateRatings }) => {
    const { state, dispatch } = useContext(StoreContext);
    const [openRateModal, setOpenRateModal] = useState(false);
    const [doneRatingOpen, setDoneRatingOpen] = useState(false);
    const [openListModal, setOpenListModal] = useState(false);
    const [myActivity, setMyActivity] = useState();
    const [counter, setCounter] = useState({
        like_counter: Number(data?.total_like) || Number(data?.total_likes) || 0,
        done_counter: Number(data?.total_frequencie) || Number(data?.total_frequencies) || 0,
    });

    const [isLike, setIsLike] = useState(data?.is_liked);
    const [isDone, setIsDone] = useState(data?.is_done);
    const { snackBarError, snackBarSuccess, snackBarInfo } = useSnackBar();
    const navigate = useNavigate();

    const getActivityNameUrl = () => {
        let activityname = data?.activity !== undefined ? data.activity.name : data?.name;
        let stringifyActivity = activityname?.toString();
        // let lowerCaseActivity = stringifyActivity?.toLowerCase();
        let formattedName = stringifyActivity?.replace(/ /g, '_');
        return formattedName;
    };

    const getImage = () => {
        const imgArr = [homepage1, homepage2, homepage3, homepage4];
        const randomIndex = Math.floor(Math.random() * imgArr.length);
        return imgArr[randomIndex];
    };

    const setLoading = (value, msg) => {
        dispatch({
            type: actions.SET_GLOABL_LOADING,
            payload: {
                loading: value,
                text: msg ? msg : '',
            },
        });
    };

    const logout = async () => {
        dispatch({
            type: actions.SET_USER,
            payload: null,
        });
        localStorage.removeItem('jwt');
        localStorage.removeItem('user');
        // handleClose();
    };

    const getUserActivity = async () => {
        try {
            setLoading(true);
            const jwt = localStorage.getItem('jwt');
            const options = {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            };
            const res = await axios.get(`${BASE_URI}/activities/my_activities`, options);
            const resultData = res.data;
            console.log(resultData);
            const currActivity = resultData.data.find((item) => {
                if (data.id) {
                    return item.activity.id === data.id;
                } else {
                    return item.activity.id === data.activity.id;
                }
            });
            console.log(currActivity, ':curr activty card');
            setMyActivity(currActivity);
            setLoading(false);
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.message[0]?.error == 'Expired Token') {
                logout();
                navigate(routes.LOGIN);
                snackBarError(
                    'Your session has been expired. Please Login again to continue as User or continue as guest.',
                );
            }
            setLoading(false);
        }
    };

    const handleRatingModal = async () => {
        await getUserActivity();
        setOpenRateModal(true);
    };
    const handleDoneRating = async () => {
        await getUserActivity();
        setDoneRatingOpen(true);
    };

    const isRatedLike = () => {
        return data?.is_liked;
    };
    const isRatedDone = () => {
        // const isDone = counter.done_counter > 0;
        return data?.is_done;
    };

    return (
        <div className={styles.card}>
            <Link
                className={styles.card__link}
                to={`/activity/${getActivityNameUrl()}`}
                state={{ data }}
            >
                <div className={styles.card__info}>
                    {data?.activity ? (
                        data?.activity.pexel_images?.length > 0 ? (
                            <img src={data?.activity.pexel_images[0]?.src} alt="" />
                        ) : (
                            <div className={styles.imageBox}></div>
                        )
                    ) : data?.pexel_images?.length > 0 ? (
                        <img src={data?.pexel_images[0]?.src} alt="" />
                    ) : (
                        <div className={styles.imageBox}></div>
                    )}
                    <div className={styles.card__info_content}>
                        <h4>{data?.activity !== undefined ? data.activity.name : data?.name}</h4>
                        <div className={styles.card__info_detail}>
                            <p>{`${counter?.like_counter} Like`}</p>
                            {' - '}
                            <p>{`${counter?.done_counter} Done`}</p>
                        </div>
                    </div>
                </div>
            </Link>
            <div className={styles.card__actions}>
                <CustomButton
                    bg={isLike ? '#DADADA' : '#1F62AF'}
                    customColor={isLike ? '#000' : '#fff'}
                    onClick={handleRatingModal}
                >
                    Like
                </CustomButton>
                <CustomButton
                    bg={isDone ? '#DADADA' : '#1F62AF'}
                    customColor={isDone ? '#000' : '#fff'}
                    onClick={handleDoneRating}
                >
                    Done
                </CustomButton>
                {/* <IconButton onClick={handleRatingModal}>
                    <StarBorderOutlined />
                </IconButton>
                <IconButton onClick={() => setOpenListModal(true)}>
                    <FormatListBulletedOutlined />
                </IconButton> */}
            </div>
            {/* <RateActivityModal
                activity={data}
                open={openRateModal}
                setOpen={setOpenRateModal}
                myActivity={myActivity}
                setMyActivity={setMyActivity}
            /> */}

            {openRateModal && (
                <LikeRatingModal
                    data={data}
                    open={openRateModal}
                    setOpen={setOpenRateModal}
                    myActivity={myActivity}
                    setMyActivity={setMyActivity}
                    setCounter={setCounter}
                    isProfile={isProfile}
                    updateRatings={updateRatings}
                    setIsLike={setIsLike}
                />
            )}
            {doneRatingOpen && (
                <DoneRatingModal
                    data={data}
                    open={doneRatingOpen}
                    setOpen={setDoneRatingOpen}
                    myActivity={myActivity}
                    setMyActivity={setMyActivity}
                    setCounter={setCounter}
                    isProfile={isProfile}
                    updateRatings={updateRatings}
                    setIsDone={setIsDone}
                />
            )}

            <AddToList open={openListModal} setOpen={setOpenListModal} />
        </div>
    );
};

export default Card;
