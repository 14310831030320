import { CustomModal } from 'components/common/Modal';
import styles from './likeModal.module.scss';
import { ratingNumbers } from 'constants/ratingsScale';
// import Like1Icon from 'assets/images/like-1.svg';
// import Like2Icon from 'assets/images/like-2.svg';
// import Like3Icon from 'assets/images/like-3.svg';
// import Like4Icon from 'assets/images/like-4.svg';
// import Like5Icon from 'assets/images/like-5.svg';

import { AiFillDislike } from 'react-icons/ai';
import { GiShrug } from 'react-icons/gi';
import { AiFillLike } from 'react-icons/ai';
import { BsFillHeartFill } from 'react-icons/bs';
import { BsFire } from 'react-icons/bs';

const LikeModal = ({ open, setOpen, onClick }) => {
    const likeContentImg = {
        1: <AiFillDislike />,
        2: <GiShrug />,
        3: <AiFillLike />,
        4: <BsFillHeartFill />,
        5: <BsFire />,
    };

    const onClose = () => setOpen(false);
    return (
        <CustomModal open={open} onClose={onClose} title={'How do you like this activity?'}>
            <div className={styles.actions__grid}>
                {Object.keys(ratingNumbers).map((key, idx) => (
                    <span title={ratingNumbers[key]} onClick={() => onClick(key)} key={key}>
                        {likeContentImg[key]}
                    </span>
                ))}
            </div>
        </CustomModal>
    );
};

export default LikeModal;
