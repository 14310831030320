import React, { useContext, useEffect, useState } from 'react';
import styles from './availableUsers.module.scss';
import AvailableUser from 'components/AvailableUser';
import { onSnapshot, collection } from 'firebase/firestore';
import { db } from 'helper/firebase';
import { StoreContext } from 'store/useStore';
import actions from 'store/action';

const AvailableUsers = ({ receiverData, setReceiverData }) => {
    const [users, setUsers] = useState();
    const { state, dispatch } = useContext(StoreContext);

    useEffect(() => {
        const unsub = onSnapshot(collection(db, 'users'), (snapshot) => {
            let usersData = snapshot.docs.map((doc) => doc.data());
            setUsers(usersData);
            dispatch({
                type: actions.SET_USERS,
                payload: usersData,
            });
        });

        return unsub;
    }, []);

    return (
        <div className={styles.Container}>
            {users &&
                users
                    .filter((item) => item?.userId !== state?.user?.id)
                    .map((user) => (
                        <AvailableUser
                            key={user?.userId}
                            userInfo={user}
                            setReceiverData={setReceiverData}
                            receiverData={receiverData}
                        />
                    ))}
        </div>
    );
};

export default AvailableUsers;
