export const AGE_FILTER = [
    {
        label: '0-5 years',
        value: '0-5',
    },
    {
        label: '6-11 years',
        value: '6-11',
    },
    {
        label: '12-17 years',
        value: '12-17',
    },
    {
        label: '18-24 years',
        value: '18-24',
    },
    {
        label: '25-34 years',
        value: '25-34',
    },
    {
        label: '35-44 years',
        value: '35-44',
    },
    {
        label: '45-54 years',
        value: '45-54',
    },
    {
        label: '55-64 years',
        value: '55-64',
    },
    {
        label: '65-74 years',
        value: '65-74',
    },
    {
        label: '75+ years',
        value: '75-100',
    },
];
