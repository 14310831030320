export const FITNESS_FILTER = [
    {
        label: '1: Stationary',
        value: 1,
    },
    {
        label: '2: Minimal',
        value: 2,
    },
    {
        label: '3: Light',
        value: 3,
    },
    {
        label: '4: Moderate',
        value: 4,
    },
    {
        label: '5: Average',
        value: 5,
    },
    {
        label: '6: Active',
        value: 6,
    },
    {
        label: '7: Sportive',
        value: 7,
    },
    {
        label: '8: Demanding',
        value: 8,
    },
    {
        label: '9: Intense',
        value: 9,
    },
    {
        label: '9: Pro',
        value: 10,
    },
];
