import React, { useContext, useEffect, useState } from 'react';
import styles from './profilePicture.module.scss';
import axios from 'axios';
import { StoreContext } from 'store/useStore';

const BASE_URI = process.env['REACT_APP_BASE_URI'];

const ProfilePicture = ({ userId, customStyle, profilePic }) => {
    const [image, setImage] = useState('');
    const { state } = useContext(StoreContext)

    console.log({
        customStyle,
        userId
    }, ':userFromHeader')


    const getProfilePic = async () => {
        try {
            const res = await axios.get(`${BASE_URI}/user_details/${userId}/profile_picture`, {
                responseType: 'arraybuffer',
            });
            const resultData = new Blob([res.data], { type: 'image/png' });
            // // Create URL from Blob
            var imageUrl = URL.createObjectURL(resultData);
            setImage(imageUrl);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (profilePic) {
            setImage(profilePic);
        } else {
            getProfilePic();
        }
    }, [userId, state.user]);
    return (
        <div style={customStyle} className={styles.Profile__Avatar}>
            {image ? <img src={image} alt="" /> : <div className={styles.blank}></div>}
        </div>
    );
};

export default ProfilePicture;
