import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './activity.module.scss';
import {
    Table,
    TableBody,
    TableContainer,
    TableRow,
    Paper,
    TableHead,
    styled,
    Button,
} from '@mui/material';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useLocation, useParams } from 'react-router';
import ReactPlayer from 'react-player';
import LikeModal from 'components/LikeModal';
import FrequencyModal from 'components/FrequencyModal';
import PeopleLists from 'components/PeopleLists';
import avatar1 from 'assets/images/avatar1.png';
import avatar2 from 'assets/images/avatar2.png';
import avatar3 from 'assets/images/avatar3.png';
import avatar4 from 'assets/images/avatar4.png';
import avatar5 from 'assets/images/avatar5.png';
import placeList from 'assets/images/placeList1.png';
import related1 from 'assets/images/related1.png';
import related2 from 'assets/images/related2.png';
import related3 from 'assets/images/related3.png';
import related4 from 'assets/images/related4.png';
import MapSnapshot from 'components/MapSnapshot';
import { frequencyNumbers, friendsNumber, ratingNumbers } from 'constants/ratingsScale';
import { StoreContext } from 'store/useStore';
import actions from 'store/action';
import axios from 'axios';
import { useSnackBar } from 'hoc/snackbar';
import { boolean } from 'yup';
import CommonBoxWrapper from 'components/common/CommonBoxWrapper';
import activityImg from 'assets/images/activity_img.png';
import SectionTitle from 'components/SectionTitle';
import PeopleIcon from '@mui/icons-material/People';
import WikiLogo from 'assets/images/wikiLogo.png';
import Card from 'components/Card';
import { useNavigate } from 'react-router-dom';
import SocialRatingModal from 'components/SocialRatingModal';
import ActivitySidebarLinks from 'components/ActivitySidebarLinks';
import { Search } from '@mui/icons-material';
import routes from 'constants/routes';
import MobileHeader from 'components/MobileHeader';
import LikeRatingModal from 'components/LikeRatingModal/LikeRatingModal';
import DoneRatingModal from 'components/DoneRatingModal/DoneRatingModal';
import { atom, useAtom } from 'jotai';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import FAVICON from 'assets/icons/favicon.ico';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#d9edf7',
        color: '#000',
        fontFamily: "'Arimo', sans-serif",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: "'Arimo', sans-serif",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const StyledButton = styled(Button)(({ bg, width }) => ({
    background: bg,
    textTransform: 'Capitalize',
    color: bg === 'none' ? '#1F62AF' : '#fff',
    borderRadius: '5px',
    width: width ? width : 'fit-content',
    height: '41px',
    fontSize: 18,
    fontWeight: 700,
    '&:hover': {
        background: bg,
    },
    '@media (max-width: 476px)': {
        fontSize: '15px !important',
    },
}));

const PEXELS_API_KEY = process.env['REACT_APP_PEXELS_API_KEY'];
const BASE_URI = process.env['REACT_APP_BASE_URI'];

const friends = [
    {
        image: avatar1,
        name: 'John Dooney',
        likes: '15',
    },
    {
        image: avatar2,
        name: 'Max Moser',
        likes: '14',
    },
    {
        image: avatar3,
        name: 'John Durster',
        likes: '12',
    },
    {
        image: avatar4,
        name: 'John Durster',
        likes: '13',
    },
    {
        image: avatar5,
        name: 'John Durster',
        likes: '11',
    },
    {
        image: avatar5,
        name: 'John Durster',
        likes: '10',
    },
];

const listNear = [
    {
        placesList: [placeList, placeList, placeList, placeList],
        listName: 'Best A Capella Schools in Berlin',
        rating: '52 items',
    },
    {
        placesList: [placeList, placeList, placeList, placeList],
        listName: 'Top 100 Music Schools',
        rating: '100 items',
    },
    {
        placesList: [placeList, placeList, placeList, placeList],
        listName: 'Activities for Music Lovers',
        rating: '40 items',
    },
    {
        placesList: [placeList, placeList, placeList, placeList],
        listName: 'Things to do for students',
        rating: '120 items',
    },
];

const mainTabMapping = {
    All: 1,
    Likes: 2,
    Done: 3,
    // Lists: 4,
    About: 5,
};

const tabIndexAtom = atom(1);

const Activity = () => {
    const activityParams = useParams();

    const { activityName, activityTab } = activityParams;

    // return main tab name
    const getMainTab = () => {
        const tabNameCapitalCase = activityTab
            ? activityTab.charAt(0).toUpperCase() + activityTab.slice(1)
            : 'All';

        return mainTabMapping[tabNameCapitalCase];
    };

    const formattedActivityName = activityName.replace(/_/g, ' ');
    const [activityId, setActivityId] = useState('');
    const [data, setData] = useState();
    const { state, dispatch } = useContext(StoreContext);
    const [openLikeModal, setOpenLikeModal] = useState(false);
    const [openFreqModal, setOpenFreqModal] = useState(false);
    const [openSocialModal, setOpenSocialModal] = useState(false);
    const [pexelsImages, setPexelsImages] = useState(null);
    const [myActivity, setMyActivity] = useState();
    const { snackBarError, snackBarSuccess, snackBarInfo } = useSnackBar();
    const [counter, setCounter] = useState({
        like_counter: 0,
        done_counter: 0,
    });

    const [likeData, setLikeData] = useState(null);

    const [frequencyData, setFrequencyData] = useState(null);

    const [activityMainTab, setActivityMainTab] = useState(mainTabMapping.All);
    const [tabIndex, setSelectedTab] = useAtom(tabIndexAtom);

    const navigate = useNavigate();

    const combinedFields = {};

    const likeMinded = [
        {
            image: avatar3,
            name: 'John Durster',
            likes: '12',
        },
        {
            image: avatar4,
            name: 'John Durster',
            likes: '13',
        },
        {
            image: avatar5,
            name: 'John Durster',
            likes: '11',
        },
        {
            image: avatar5,
            name: 'John Durster',
            likes: '10',
        },
    ];

    for (const key in data) {
        if (key.includes('min') || key.includes('max')) {
            const combinedKey = key.split('_')[0]; // get the key prefix , eg: age
            if (!combinedFields[combinedKey]) {
                combinedFields[combinedKey] = data[key]; // set initial value
            } else {
                combinedFields[combinedKey] = data[key] + '-' + combinedFields[combinedKey];
            }
        } else {
            combinedFields[key] = data[key];
        }
    }

    const setLoading = (value, msg) => {
        dispatch({
            type: actions.SET_GLOABL_LOADING,
            payload: {
                loading: value,
                text: msg ? msg : '',
            },
        });
    };

    const getActivityData = async (getId = false) => {
        try {
            setLoading(true, 'Getting Activity Details');
            const res = await axios.get(`${BASE_URI}/activities/${formattedActivityName}`);
            const resData = res.data;
            setData(resData.data);
            setActivityId(resData.data.id);
            setLoading(false);
            if (getId) return resData.data.id;
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const getActivityRating = async (id) => {
        try {
            setLoading(true);
            const jwt = localStorage.getItem('jwt');
            const options = {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            };
            const res = await axios.get(`${BASE_URI}/activities/my_activities`, options);
            const resultData = res.data;

            const currActivity = resultData.data.find((item) => item.activity.id == id);
            setMyActivity(currActivity);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const getStats = async (id) => {
        try {
            setLoading(true);
            const likesData = await axios.get(`${BASE_URI}/activities/${id}/like`);
            setLikeData(likesData.data.data);

            const frequencyData = await axios.get(`${BASE_URI}/activities/${id}/frequency`);

            setFrequencyData(frequencyData.data.data);
            setCounter((prev) => {
                return {
                    like_counter: Number(likesData.data.data?.likes?.length) || 0,
                    done_counter: Number(frequencyData.data.data?.frequencies?.length) || 0,
                };
            });
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handleLike = async (rating) => {
        try {
            if (!state.user) throw 'Please login first';
            setLoading(true, 'Rating activity...');
            const activityId = data.id;
            const payload = {
                like: rating,
            };
            const jwt = localStorage.getItem('jwt');
            const options = {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': 'application/json',
                },
            };
            const res = await axios.post(
                `${BASE_URI}/activities/${activityId}/like`,
                payload,
                options,
            );
            if (!(res.status >= 200 && res.status < 400)) return res.statusText;
            const resData = res.data;
            await getActivityRating(activityId);
            snackBarSuccess(`Successfully rated ${resData.data.activity.name}`);
            await getStats(activityId);

            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            snackBarError(`Error: ${JSON.stringify(error?.message)}`);

            if (error?.response?.data?.message[0]?.error == 'Expired Token') {
                navigate(routes.LOGIN);
                snackBarError('Your session has been expired. Please Login again to continue.');
            } else {
                snackBarError(error.toString());
            }
        }
    };

    const handleFrequency = async (rating) => {
        try {
            if (!state.user) throw 'Please login first';
            setLoading(true, 'Rating activity...');
            const activityId = data.id;
            const payload = {
                frequency: rating,
            };
            const jwt = localStorage.getItem('jwt');
            const options = {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': 'application/json',
                },
            };
            const res = await axios.post(
                `${BASE_URI}/activities/${activityId}/frequency`,
                payload,
                options,
            );
            if (!(res.status >= 200 && res.status < 400)) return res.statusText;
            const resData = res.data;
            await getActivityRating(activityId);
            snackBarSuccess(`Successfully rated ${resData.data.activity.name}`);
            await getStats(activityId);

            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            // snackBarError(`Error: ${JSON.stringify(error)}`);
            if (error?.response?.data?.message[0]?.error == 'Expired Token') {
                navigate(routes.LOGIN);
                snackBarError('Your session has been expired. Please Login again to continue.');
            } else {
                snackBarError(error.toString());
            }
        }
    };

    const handleSocial = async (rating) => {
        try {
            if (!state.user) throw 'Please login first';
            setLoading(true, 'Rating activity...');
            const activityId = data.id;
            const payload = {
                social: rating,
            };
            const jwt = localStorage.getItem('jwt');
            const options = {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': 'application/json',
                },
            };
            const res = await axios.post(
                `${BASE_URI}/activities/${activityId}/social`,
                payload,
                options,
            );
            if (!(res.status >= 200 && res.status < 400)) return res.statusText;
            const resData = res.data;
            await getActivityRating(activityId);
            snackBarSuccess(`Successfully rated ${resData.data.activity.name}`);
            await getStats(activityId);

            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            // snackBarError(`Error: ${JSON.stringify(error)}`);
            if (error?.response?.data?.message[0]?.error == 'Expired Token') {
                navigate(routes.LOGIN);
                snackBarError('Your session has been expired. Please Login again to continue.');
            } else {
                snackBarError(error.toString());
            }
        }
    };

    const getActivityPageData = async () => {
        const getId = true;
        const id = await getActivityData(getId);
        await getActivityRating(id);
        await getStats(id);
    };

    const getLinksItems = (itemsArray, linkPrefix) => {
        const arrayLinks = itemsArray?.map((item) => {
            const searchText = encodeURIComponent(item?.name?.replace(/\s+/g, '+'));
            const link = `${linkPrefix}${searchText}`;
            return { text: item.name.charAt(0).toUpperCase() + item.name.slice(1), link: link };
        });

        return arrayLinks;
    };

    // useEffect(() => {
    //     getPexelsImage();
    // }, [])

    useEffect(() => {
        getActivityPageData();
        if (activityTab) {
            const tabName = getMainTab();
            setActivityMainTab(tabName);
        } else {
            setActivityMainTab(mainTabMapping.All);
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [activityTab, activityName, counter?.like_counter, counter?.done_counter]);

    const isLikedByMe = () => {
        const isLiked = myActivity?.activity?.id == activityId && myActivity?.like !== null;
        return isLiked;
    };

    const isDoneByMe = () => {
        const isDone = myActivity?.activity?.id == activityId && myActivity?.frequency !== null;
        return isDone;
    };

    if (!data) {
        if (state.globalLoading) {
            return <></>;
        } else {
            return <p>Activity not found</p>;
        }
    }

    return (
        <div className="main-container">
            <Helmet>
                <title>{combinedFields?.name}</title>
                <link rel="icon" href={FAVICON} />
                <meta name="title" content={combinedFields?.name} />
                <meta name="description" content={combinedFields?.description} />
                <meta property="og:type" content="website"></meta>
                <meta property="og:url" content={`${window.location.href}`}></meta>
                <meta property="og:title" content={combinedFields?.name}></meta>
                <meta property="og:description" content={combinedFields?.description} />
                <meta property="og:image" content={combinedFields?.pexel_images[0]?.src}></meta>
                <meta property="twitter:card" content="summary_large_image"></meta>
                <meta property="twitter:url" content={window.location.href}></meta>
                <meta property="twitter:title" content={combinedFields?.name}></meta>
                <meta property="twitter:description" content={combinedFields?.description} />
                <meta
                    property="twitter:image"
                    content={combinedFields?.pexel_images[0]?.src}
                ></meta>
            </Helmet>
            <MobileHeader
                isHide={tabIndex > 1}
                setTab={setSelectedTab}
                title={combinedFields?.name}
            />
            <div className={styles.activity__container}>
                <CommonBoxWrapper
                    classname={activityMainTab !== mainTabMapping.All ? styles.hideOnMobile : ''}
                >
                    <div className={styles.activity__info}>
                        <div className={styles.activity__infoWrapper}>
                            <div className={styles.activity__avatar}>
                                <img src={combinedFields?.pexel_images[0]?.src} alt="" />
                            </div>
                            <div className={styles.activity__statsMb}>
                                <ul>
                                    <li>
                                        <span>{counter?.like_counter}</span>{' '}
                                        <br className={styles.break__mobile} />
                                        Likes
                                    </li>
                                    <li>
                                        <span>{counter?.done_counter}</span>{' '}
                                        <br className={styles.break__mobile} />
                                        Done
                                    </li>
                                    {/* <li>
                                            <span>0</span> <br className={styles.break__mobile} />
                                            Lists
                                        </li> */}
                                </ul>
                            </div>
                            <div className={styles.activity__infoContent}>
                                <SectionTitle
                                    styles={{ fontSize: '30px' }}
                                    text={combinedFields?.name}
                                />
                                <div className={styles.activity__stats}>
                                    <ul>
                                        <li>
                                            <span>{counter?.like_counter}</span>{' '}
                                            <br className={styles.break__mobile} />
                                            Likes
                                        </li>
                                        <li>
                                            <span>{counter?.done_counter}</span>{' '}
                                            <br className={styles.break__mobile} />
                                            Done
                                        </li>
                                        {/* <li>
                                            <span>0</span> <br className={styles.break__mobile} />
                                            Lists
                                        </li> */}
                                    </ul>
                                </div>
                                {/* <div className={styles.activity__infoPrimaryButtons}>
                                    <StyledButton bg={'none'}>Edit</StyledButton>
                                    <StyledButton bg={'none'}>Share</StyledButton>
                                    <StyledButton bg={'none'}>Add to list</StyledButton>
                                </div> */}
                            </div>
                        </div>
                        <div className={styles.titleMb}>
                            <SectionTitle
                                styles={{ fontSize: '28px' }}
                                text={combinedFields?.name}
                            />
                        </div>
                        {/* <div> */}
                        <div className={styles.activity__ratingButtons}>
                            <StyledButton
                                onClick={() => setOpenLikeModal(true)}
                                bg={isLikedByMe() ? '#DADADA' : '#1F62AF'}
                                sx={{
                                    color: isLikedByMe() ? '#000' : '#FFF',
                                }}
                            >
                                {isLikedByMe() ? 'Like it?' : 'Do you like it?'}
                            </StyledButton>
                            <StyledButton
                                onClick={() => setOpenFreqModal(true)}
                                bg={isDoneByMe() ? '#DADADA' : '#1F62AF'}
                                sx={{
                                    color: isDoneByMe() ? '#000' : '#FFF',
                                }}
                            >
                                {isDoneByMe() ? 'Done it?' : 'Have you done it?'}
                            </StyledButton>
                            {/* <StyledButton bg={'#DADADA'} onClick={() => setOpenSocialModal(true)}>
                                <PeopleIcon sx={{ color: '#000' }} />
                            </StyledButton> */}
                        </div>
                        {/* </div> */}
                    </div>
                    <div className={styles.tabButton__wrapper}>
                        <div className={styles.activity__tabsButtons}>
                            {Object.keys(mainTabMapping).map((key) => (
                                <div
                                    key={key}
                                    className={[
                                        styles.tabButton,
                                        activityMainTab === mainTabMapping[key]
                                            ? styles.active
                                            : '',
                                    ].join(' ')}
                                    onClick={() => {
                                        setActivityMainTab(mainTabMapping[key]);
                                        navigate(`/activity/${activityName}/${key.toLowerCase()}`);
                                    }}
                                >
                                    {key}
                                </div>
                            ))}
                        </div>
                        <div></div>
                    </div>
                </CommonBoxWrapper>
                <div
                    className={[
                        styles.activities__grid,
                        activityMainTab !== mainTabMapping.All &&
                        activityMainTab !== mainTabMapping.About
                            ? styles.grid1
                            : '',
                    ].join(' ')}
                >
                    {activityMainTab === mainTabMapping.All && (
                        <>
                            <div className={styles.aSide}>
                                {likeData && likeData?.likes?.length > 0 && (
                                    <div className={styles.aSide__Item}>
                                        <Likes
                                            likedPeople={likeData}
                                            activityMainTab={activityMainTab}
                                            setActivityMainTab={setActivityMainTab}
                                        />
                                    </div>
                                )}
                                {frequencyData && frequencyData?.frequencies?.length > 0 && (
                                    <div className={styles.aSide__Item}>
                                        <Done
                                            likedPeople={frequencyData}
                                            activityMainTab={activityMainTab}
                                            setActivityMainTab={setActivityMainTab}
                                        />
                                    </div>
                                )}
                                {/* <div className={styles.aSide__Item}>
                        <Done />
                    </div>
                    <div className={styles.aSide__Item}>
                        <Lists />
                    </div> */}
                                <div className={styles.aSide__Item}>
                                    <About
                                        combinedFields={combinedFields}
                                        activityMainTab={activityMainTab}
                                        setActivityMainTab={setActivityMainTab}
                                    />
                                </div>
                            </div>
                            <div className={styles.bSide}>
                                {combinedFields?.video && (
                                    <CommonBoxWrapper>
                                        <div className={styles.right__content}>
                                            <div className={styles.video}>
                                                <ReactPlayer
                                                    url={combinedFields?.video}
                                                    width={'100%'}
                                                    height={'255px'}
                                                    controls
                                                    style={{
                                                        borderRadius: '10px',
                                                        overflow: 'hidden',
                                                    }}
                                                />
                                            </div>
                                            {/* <h5 className={styles.links__header}>Video</h5> */}
                                            {/* <div className={styles.activity__Links}>
                                                <div className={styles.activity__LinksItem}>
                                                    <p>{combinedFields?.name} for beginner</p>
                                                    <a
                                                        href={`https://www.youtube.com/results?search_query=${combinedFields?.name}+for+beginner`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Youtube
                                                    </a>
                                                </div>
                                                <div className={styles.activity__LinksItem}>
                                                    <p>{combinedFields?.name} best of</p>
                                                    <a
                                                        href={`https://www.youtube.com/results?search_query=${combinedFields?.name}+best+of`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Youtube
                                                    </a>
                                                </div>
                                                <div className={styles.activity__LinksItem}>
                                                    <p>
                                                        {combinedFields?.name} how to improve your
                                                        skills
                                                    </p>
                                                    <a
                                                        href={`https://www.youtube.com/results?search_query=${combinedFields?.name}+how+to+improve+your+skills`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Youtube
                                                    </a>
                                                </div>
                                            </div> */}
                                            <ActivitySidebarLinks
                                                title={'Youtube'}
                                                linkItems={[
                                                    {
                                                        text: `${combinedFields?.name} for beginner`,
                                                        link: `https://www.youtube.com/results?search_query=${combinedFields?.name}+for+beginner`,
                                                    },
                                                    {
                                                        text: `${combinedFields?.name} best of`,
                                                        link: `https://www.youtube.com/results?search_query=${combinedFields?.name}+best+of`,
                                                    },
                                                    {
                                                        text: `${combinedFields?.name} how to improve your skills`,
                                                        link: `https://www.youtube.com/results?search_query=${combinedFields?.name}+how+to+improve+your+skills`,
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </CommonBoxWrapper>
                                )}
                                {/* {combinedFields?.equipments?.length > 0 && (
                                    <CommonBoxWrapper style={{ marginTop: '25px' }}>
                                        <h5 className={styles.links__header}>Equipments</h5>
                                        <div className={styles.activity__Links}>
                                            {combinedFields?.equipments?.map((item, idx) => (
                                                <div
                                                    key={idx}
                                                    className={styles.activity__LinksItem}
                                                >
                                                    <p>
                                                        {item?.name?.charAt(0).toUpperCase() +
                                                            item?.name?.slice(1)}
                                                    </p>
                                                    <div className={styles.double__links}>
                                                        <a
                                                            href={`https://www.google.com/search?tbm=shop&q=${item?.name}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            Google
                                                        </a>
                                                        <a
                                                            href={`https://www.amazon.com/s?k=${item?.name}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            Amazon
                                                        </a>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </CommonBoxWrapper>
                                )} */}

                                <CommonBoxWrapper style={{ marginTop: '25px' }}>
                                    <ActivitySidebarLinks
                                        title={'Wikipedia'}
                                        linkItems={[
                                            {
                                                text: combinedFields?.name,
                                                link: `https://en.wikipedia.org/w/index.php?search=${combinedFields?.name}`,
                                            },
                                        ]}
                                    />
                                </CommonBoxWrapper>

                                {combinedFields?.places?.length > 0 && (
                                    <CommonBoxWrapper style={{ marginTop: '25px' }}>
                                        <ActivitySidebarLinks
                                            title={'Google Maps'}
                                            linkItems={getLinksItems(
                                                combinedFields?.places,
                                                'https://www.google.com/maps/search/?q=',
                                            )}
                                        />
                                    </CommonBoxWrapper>
                                )}
                                {combinedFields?.equipments?.length > 0 && (
                                    <CommonBoxWrapper style={{ marginTop: '25px' }}>
                                        <ActivitySidebarLinks
                                            title={'Google Shopping'}
                                            linkItems={getLinksItems(
                                                combinedFields?.equipments,
                                                'https://www.google.com/search?tbm=shop&q=',
                                            )}
                                        />
                                    </CommonBoxWrapper>
                                )}
                            </div>
                        </>
                    )}

                    {activityMainTab === mainTabMapping.Likes &&
                        likeData &&
                        likeData?.likes?.length > 0 && (
                            <div className={styles.aSide__Item}>
                                <Likes
                                    likedPeople={likeData}
                                    setLikeData={setLikeData}
                                    activityMainTab={activityMainTab}
                                    setActivityMainTab={setActivityMainTab}
                                />
                            </div>
                        )}

                    {activityMainTab === mainTabMapping.Done &&
                        frequencyData &&
                        frequencyData?.frequencies?.length > 0 && (
                            <div className={styles.aSide__Item}>
                                <Done
                                    likedPeople={frequencyData}
                                    activityMainTab={activityMainTab}
                                    setActivityMainTab={setActivityMainTab}
                                />
                            </div>
                        )}

                    {activityMainTab === mainTabMapping.About && (
                        <div className={styles.aSide__Item}>
                            <About
                                combinedFields={combinedFields}
                                activityMainTab={activityMainTab}
                                setActivityMainTab={setActivityMainTab}
                            />
                        </div>
                    )}
                    {activityMainTab === mainTabMapping.About && (
                        <div className={styles.bSide__Item}>
                            {combinedFields?.video && (
                                <CommonBoxWrapper>
                                    <div className={styles.right__content}>
                                        <div className={styles.video}>
                                            <ReactPlayer
                                                url={combinedFields?.video}
                                                width={'100%'}
                                                height={'255px'}
                                                controls
                                                style={{
                                                    borderRadius: '10px',
                                                    overflow: 'hidden',
                                                }}
                                            />
                                        </div>
                                        <h5 className={styles.links__header}>Video</h5>
                                        <div className={styles.activity__Links}>
                                            {/* <div className={styles.activity__LinksItem}>
                                                <p>{combinedFields?.name} for beginner</p>
                                                <a
                                                    href={`https://www.youtube.com/results?search_query=${combinedFields?.name}+for+beginner`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Youtube
                                                </a>
                                            </div>
                                            <div className={styles.activity__LinksItem}>
                                                <p>{combinedFields?.name} best of</p>
                                                <a
                                                    href={`https://www.youtube.com/results?search_query=${combinedFields?.name}+best+of`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Youtube
                                                </a>
                                            </div>
                                            <div className={styles.activity__LinksItem}>
                                                <p>
                                                    {combinedFields?.name} how to improve your
                                                    skills
                                                </p>
                                                <a
                                                    href={`https://www.youtube.com/results?search_query=${combinedFields?.name}+how+to+improve+your+skills`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Youtube
                                                </a>
                                            </div> */}
                                            <div className={styles.activity__Link}>
                                                <img src={WikiLogo} alt="Wikipedia" />
                                                <a
                                                    href={`https://en.wikipedia.org/w/index.php?search=${combinedFields?.name}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {combinedFields?.name}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </CommonBoxWrapper>
                            )}
                        </div>
                    )}
                </div>
                <div className={styles.similar__activitiesContainer}>
                    {combinedFields?.similar_activities?.length > 0 && (
                        <CommonBoxWrapper>
                            <h5 className={styles.tab__contentHeader}>Similar activities</h5>
                            <div className={styles.similar__activitiesGrid}>
                                {combinedFields?.similar_activities?.map((item, idx) => (
                                    <Card key={item?.id} data={item} />
                                    // <div key={idx}>asd</div>
                                ))}
                            </div>
                        </CommonBoxWrapper>
                    )}
                </div>
            </div>

            {openLikeModal && (
                <LikeRatingModal
                    data={data}
                    open={openLikeModal}
                    setOpen={setOpenLikeModal}
                    myActivity={myActivity}
                    setMyActivity={setMyActivity}
                    setCounter={setCounter}
                />
            )}

            {openFreqModal && (
                <DoneRatingModal
                    data={data}
                    open={openFreqModal}
                    setOpen={setOpenFreqModal}
                    myActivity={myActivity}
                    setMyActivity={setMyActivity}
                    setCounter={setCounter}
                />
            )}
        </div>
    );
};

const Likes = ({ likedPeople, setLikeData, activityMainTab, setActivityMainTab }) => {
    const [searchInput, setSearchInput] = useState('');
    const [likedUsers, setLikedUsers] = useState(likedPeople?.likes);

    const activityParams = useParams();

    const { activityName, activityTab } = activityParams;
    const navigate = useNavigate();

    const likeSubTab = {
        All: 1,
        Friends: 2,
        'Like-Minded': 3,
        Nearby: 4,
    };

    const [selectedSubTab, setSelectedSubTab] = useState(likeSubTab.All);

    const handleChange = (e) => {
        const val = e.target.value;
        setSearchInput(e.target.value);
        const filtered = likedPeople?.likes?.filter((like) =>
            like.user.username.toLowerCase().includes(val.toLowerCase()),
        );

        setLikedUsers(filtered);
    };

    return (
        <CommonBoxWrapper>
            {/* <div className={styles.Mobile__TabGroup}>
                {Object.keys(likeSubTab)
                    .filter((item) => item !== 'All')
                    .map((item, idx) => (
                        <div
                            key={idx}
                            className={[
                                styles.Mobile__Tabs,
                                selectedSubTab === likeSubTab[item] ? styles.active : '',
                            ].join(' ')}
                            onClick={() => setSelectedSubTab(likeSubTab[item])}
                        >
                            {item}
                        </div>
                    ))}
            </div> */}
            <div className={styles.tabContent__header}>
                <SectionTitle text={'Likes'} classname={styles.mbHide} />
                {activityMainTab !== mainTabMapping.All && (
                    <div className={styles.search__form}>
                        <Search className={styles.search__icon} />
                        <input
                            type="text"
                            name="search"
                            id="search"
                            value={searchInput}
                            onChange={handleChange}
                        />
                    </div>
                )}
            </div>
            <h4 className={styles.MbTitle}>{likedUsers?.length} Likes</h4>
            {/* {activityMainTab !== mainTabMapping.All && (
                <div className={styles.Likes__TabGroup}>
                    {Object.keys(likeSubTab).map((item, idx) => (
                        <div
                            key={idx}
                            className={[
                                styles.Likes__Tabs,
                                selectedSubTab === likeSubTab[item] ? styles.active : '',
                            ].join(' ')}
                            onClick={() => setSelectedSubTab(likeSubTab[item])}
                        >
                            {item}
                        </div>
                    ))}
                </div>
            )} */}
            <div className={styles.activity__friends}>
                <PeopleLists itemList={likedUsers} />
                {searchInput.length > 0 && likedUsers?.length === 0 && <p>No match found</p>}
            </div>
            {activityMainTab === mainTabMapping.All && (
                <div className={styles.more__btn}>
                    <StyledButton
                        onClick={() => {
                            setActivityMainTab(mainTabMapping.Likes);
                            navigate(`/activity/${activityName}/${'Likes'.toLowerCase()}`);
                        }}
                        sx={{ color: '#000' }}
                        width={'100%'}
                        bg={'#DADADA'}
                    >
                        See all
                    </StyledButton>
                </div>
            )}
        </CommonBoxWrapper>
    );
};

const Done = ({ likedPeople, activityMainTab, setActivityMainTab }) => {
    const [searchInput, setSearchInput] = useState('');
    const [likedUsers, setLikedUsers] = useState(likedPeople?.frequencies);

    const likeSubTab = {
        All: 1,
        Friends: 2,
        'Like-Minded': 3,
        Nearby: 4,
    };
    const activityParams = useParams();

    const { activityName, activityTab } = activityParams;

    const navigate = useNavigate();

    const [selectedSubTab, setSelectedSubTab] = useState(likeSubTab.All);

    const handleChange = (e) => {
        const val = e.target.value;
        setSearchInput(e.target.value);
        const filtered = likedPeople?.frequencies?.filter((like) =>
            like.user.username.toLowerCase().includes(val.toLowerCase()),
        );
        setLikedUsers(filtered);
    };
    return (
        <CommonBoxWrapper>
            {/* <div className={styles.Mobile__TabGroup}>
                {Object.keys(likeSubTab)
                    .filter((item) => item !== 'All')
                    .map((item, idx) => (
                        <div
                            key={idx}
                            className={[
                                styles.Mobile__Tabs,
                                selectedSubTab === likeSubTab[item] ? styles.active : '',
                            ].join(' ')}
                            onClick={() => setSelectedSubTab(likeSubTab[item])}
                        >
                            {item}
                        </div>
                    ))}
            </div> */}
            <div className={styles.tabContent__header}>
                <SectionTitle text={'Done'} classname={styles.mbHide} />
                {activityMainTab !== mainTabMapping.All && (
                    <div className={styles.search__form}>
                        <Search className={styles.search__icon} />
                        <input
                            type="text"
                            name="search"
                            id="search"
                            value={searchInput}
                            onChange={handleChange}
                        />
                    </div>
                )}
            </div>
            <h4 className={styles.MbTitle}>{likedUsers?.length} Done</h4>
            {/* {activityMainTab !== mainTabMapping.All && (
                <div className={styles.Likes__TabGroup}>
                    {Object.keys(likeSubTab).map((item, idx) => (
                        <div
                            key={idx}
                            className={[
                                styles.Likes__Tabs,
                                selectedSubTab === likeSubTab[item] ? styles.active : '',
                            ].join(' ')}
                            onClick={() => setSelectedSubTab(likeSubTab[item])}
                        >
                            {item}
                        </div>
                    ))}
                </div>
            )} */}
            <div className={styles.activity__friends}>
                <PeopleLists itemList={likedUsers} />
                {searchInput.length > 0 && likedUsers?.length === 0 && <p>No match found</p>}
            </div>
            {activityMainTab === mainTabMapping.All && (
                <div className={styles.more__btn}>
                    <StyledButton
                        onClick={() => {
                            setActivityMainTab(mainTabMapping.Done);
                            navigate(`/activity/${activityName}/${'Done'.toLowerCase()}`);
                        }}
                        sx={{ color: '#000' }}
                        width={'100%'}
                        bg={'#DADADA'}
                    >
                        See all
                    </StyledButton>
                </div>
            )}
        </CommonBoxWrapper>
    );
};

const Lists = () => {
    return (
        <CommonBoxWrapper>
            <SectionTitle text={'Lists'} />
            <div className={styles.Likes__TabGroup}>
                <div className={[styles.Likes__Tabs, styles.active].join(' ')}>My lists</div>
                <div className={styles.Likes__Tabs}>Nearby</div>
                <div className={styles.Likes__Tabs}>Popular</div>
            </div>
            <div className={styles.activity__friends}>
                <PeopleLists itemList={listNear} myList={true} />
            </div>
            <div className={styles.more__btn}>
                <StyledButton sx={{ color: '#000' }} width={'100%'} bg={'#DADADA'}>
                    See all
                </StyledButton>
            </div>
        </CommonBoxWrapper>
    );
};

const About = ({ activityMainTab, combinedFields, setActivityMainTab }) => {
    const activityParams = useParams();

    const { activityName, activityTab } = activityParams;
    const formattedActivityName = activityName.replace(/_/g, ' ');
    const x = [
        {
            title: 'Description',
            index: 1,
            description: '',
        },
        {
            title: 'How To',
            index: 2,
            description: `How to do ${formattedActivityName}`,
        },
        {
            title: 'Places',
            index: 3,
            description: `Where to do ${formattedActivityName}`,
        },
        {
            title: 'Equipments',
            index: 4,
            description: `What is needed for ${formattedActivityName}`,
        },
        {
            title: 'Services',
            index: 5,
            description: `Who can help with ${formattedActivityName}`,
        },
        {
            title: 'Requirements',
            index: 6,
            description: '',
        },
    ];
    const [aboutTabs, setAboutTabs] = useState(x);
    const [copyArr, setCopyArr] = useState([]);

    // const copyArr = aboutTabs;
    // const firstElement = copyArr.shift();
    // copyArr.push(firstElement);

    const navigate = useNavigate();
    // const [selectedTab, setSelectedTab] = useState(1);
    const [selectedTab, setSelectedTab] = useAtom(tabIndexAtom);

    const descriptionRef = useRef();
    const howToRef = useRef();
    const equipmentsRef = useRef();
    const requirementsRef = useRef();
    const placesRef = useRef();
    const servicesRef = useRef();

    // const howToText = combinedFields?.how_to;

    const renderFormattedText = (rawText) => {
        if (!rawText) return '';
        const regex = /\*\*(.*?)\*\*([\s\S]*?)(?=\*\*|$)/g;
        const matches = [];
        let match;

        while ((match = regex.exec(rawText)) !== null) {
            const title = match[1];
            const text = match[2].trim();
            matches.push({ title, text });
        }

        return (
            <div className={styles.howToText}>
                {matches.map((item, idx) => (
                    <div key={idx} className={styles.howToText__content}>
                        <h4>{item?.title}:</h4>
                        <p>{item?.text}</p>
                    </div>
                ))}
            </div>
        );
    };

    const getValue = (val) => {
        const isArray = Array.isArray(val);
        if (isArray) {
            return (
                <>
                    {val?.map((item, idx) => (
                        <span key={idx}>{item.name}, </span>
                    ))}
                </>
            );
        } else if (typeof val === boolean) {
            return val.toString();
        } else {
            return val;
        }
        // let formatVal = Array.isArray(val) ? val?.join(', ') : val;
    };

    function getFormattedKey(str) {
        return str
            .toLowerCase()
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    // useEffect(() => {
    //     if (selectedTab === 2) {
    //         howToRef.current.scrollIntoView({
    //             behavior: 'smooth',
    //             top: 0,
    //         });
    //     }
    //     if (selectedTab === 3) {
    //         equipmentsRef.current.scrollIntoView({
    //             behavior: 'smooth',
    //             top: 0,
    //         });
    //     }
    //     if (selectedTab === 4) {
    //         requirementsRef.current.scrollIntoView({
    //             behavior: 'smooth',
    //             top: 0,
    //         });
    //     }
    //     if (selectedTab === 5) {
    //         placesRef.current.scrollIntoView({
    //             behavior: 'smooth',
    //             top: 0,
    //         });
    //     }
    //     if (selectedTab === 6) {
    //         servicesRef.current.scrollIntoView({
    //             behavior: 'smooth',
    //             top: 0,
    //         });
    //     }
    // }, [selectedTab]);

    useEffect(() => {
        setCopyArr([...aboutTabs.slice(1), aboutTabs[0]]);
    }, []);

    return (
        <CommonBoxWrapper>
            <SectionTitle
                text={'About'}
                classname={activityMainTab !== mainTabMapping.All && styles.mbHide}
            />
            {activityMainTab === mainTabMapping.About ? (
                <>
                    {/* <div className={styles.Mobile__AboutTabGroup}>
                        {copyArr.map((item) => (
                            <div
                                onClick={() => setSelectedTab(item.index)}
                                key={item.index}
                                className={[
                                    styles.Mobile__AboutTabs,
                                    selectedTab === item.index ? styles.active : '',
                                ].join(' ')}
                            >
                                <h4>{item.title}</h4>
                                <p>{item.description}</p>
                            </div>
                        ))}
                    </div> */}
                    <div className={[styles.Likes__TabGroup, styles.About__TabGroup].join(' ')}>
                        {aboutTabs.map((item) => (
                            <div
                                onClick={() => setSelectedTab(item.index)}
                                key={item.index}
                                className={[
                                    styles.Likes__Tabs,
                                    selectedTab === item.index ? styles.active : '',
                                ].join(' ')}
                            >
                                {item.title}
                            </div>
                        ))}
                    </div>
                    {selectedTab === 1 && (
                        <>
                            <div className={styles.Mobile__AboutTabGroup}>
                                {copyArr.map((item) => (
                                    <div
                                        onClick={() => {
                                            setSelectedTab(item.index);
                                        }}
                                        key={item.index}
                                        className={[
                                            styles.Mobile__AboutTabs,
                                            selectedTab === item.index ? styles.active : '',
                                        ].join(' ')}
                                    >
                                        <h4>{item.title}</h4>
                                        <p>{item.description}</p>
                                    </div>
                                ))}
                            </div>
                            <div ref={descriptionRef} className={styles.activity__description}>
                                <h5
                                    className={[styles.tab__contentHeader, styles.mbHide].join(' ')}
                                >
                                    Description
                                </h5>
                                <p>{combinedFields?.description}</p>
                                <div className={styles.activity__Link}>
                                    <img src={WikiLogo} alt="Wikipedia" />
                                    <a
                                        href={`https://en.wikipedia.org/w/index.php?search=${combinedFields?.name}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {combinedFields?.name}
                                    </a>
                                </div>
                            </div>
                        </>
                    )}

                    {selectedTab === 2 && (
                        <div ref={howToRef} className={styles.activity__HowToText}>
                            <h5 className={styles.tab__contentHeader}>How To</h5>
                            {combinedFields?.how_to ? (
                                <>
                                    <p className={styles.howToText__desc}>
                                        Here are the steps a beginner should follow to start with{' '}
                                        {combinedFields?.name}
                                    </p>
                                    {renderFormattedText(combinedFields?.how_to)}
                                </>
                            ) : (
                                <p>No How to text available</p>
                            )}
                        </div>
                    )}

                    {selectedTab === 4 && (
                        <div ref={equipmentsRef} className={styles.activity__equipments}>
                            <h5 className={styles.tab__contentHeader}>Equipments</h5>
                            {combinedFields?.equipment_text ? (
                                renderFormattedText(combinedFields?.equipment_text)
                            ) : (
                                <p>No equipment text available</p>
                            )}
                            {/* {renderFormattedText(combinedFields?.equipment_text)} */}
                        </div>
                    )}

                    {selectedTab === 6 && (
                        <div ref={requirementsRef} className={styles.activity__requirements}>
                            <h5 className={styles.tab__contentHeader}>Requirements</h5>
                            <div className={styles.activities__properties}>
                                {Object.keys(combinedFields)
                                    .filter(
                                        (key, idx) =>
                                            key !== 'description' &&
                                            key !== 'video' &&
                                            key !== 'how_to' &&
                                            key !== 'similar_activities' &&
                                            !key.includes('_text') &&
                                            combinedFields[key]?.length !== 0 &&
                                            key !== 'pexel_images',
                                    )
                                    .map((key, idx) => (
                                        <div key={idx} className={styles.activity__property}>
                                            <strong>{getFormattedKey(key)}</strong>:{' '}
                                            <span>{getValue(combinedFields[key])}</span>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}

                    {selectedTab === 3 && (
                        <div ref={placesRef} className={styles.activity__HowToText}>
                            <h5 className={styles.tab__contentHeader}>Places</h5>
                            {combinedFields?.places_text ? (
                                renderFormattedText(combinedFields?.places_text)
                            ) : (
                                <p>No places text available</p>
                            )}
                        </div>
                    )}
                    {selectedTab === 5 && (
                        <div ref={servicesRef} className={styles.activity__HowToText}>
                            <h5 className={styles.tab__contentHeader}>Services</h5>
                            {combinedFields?.services_text ? (
                                renderFormattedText(combinedFields?.services_text)
                            ) : (
                                <p>No services text available</p>
                            )}
                            {/* {renderFormattedText(combinedFields?.services_text)} */}
                        </div>
                    )}
                </>
            ) : (
                <>
                    <div className={styles.activity__description}>
                        <p>{combinedFields?.description}</p>
                    </div>

                    <div className={styles.more__btn}>
                        <StyledButton
                            onClick={() => {
                                setActivityMainTab(mainTabMapping.About);
                                navigate(`/activity/${activityName}/${'About'.toLowerCase()}`);
                            }}
                            sx={{ color: '#000' }}
                            width={'100%'}
                            bg={'#DADADA'}
                        >
                            See all
                        </StyledButton>
                    </div>
                </>
            )}
        </CommonBoxWrapper>
    );
};

export default Activity;
