import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import {
    addDoc,
    collection,
    getFirestore,
    onSnapshot,
    orderBy,
    query,
    serverTimestamp,
} from 'firebase/firestore';

const firebaseConfig = {
    apiKey: process.env['REACT_APP_FIREBASE_API_KEY'],
    authDomain: process.env['REACT_APP_FIREBASE_AUTH_DOMAIN'],
    projectId: process.env['REACT_APP_FIREBASE_PROJECT_ID'],
    storageBucket: process.env['REACT_APP_FIREBASE_STORAGE_BUCKET'],
    messagingSenderId: process.env['REACT_APP_FIREBASE_MESSAGE_SENDER_ID'],
    appId: process.env['REACT_APP_FIREBASE_APP_ID'],
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const db = getFirestore(app);

export const sendMessage = async (user, receiver, text) => {
    await addDoc(collection(db, 'chat', `chatroom-${user?.id}-${receiver?.userId}`, 'messages'), {
        uid: user.id,
        displayName: user.username,
        text: text.trim(),
        timestamp: serverTimestamp(),
    });
    await addDoc(collection(db, 'chat', `chatroom-${receiver?.userId}-${user?.id}`, 'messages'), {
        uid: user.id,
        displayName: user.username,
        text: text.trim(),
        timestamp: serverTimestamp(),
    });
};

export const getMessages = async (user, receiver, callback) => {
    return onSnapshot(
        query(
            collection(db, 'chat', `chatroom-${user?.id}-${receiver?.userId}`, 'messages'),
            orderBy('timestamp', 'asc'),
        ),
        (querySnapshot) => {
            const messages = querySnapshot.docs.map((x) => ({
                id: x.id,
                ...x.data(),
            }));

            callback(messages);
        },
    );
};

export { auth, db };
