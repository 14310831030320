import React, { useContext } from 'react';
import styles from '../LoginForm/loginForm.module.scss';
import { useFormik } from 'formik';
import { Button, TextField, styled } from '@mui/material';
import * as yup from 'yup';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from 'helper/firebase';
import { StoreContext } from 'store/useStore';
import actions from 'store/action';
import { useSnackBar } from 'hoc/snackbar';
import { getErrorMsg } from 'helper/getFirebaseErrorMsg';
import axios from 'axios';

const StyledButton = styled(Button)(() => ({
    background: '#1F62AF',
    textTransform: 'Capitalize',
    color: '#fff',
    borderRadius: '5px',
    width: '260px',
    height: '50px',
    fontSize: 18,
    '&:hover': {
        background: '#1F62AF',
    },
    '@media (max-width: 476px)': {
        fontSize: '15px !important',
    },
}));

const StyledTextField = styled(TextField)`
    .MuiInputBase-root {
        background-color: #f3f3f3;
        border: solid 1px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 0px 10px;
        -webkit-appearance: none;
        box-sizing: border-box;
        min-height: 41px;
        width: 100%;

        /* &:focus {
            outline: none;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
        } */
    }

    & .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
            border-color: #fff;
            outline: none;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
        }
    }

    .MuiInputBase-input {
        padding: 0;
        font-family: 'Arimo', sans-serif;
        font-size: 16px;
    }

    .Mui-error {
        font-family: 'Arimo', sans-serif;
        color: #ec4453;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
    }
`;

const BASE_URI = process.env['REACT_APP_BASE_URI'];

const PasswordResetForm = ({ setSelected, handleClose }) => {
    const { state, dispatch } = useContext(StoreContext);
    const { snackBarError, snackBarSuccess, snackBarInfo } = useSnackBar();

    const initialValues = {
        username: '',
        password: '',
    };

    const validationSchema = yup.object({
        username: yup.string().required('Username is required'),
        password: yup
            .string()
            .min(6, 'Password should be of minimum 6 in length')
            .max(100, 'Password should be of maximum of 100 in length'),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            handlePasswordReset(values);
        },
    });

    const setLoading = (value, msg) => {
        dispatch({
            type: actions.SET_GLOABL_LOADING,
            payload: {
                loading: value,
                text: msg ? msg : '',
            },
        });
    };

    const logout = async () => {
        dispatch({
            type: actions.SET_USER,
            payload: null,
        });
        localStorage.removeItem('jwt');
        localStorage.removeItem('user');
    };

    const handlePasswordReset = async (values) => {
        try {
            setLoading(true, 'Updating Password');
            let payload = {
                username: values.username,
                password: values.password,
            };
            let options = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const res = await axios.post(`${BASE_URI}/auth/password_reset`, payload, options);
            console.log(res);
            // if (res.status !== 200) throw res.statusText;

            const resultData = res.data;
            console.log(resultData);
            if (state.user) {
                logout();
            }
            setSelected(1);

            snackBarSuccess('Password updated successfully. Please login again with new password');

            formik.resetForm();
            setLoading(false);
        } catch (error) {
            console.log(error);
            let errorMsg = getErrorMsg(error);
            setLoading(false);
            snackBarError(`Error: ${JSON.stringify(errorMsg.toString())}`);
        }
    };

    return (
        <div className={styles.LoginForm}>
            <h3 className={styles.LoginForm__Title}>Forgot Password</h3>
            <form className={styles.form} onSubmit={formik.handleSubmit}>
                <div className={styles.form__field}>
                    <StyledTextField
                        name="username"
                        placeholder="Username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        sx={{ width: '100%' }}
                        error={formik.touched.username && Boolean(formik.errors.username)}
                        helperText={formik.touched.username && formik.errors.username}
                    />
                </div>
                <div className={styles.form__field}>
                    <StyledTextField
                        name="password"
                        placeholder="New password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        sx={{ width: '100%' }}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        type="password"
                    />
                </div>
                <div className={styles.form__actions}>
                    <StyledButton type="submit">Reset Your Password</StyledButton>
                </div>
            </form>
        </div>
    );
};

export default PasswordResetForm;
