import React, { useContext, useEffect, useState } from 'react';
import styles from './peopleLists.module.scss';
import avatar1 from 'assets/images/avatar1.png';
import { Link } from 'react-router-dom';
import ProfilePicture from 'components/ProfilePicture';
import { StoreContext } from 'store/useStore';
import { LineWave } from 'react-loader-spinner';
import axios from 'axios';

const BASE_URI = process.env['REACT_APP_BASE_URI'];

const PeopleLists = ({ itemList, title, myList, grid }) => {
    const [profilePic, setProfilePic] = useState('');
    console.log(itemList);
    const userLocal = JSON.parse(localStorage.getItem('user'));
    console.log(userLocal, ':local');
    const { state } = useContext(StoreContext);
    const [mutualLikes, setMutualLikes] = useState(0)
    

    const [loading, setLoading] = useState(false)

    const getMutualLikes = async (id) => {
        try {
            setLoading(true, 'Fetching User Details...');
            const options = {
                headers: {
                    Authorization: `Bearer ${userLocal?.access_token}`,
                    Origin: '*',
                    'ngrok-skip-browser-warning': '69420',
                },
            };
            const res = await axios.get(`${BASE_URI}/profile/bothlike${'/' + id}`, options);
            if (res.status !== 200) {
                setMutualLikes(null);
                throw res.statusText;
            }
            const resultData = res.data;
            console.log(resultData, ':mutualLikesResult')

            setMutualLikes(resultData.total);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            // snackBarError(error.toString());
        }
    };

    useEffect(() => {
        itemList?.forEach((item) => {
            getMutualLikes(item?.user?.id)
        })
        
    }, [itemList])
    return (
        <div className={styles.People}>
            {/* <h4 className={styles.People__Title}>{title}</h4> */}
            <div className={`${styles.People__Grid} ${grid && styles.People__Grid1}`}>
                {itemList
                    ?.filter((item) => userLocal?.id != item?.user?.id)
                    ?.map((item, idx) => (
                        <Link
                            key={idx}
                            to={`/profile/${item?.user?.id}`}
                            style={{ textDecoration: 'none' }}
                        >
                            <div className={styles.People__Item}>
                                {myList ? (
                                    <div className={styles.List__Images}>
                                        {item?.placesList?.map((img, idx) => (
                                            <img key={idx} src={img} alt="" />
                                        ))}
                                    </div>
                                ) : (
                                    <ProfilePicture userId={item?.user?.id} />
                                )}

                                <div className={styles.People__Info}>
                                    {myList ? (
                                        <>
                                            <p className={styles.List__Title}>{item.listName}</p>
                                            <span className={styles.People__Ratings}>
                                                {item.rating}
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <p className={styles.People__Name}>
                                                {item?.user?.user_detail?.first_name ? `${item?.user?.user_detail?.first_name} ${item?.user?.user_detail?.last_name}` : item?.user?.username}
                                            </p>
                                            {state?.user && (
                                                <span className={styles.People__Ratings}>
                                                    {loading ? (
                                                        <LineWave
                                                            visible={true}
                                                            height="40px"
                                                            width="40px"
                                                            color="#0069c0"
                                                            ariaLabel="line-wave-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                            firstLineColor=""
                                                            middleLineColor=""
                                                            lastLineColor=""
                                                        />
                                                    ): mutualLikes} mutual likes
                                                </span>
                                            )}

                                            {state?.user && (
                                                <Link
                                                    to={`/chat?user=${item?.user.id}`}
                                                    style={{
                                                        textDecoration: 'none',
                                                        color: '#1F62AF',
                                                    }}
                                                >
                                                    Message
                                                </Link>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </Link>
                    ))}
            </div>
        </div>
    );
};

export default PeopleLists;
