export default {
    HOMEPAGE: '/',
    FILTER: '/filter',
    FILTERS: '/filters',
    ACTIVITY: '/activity/:activityName',
    ACTIVITY_WITH_TAB: '/activity/:activityName/:activityTab',
    USER_ACTIVITY: '/:username/:ratingType',
    PROFILE: '/profile/:userId',
    PROFIE_WITH_TAB: '/profile/:userId/:profileTab',
    MY_LIST: '/:username/my-lists',
    CHAT: '/chat',
    LOGIN: '/login',
    REGISTER: '/register',
};
