import React from 'react';
import styles from './activitySidebar.module.scss';

const ActivitySidebarLinks = ({ title, linkItems }) => {
    return (
        <div className={styles.sidebarLinks}>
            <h4>{title}</h4>
            {linkItems?.map((item, idx) => (
                <a key={idx} href={item?.link} target="_blank" rel="noopener noreferrer">
                    {item?.text}
                </a>
            ))}
        </div>
    );
};

export default ActivitySidebarLinks;
