import actions from './action';

export const initialState = {
    cardsData: null,
    // searchedData: null,
    totalActivities: 0,
    userLocation: null,
    globalLoading: false,
    loadingText: '',
    user: null,
    allUsers: null,
    lists: [],
    weatherInfo: null,
    userProfilePic: '',
};

export const reducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
    case actions.SET_DATA:
            return {
                ...state,
                cardsData: payload.data,
                totalActivities: payload.totalActivities
            };
        // case actions.SET_SEARCH_DATA:
        //     return {
        //         ...state,
        //         searchedData: payload,
        //     };
        // case actions.CLEAR_SEARCH_DATA:
        //     return {
        //         ...state,
        //         searchedData: null,
        //     };
        case actions.SET_LOCATION:
            return {
                ...state,
                userLocation: payload,
            };
        case actions.SET_GLOABL_LOADING:
            return {
                ...state,
                globalLoading: payload.loading,
                loadingText: payload.text,
            };
        case actions.SET_USER:
            return {
                ...state,
                user: payload,
            };
        case actions.SET_USER_PROFILE_PIC:
            return {
                ...state,
                userProfilePic: payload,
            };
        case actions.SET_USERS:
            return {
                ...state,
                allUsers: payload,
            };
        case actions.ADD_LISTS:
            return {
                ...state,
                lists: [...state.lists, payload],
            };
        case actions.SET_WEATHER_INFO:
            return {
                ...state,
                weatherInfo: payload,
            };
        default: {
            return state;
        }
    }
};
