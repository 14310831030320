import { CustomModal } from 'components/common/Modal';
import styles from './addList.module.scss';
import { Button, styled } from '@mui/material';
import CreateListModal from 'components/CreateListModal';
import { useContext, useState } from 'react';
import { StoreContext } from 'store/useStore';

const StyledButton = styled(Button)(({ bg }) => ({
    background: bg,
    textTransform: 'Capitalize',
    color: bg === 'none' ? '#1F62AF' : '#fff',
    borderRadius: '5px',
    width: 'fit-content',
    height: '41px',
    fontSize: 18,
    fontWeight: 700,
    '&:hover': {
        background: bg,
    },
    '@media (max-width: 476px)': {
        fontSize: '15px !important',
    },
}));

const AddToList = ({ open, setOpen }) => {
    const onClose = () => setOpen(false);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const { state, dispatch } = useContext(StoreContext);
    return (
        <>
            <CustomModal open={open} onClose={onClose} title={'Add to list'}>
                <div className={styles.actions__box}>
                    <div className={styles.activity__actions}>
                        <select>
                            <option value={''} disabled selected>
                                Choose a list
                            </option>
                            {state.lists.length > 0 &&
                                state.lists.map((x, idx) => (
                                    <option key={idx} value={x.listTitle}>
                                        {x.listTitle}
                                    </option>
                                ))}
                        </select>
                    </div>
                    <div className={styles.createList}>
                        <StyledButton
                            onClick={() => {
                                onClose();
                                setOpenCreateModal(true);
                            }}
                            bg="none"
                        >
                            Or create a new list
                        </StyledButton>
                    </div>
                    <div className={styles.submitBtn}>
                        <StyledButton bg="none">Save</StyledButton>
                    </div>
                </div>
            </CustomModal>
            <CreateListModal
                open={openCreateModal}
                setOpen={setOpenCreateModal}
                setOpenInitialModal={setOpen}
            />
        </>
    );
};

export default AddToList;
