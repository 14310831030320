const MAPBOX_API_KEY = process.env.REACT_APP_MAP_API_KEY;

export const fetchPlace = async (text) => {
    try {
        const res = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${text}.json?access_token=${MAPBOX_API_KEY}&cachebuster=1625641871908&autocomplete=true&types=place`,
        );
        if (!res.ok) throw new Error(res.statusText);
        return res.json();
    } catch (error) {
        return { error: 'Unable to retrieve places' };
    }
};
