import React from 'react';
import styles from './ratingItems.module.scss';
import { frequencyNumbers, ratingNumbers } from 'constants/ratingsScale';
import { AiFillDislike } from 'react-icons/ai';
import { GiShrug } from 'react-icons/gi';
import { AiFillLike } from 'react-icons/ai';
import { BsFillHeartFill } from 'react-icons/bs';
import { BsFire } from 'react-icons/bs';
import SyncIcon from '@mui/icons-material/Sync';
import { styled } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

const RatingItemsTabs = ({ title, ratingType, handleRating }) => {
    const ratingData = {
        like: ratingNumbers,
        frequency: frequencyNumbers,
    };

    const likeContentImg = {
        1: <AiFillDislike />,
        2: <GiShrug />,
        3: <AiFillLike />,
        4: <BsFillHeartFill />,
        5: <BsFire />,
    };

    const doneContentImg = {
        1: '0',
        2: '1',
        3: '5+',
        4: '20+',
        5: <SyncIcon />,
    };

    return (
        <div className={styles.Rating}>
            <h3>{title}</h3>
            <div className={styles.Rating__Grid}>
                {Object.keys(ratingData[ratingType])
                    // .sort((keyA, keyB) => keyB - keyA)
                    .map((key, idx) => (
                        <StyledTooltip key={key} title={ratingData[ratingType][key]}>
                            <div
                                className={[
                                    styles.rating__items,
                                    // myActivity?.like == key ? styles.active : '',
                                ].join(' ')}
                                onClick={() => handleRating(key)}
                            >
                                <div className={styles.icon}>
                                    {ratingType === 'like'
                                        ? likeContentImg[key]
                                        : doneContentImg[key]}
                                </div>
                                {/* <p>{ratingNumbers[key]}</p> */}
                            </div>
                        </StyledTooltip>

                        // <span title={ratingNumbers[key]} onClick={() => onClick(key)} key={key}>
                        //     {likeContentImg[key]}
                        // </span>
                    ))}
            </div>
        </div>
    );
};

export default RatingItemsTabs;
