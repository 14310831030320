export const TEMPERATURE_FILTER = [
    {
        label: '< 0 °C',
        value: '-100-0',
    },
    {
        label: '1-9 °C',
        value: '0-10',
    },
    {
        label: '10-19 °C',
        value: '10-19',
    },
    {
        label: '20-29 °C',
        value: '20-29',
    },
    {
        label: '30-39 °C',
        value: '30-39',
    },
    {
        label: '40 °C +',
        value: '40-100',
    },
];
