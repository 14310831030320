import React, { useContext, useEffect, useState } from 'react';
import styles from './userActivity.module.scss';
import { StarOutline } from '@mui/icons-material';
import { useLocation } from 'react-router';
import ReactPlayer from 'react-player';
import { Button } from '@mui/material';
import styled from '@emotion/styled';
import LikeModal from 'components/LikeModal';
import FrequencyModal from 'components/FrequencyModal';
import RatingItemsTabs from 'components/RatingItemsTabs';
import { MdPublic } from 'react-icons/md';
import { StoreContext } from 'store/useStore';
import actions from 'store/action';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import routes from 'constants/routes';
import { useSnackBar } from 'hoc/snackbar';
import MobileHeader from 'components/MobileHeader';

const StyledButton = styled(Button)(({ bg, customFontWeight, customFontSize }) => ({
    background: bg,
    textTransform: 'Capitalize',
    color: '#000000',
    borderRadius: '5px',
    width: 'fit-content',
    height: '50px',
    fontSize: customFontSize ?? 20,
    fontWeight: customFontWeight ?? 400,
    transition: 'all 0.3s',

    '@media (max-width: 476px)': {
        fontSize: '15px !important',
    },
}));

const mainTabMapping = {
    Likes: 1,
    Done: 2,
};

const RATING_TYPE = {
    RATING_LIKE: 'rating_like',
    RATING_DONE: 'rating_done',
};

const BASE_URI = process.env['REACT_APP_BASE_URI'];

const UserActivity = () => {
    const location = useLocation();
    const { ratingType } = useParams();
    // const { activityData } = location.state;
    const [selectedTab, setSelectedTab] = useState(mainTabMapping.Likes);
    const [activityData, setActivityData] = useState();
    const { state, dispatch } = useContext(StoreContext);
    const { user } = state;
    const navigate = useNavigate();
    const { snackBarError, snackBarSuccess, snackBarInfo } = useSnackBar();
    // const activityData = {};

    const logout = async () => {
        dispatch({
            type: actions.SET_USER,
            payload: null,
        });
        localStorage.removeItem('jwt');
        localStorage.removeItem('user');
        // handleClose();
    };

    const setLoading = (value, msg) => {
        dispatch({
            type: actions.SET_GLOABL_LOADING,
            payload: {
                loading: value,
                text: msg ? msg : '',
            },
        });
    };

    const fetchNotLikedData = async () => {
        try {
            setLoading(true);
            const jwt = localStorage.getItem('jwt');
            const options = {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            };
            const res = await axios.get(`${BASE_URI}/activities_not_liked`, options);
            const resultData = res.data;
            setActivityData(resultData.data[0]);
            setLoading(false);
        } catch (error) {
            console.log(error);

            if (error?.response?.data?.message[0]?.error == 'Expired Token') {
                logout();
                navigate(routes.LOGIN);
                snackBarError(
                    'Your session has been expired. Please Login again to continue as User or continue as guest.',
                );
            }

            setLoading(false);
        }
    };
    const fetchNotDoneData = async () => {
        try {
            setLoading(true);
            const jwt = localStorage.getItem('jwt');
            const options = {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            };
            const res = await axios.get(`${BASE_URI}/activities_not_done`, options);
            const resultData = res.data;
            setActivityData(resultData.data[0]);
            setLoading(false);
        } catch (error) {
            console.log(error);

            if (error?.response?.data?.message[0]?.error == 'Expired Token') {
                logout();
                navigate(routes.LOGIN);
                snackBarError(
                    'Your session has been expired. Please Login again to continue as User or continue as guest.',
                );
            }

            setLoading(false);
        }
    };

    const handleLike = async (e) => {
        try {
            if (!state.user) throw 'Please login first';
            setLoading(true, 'Rating activity...');
            // const activityId = activity.id;
            const payload = {
                like: e,
            };
            const jwt = localStorage.getItem('jwt');
            const options = {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': 'application/json',
                },
            };
            const res = await axios.post(
                `${BASE_URI}/activities/${activityData?.id}/like`,
                payload,
                options,
            );
            if (!(res.status >= 200 && res.status < 400)) return res.statusText;
            const resData = res.data;
            // const isPrevRated = myActivity?.activity?.id === activityId && myActivity?.like != null;
            // await getRatedActivity();
            // if (isProfile) {
            //     await updateRatings();
            // }
            // setCounter((prev) => {
            //     return {
            //         ...prev,
            //         like_counter: isPrevRated ? prev.like_counter : prev.like_counter + 1,
            //     };
            // });
            // setIsLike(true);
            snackBarSuccess(`Successfully rated ${resData.data.activity.name}`);
            await fetchNotLikedData();
            console.log(res);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            // snackBarError(`Error: ${JSON.stringify(error?.message)}`);

            if (error?.response?.data?.message[0]?.error == 'Expired Token') {
                logout();
                navigate(routes.LOGIN);
                snackBarError('Your session has been expired. Please Login again to continue.');
            } else {
                snackBarError(error.toString());
            }
        }
    };

    const handleFrequency = async (e) => {
        try {
            if (!state.user) throw 'Please login first';
            setLoading(true, 'Rating activity...');
            // const activityId = activity.id;
            const payload = {
                frequency: e,
            };
            const jwt = localStorage.getItem('jwt');
            const options = {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': 'application/json',
                },
            };
            const res = await axios.post(
                `${BASE_URI}/activities/${activityData?.id}/frequency`,
                payload,
                options,
            );
            if (!(res.status >= 200 && res.status < 400)) return res.statusText;
            const resData = res.data;
            snackBarSuccess(`Successfully rated ${resData.data.activity.name}`);
            await fetchNotDoneData();
            console.log(res);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            snackBarError(`Error: ${JSON.stringify(error?.message)}`);

            if (error?.response?.data?.message[0]?.error == 'Expired Token') {
                logout();
                navigate(routes.LOGIN);
                snackBarError('Your session has been expired. Please Login again to continue.');
            } else {
                snackBarError(error.toString());
            }
        }
    };

    useEffect(() => {
        if (ratingType === RATING_TYPE.RATING_LIKE) {
            setSelectedTab(mainTabMapping.Likes);
            fetchNotLikedData();
        } else {
            setSelectedTab(mainTabMapping.Done);
            fetchNotDoneData();
        }
    }, [ratingType]);

    if (!activityData) {
        if (state.globalLoading) {
            return <></>;
        } else {
            return <p>Activity not found</p>;
        }
    }

    return (
        <div className={styles.UserActivity}>
            {/* <MobileHeader
                title={selectedTab === mainTabMapping.Likes ? 'Like Rating' : 'Done Rating'}
            /> */}
            <div className={styles.UserActivity__Container}>
                <div className={styles.UserActivty__RatingActions}>
                    <div className={styles.UserActivity__tabsButtons}>
                        {Object.keys(mainTabMapping).map((key) => (
                            <div
                                key={key}
                                className={[
                                    styles.tabButton,
                                    selectedTab === mainTabMapping[key] ? styles.active : '',
                                    // activityMainTab === mainTabMapping[key]
                                    //     ? styles.active
                                    //     : '',
                                ].join(' ')}
                                onClick={() => {
                                    setSelectedTab(mainTabMapping[key]);
                                    navigate(
                                        `/${user?.username}/${
                                            mainTabMapping[key] === 1
                                                ? 'rating_like'
                                                : 'rating_done'
                                        }`,
                                    );
                                    // navigate(`/activity/${activityName}/${key.toLowerCase()}`);
                                }}
                            >
                                {key}
                            </div>
                        ))}
                    </div>
                </div>

                <div className={styles.UserActivity__TitleHeader}>
                    <h3>{activityData?.name}</h3>
                    {/* <StyledButton bg={'#DADADA'}>
                        <MdPublic />
                    </StyledButton> */}
                </div>
                <div className={styles.UserActivity__Desc}>{activityData?.description}</div>
                <div className={styles.video}>
                    <ReactPlayer
                        url={activityData?.video}
                        width={'100%'}
                        height={'255px'}
                        controls
                        style={{
                            borderRadius: '10px',
                            overflow: 'hidden',
                        }}
                    />
                </div>

                {/* {selectedTab === 1 && (
                    <RatingItemsTabs title={'How do you like this activity?'} ratingType={'like'} />
                )}
                {selectedTab === 2 && (
                    <RatingItemsTabs
                        title={'How many times have you done this activity?'}
                        ratingType={'frequency'}
                    />
                )}
                {selectedTab === 3 && (
                    <RatingItemsTabs
                        title={'With whom would you do this activity?'}
                        ratingType={'social'}
                    />
                )} */}
            </div>
            <div className={styles.UserActivity__RatingBox}>
                <div className={styles.UserActivity__Container}>
                    {selectedTab === 1 && (
                        <RatingItemsTabs
                            title={'How do you like it?'}
                            ratingType={'like'}
                            handleRating={handleLike}
                        />
                    )}
                    {selectedTab === 2 && (
                        <RatingItemsTabs
                            title={'How many times have you done it?'}
                            ratingType={'frequency'}
                            handleRating={handleFrequency}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserActivity;
