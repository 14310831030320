import styles from './filterSection.module.scss';
import DropdownFilter from './dropdownFilter';
import styled from '@emotion/styled';
import { Button, IconButton } from '@mui/material';
// import { TAGS_FILTER } from 'constants/data-filter/tagsFilter';
// import { GOAL_FILTER } from 'constants/data-filter/goalFilter';
import { FITNESS_FILTER } from 'constants/data-filter/fitnessFilter';
// import { PLACES_FILTER } from 'constants/data-filter/placesFilter';
import { ENVIRONTMENT_FILTER } from 'constants/data-filter/environmentFilter';
// import { EQUIPMENT_FILTER } from 'constants/data-filter/equipmentFilter';
import { BUDGET_FILTER } from 'constants/data-filter/budgetFilter';
import { TEMPERATURE_FILTER } from 'constants/data-filter/temperatureFilter';
import { VISIBILTY_FILTER } from 'constants/data-filter/visibilityFilter';
import { WIND_FILTER } from 'constants/data-filter/windFilter';
import { PEOPLE_FILTER } from 'constants/data-filter/peopleFilter';
import { AGE_FILTER } from 'constants/data-filter/ageFilter';
// import { WEIGHT_FILTER } from 'constants/data-filter/weightFilter';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { RADIUS_FILTER } from 'constants/data-filter/radiusFilter';
import { TIME_FILTER } from 'constants/data-filter/timeFilter';
import { DATE_FILTER } from 'constants/data-filter/dateFilter';
import { StoreContext } from 'store/useStore';
import actions from 'store/action';
import axios from 'axios';
import { FaChevronLeft } from 'react-icons/fa6';
import moment from 'moment';

const FilterTag = [
    {
        name: 'Learning',
    },
    {
        name: 'Relaxing',
    },
    {
        name: 'Fitness',
    },
];

export const allFilters = [
    'Tags',
    'Goals',
    'Fitness',
    'Radius',
    'Places',
    'Environment',
    'Budget',
    'Equipment',
    'Services',
    'Temperature',
    'Visibility',
    'Wind',
    'People',
    'Age',
];

const StyledButton = styled(Button)(({ bg, customWidth }) => ({
    background: bg,
    textTransform: 'Capitalize',
    color: bg === 'none' ? '#1F62AF' : '#fff',
    borderRadius: '5px',
    width: customWidth ? customWidth : '123px',
    height: '41px',
    fontWeight: 'bold',
    fontSize: 16,
    '&:hover': {
        background: bg,
    },
    '@media (max-width: 476px)': {
        fontSize: '15px !important',
    },
}));

const BASE_URI = process.env['REACT_APP_BASE_URI'];

const FilterMenu = ({getActivitiesList, setShowFilterInMobile,setCurrentPage, currentPage }) => {
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    console.log(searchParams, ':search');

    const urlQuery = {};
    searchParams.forEach((value, key) => {
        if (allFilters.includes(key)) {
            urlQuery[key] = [value];
        }
    });
    const hasParams = location.search === '';
    const [isClear, setIsClear] = useState(false);
    const navigate = useNavigate();
    const [filterParams, setFilterParams] = useState(urlQuery);
    const [disableButton, setDisableButton] = useState(false);
    console.log(filterParams, ':params');
    const { state, dispatch } = useContext(StoreContext);
    const { user } = state;
    console.log(user, ':user');
    const [GOAL_FILTER, setGoalFilter] = useState([]);
    const [PLACES_FILTER, setPlacesFilter] = useState([]);
    const [TAGS_FILTER, setTagsFilter] = useState([]);
    const [EQUIPMENT_FILTER, setEquipmentsFilter] = useState([]);
    const [SERVICES_FILTER, setServicesFilter] = useState([]);
    const [isSticky, setIsSticky] = useState(false);
    const [isClearAll, setIsClearAll] = useState(false);

    console.log(urlQuery, 'queryString');

    const setLoading = (value, msg) => {
        dispatch({
            type: actions.SET_GLOABL_LOADING,
            payload: {
                loading: value,
                text: msg ? msg : '',
            },
        });
    };

    const getAge = () => {
        const birthdateMoment = moment(user?.user_detail?.birthdate, 'YYYY-MM-DD');
        const currentDate = moment();
        const age = currentDate.diff(birthdateMoment, 'years');
        return age.toString();
    };

    const handleApplyFilter = async () => {
        try {
            setLoading(true, 'Searching activity...');
            let payload = {
                offset: (currentPage - 1) * 100,
                limit: 100,
            };
            const queryParams = Object.keys(filterParams).map((key) => {
                const paramValue = filterParams[key]
                    .map((tag) => encodeURIComponent(tag))
                    .join(',');
                return `${key}=${paramValue}`;
            });

            if (Object.keys(filterParams).length > 0) {
                payload = {
                    ...payload,
                    ...filterParams,
                };
            }

            console.log(queryParams, ';qwerr');

            // const newUrl = `/?${queryParams.join('&')}`;

            // invoke activities api
            let options = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            if ('page' in payload) {
                delete payload.page;
            }

            const res = await axios.post(`${BASE_URI}/activities`, payload, options);
            if (res.status !== 200) throw res.statusText;

            const { data } = res;
            console.log(data);
            dispatch({
                type: actions.SET_DATA,
                payload: {
                    data: data.data,
                    totalActivities: data.total_rows
                },
            });

            // setTotalActivities(data.total_rows)
            console.log(filterParams, ':filter')

            // let newPayload = {
            //     page: Math.floor(payload.offset / 100) + 1,
            //     ...queryParams,
            // };

            // if ('limit' in newPayload) {
            //     delete newPayload.limit;
            // }
            // if ('offset' in newPayload) {
            //     delete newPayload.offset;
            // }

            const newUrl = `/?page=${Math.floor(payload.offset / 100) + 1}&${queryParams.join('&')}`;

            // const queryString1 = new URLSearchParams(newPayload).toString();
            console.log(newUrl, ':string');
    
           

            setLoading(false);

            //

            // console.log(newUrl);

            // navigate(newUrl);
            navigate(newUrl);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const setFilterValue = (filterType, selectedTagss) => {
        console.log(filterType, ':type');
        setFilterParams((prevParams) => ({
            ...prevParams,
            [filterType]: selectedTagss,
        }));
    };

    useEffect(() => {
        if (hasParams) {
            setIsClear(true);
        } else {
            setIsClear(false);
        }
    }, [hasParams]);

    const fetchFilterValues = () => {
        setDisableButton(true);
        const request1 = axios.get(`${BASE_URI}/goals`);
        const request2 = axios.get(`${BASE_URI}/tags`);
        const request3 = axios.get(`${BASE_URI}/places`);
        const request4 = axios.get(`${BASE_URI}/equipments`);
        const request5 = axios.get(`${BASE_URI}/services`);

        // Make all three API requests in parallel
        axios
            .all([request1, request2, request3, request4, request5])
            .then(
                axios.spread((response1, response2, response3, response4, response5) => {
                    // Handle the responses for each API call
                    console.log('Response from API 1:', response1.data);
                    const filterVal = response1.data.data.map((item) => {
                        const obj = {
                            value: item.name,
                        };
                        return obj;
                    });
                    setGoalFilter(filterVal);
                    console.log('Response from API 2:', response2.data);
                    const tagsVal = response2.data.data.map((item) => {
                        const obj = {
                            value: item.name,
                        };
                        return obj;
                    });
                    setTagsFilter(tagsVal);
                    console.log('Response from API 3:', response3.data);

                    const placesVal = response3.data.data.map((item) => {
                        const obj = {
                            value: item.name,
                        };
                        return obj;
                    });
                    setPlacesFilter(placesVal);
                    console.log('Response from API 4:', response4.data);

                    const equipVal = response4.data.data.map((item) => {
                        const obj = {
                            value: item.name,
                        };
                        return obj;
                    });
                    setEquipmentsFilter(equipVal);
                    console.log('Response from API :5', response5.data);

                    const servicesVal = response5.data.data.map((item) => {
                        const obj = {
                            value: item.name,
                        };
                        return obj;
                    });
                    setServicesFilter(servicesVal);

                    setDisableButton(false);
                }),
            )
            .catch((error) => {
                // Handle any errors that occurred during the API requests
                console.error('Error:', error);
                setDisableButton(false);
            });
    };

    useEffect(() => {
        if (
            SERVICES_FILTER.length === 0 ||
            EQUIPMENT_FILTER.length === 0 ||
            TAGS_FILTER.length === 0 ||
            PLACES_FILTER.length === 0 ||
            GOAL_FILTER.length === 0
        ) {
            fetchFilterValues();
        }
    }, []);

    const handleClearAll = async () => {
        setCurrentPage(1)
        navigate('/');
        setFilterParams({});
        setIsClearAll(true);
        
    };

    // console.log(FITNESS_FILTER);

    useEffect(() => {
        const handleScroll = () => {
            console.log(window.scrollY, ':Scroll Y');
            if (window.scrollY >= 56) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // useEffect(() => {
    //     if (Object.keys(urlQuery).length !== 0) {
    //         setFilterParams(urlQuery);
    //     }
    // }, []);

    useEffect(() => {
        if (Object.keys(filterParams).length > 0) {
            handleApplyFilter();
        } else {
            getActivitiesList()
        }
    }, [filterParams, currentPage]);

    return (
        <aside className={[styles.filter__container, isSticky ? styles.sticky : ''].join(' ')}>
            <div className={styles.filter__header}>
                <div className={styles.filter__headerLeft}>
                    <IconButton
                        onClick={() => {
                            setShowFilterInMobile(false);
                        }}
                        className={styles.leftIcon}
                    >
                        <FaChevronLeft color="#000" fontSize={'18px'} />
                    </IconButton>

                    <h3 className={styles.filter__title}>Filter</h3>
                </div>

                <StyledButton
                    bg={'none'}
                    customWidth={'50px'}
                    type="submit"
                    onClick={handleClearAll}
                >
                    Clear
                </StyledButton>
            </div>
            <div className={styles.filter_item_container}>
                <h4 style={{ margin: '10px 0 8px 0' }}>What</h4>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                    <DropdownFilter
                        nameFilter={'Tags'}
                        itemFilter={TAGS_FILTER}
                        filterType="Tags"
                        setFilterValue={setFilterValue}
                        onClear={handleClearAll}
                        hasParams={isClear}
                        searchField={true}
                        disabled={disableButton}
                        isClearAll={isClearAll}
                        queryFilter={urlQuery}
                    />
                    <DropdownFilter
                        nameFilter={'Goals'}
                        itemFilter={GOAL_FILTER}
                        filterType="Goals"
                        setFilterValue={setFilterValue}
                        onClear={handleClearAll}
                        hasParams={isClear}
                        searchField={true}
                        disabled={disableButton}
                        isClearAll={isClearAll}
                        queryFilter={urlQuery}
                    />
                    <DropdownFilter
                        nameFilter={'Fitness'}
                        itemFilter={FITNESS_FILTER}
                        filterType={'Fitness'}
                        setFilterValue={setFilterValue}
                        onClear={handleClearAll}
                        hasParams={isClear}
                        isClearAll={isClearAll}
                        queryFilter={urlQuery}
                        defaultLabel={'My Fitness'}
                        defaultValue={user?.user_detail?.fitness}
                        filterParams={filterParams}
                    />
                </div>
                <h4 style={{ margin: '10px 0 8px 0' }}>Where</h4>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                    {/* <DropdownFilter
                        onClear={handleClearAll}
                        hasParams={isClear}
                        type={'radio'}
                        setFilterValue={setFilterValue}
                        filterType={'Radius'}
                        nameFilter={'Radius'}
                        itemFilter={RADIUS_FILTER}
                        isClearAll={isClearAll}
                        queryFilter={urlQuery}
                    /> */}
                    <DropdownFilter
                        onClear={handleClearAll}
                        hasParams={isClear}
                        setFilterValue={setFilterValue}
                        filterType="Places"
                        nameFilter={'Places'}
                        itemFilter={PLACES_FILTER}
                        searchField={true}
                        disabled={disableButton}
                        isClearAll={isClearAll}
                        queryFilter={urlQuery}
                        defaultLabel={'Places from the map'}
                        defaultValue={'useOpenStreetMapFilter'}
                        filterParams={filterParams}
                    />
                    <DropdownFilter
                        onClear={handleClearAll}
                        hasParams={isClear}
                        setFilterValue={setFilterValue}
                        filterType="Environment"
                        nameFilter={'Environment'}
                        itemFilter={ENVIRONTMENT_FILTER}
                        isClearAll={isClearAll}
                        queryFilter={urlQuery}
                    />
                </div>
                {/* <h4 style={{ margin: '10px 0 8px 0' }}>When</h4>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                    <DropdownFilter
                        onClear={handleClearAll}
                        hasParams={isClear}
                        setFilterValue={setFilterValue}
                        filterType="Time"
                        nameFilter={'Time'}
                        itemFilter={TIME_FILTER}
                        searchField={true}
                    />
                    <DropdownFilter
                        onClear={handleClearAll}
                        hasParams={isClear}
                        setFilterValue={setFilterValue}
                        filterType="Date"
                        nameFilter={'Date'}
                        itemFilter={DATE_FILTER}
                        searchField={true}
                    />
                </div> */}
                <h4 style={{ margin: '10px 0 8px 0' }}>Requirements</h4>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                    <DropdownFilter
                        onClear={handleClearAll}
                        hasParams={isClear}
                        setFilterValue={setFilterValue}
                        filterType="Budget"
                        nameFilter={'Budget'}
                        itemFilter={BUDGET_FILTER}
                        isClearAll={isClearAll}
                        queryFilter={urlQuery}
                    />
                    <DropdownFilter
                        onClear={handleClearAll}
                        hasParams={isClear}
                        setFilterValue={setFilterValue}
                        filterType="Equipment"
                        nameFilter={'Equipment'}
                        itemFilter={EQUIPMENT_FILTER}
                        searchField={true}
                        disabled={disableButton}
                        isClearAll={isClearAll}
                        queryFilter={urlQuery}
                        defaultLabel={'No equipment needed'}
                        defaultValue={''}
                        filterParams={filterParams}
                    />
                    <DropdownFilter
                        onClear={handleClearAll}
                        hasParams={isClear}
                        setFilterValue={setFilterValue}
                        filterType="Services"
                        nameFilter={'Services'}
                        itemFilter={SERVICES_FILTER}
                        searchField={true}
                        disabled={disableButton}
                        isClearAll={isClearAll}
                        queryFilter={urlQuery}
                    />

                    <DropdownFilter
                        onClear={handleClearAll}
                        hasParams={isClear}
                        setFilterValue={setFilterValue}
                        filterType="Temperature"
                        nameFilter={'Temperature'}
                        itemFilter={TEMPERATURE_FILTER}
                        isClearAll={isClearAll}
                        queryFilter={urlQuery}
                        defaultLabel={'Current temperature'}
                        defaultValue={String(state?.weatherInfo?.temperature)}
                        filterParams={filterParams}
                    />
                </div>
                {/* <h4 style={{ margin: '10px 0 8px 0' }}>Wheater</h4>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                    <DropdownFilter
                        onClear={handleClearAll}
                        hasParams={isClear}
                        setFilterValue={setFilterValue}
                        filterType="Temperature"
                        nameFilter={'Temperature'}
                        itemFilter={TEMPERATURE_FILTER}
                        isClearAll={isClearAll}
                        queryFilter={urlQuery}
                        defaultLabel={'Current temperature'}
                        defaultValue={String(state?.weatherInfo?.temperature)}
                        filterParams={filterParams}
                    />
                    <DropdownFilter
                        onClear={handleClearAll}
                        hasParams={isClear}
                        setFilterValue={setFilterValue}
                        filterType="Visibility"
                        nameFilter={'Visibility'}
                        itemFilter={VISIBILTY_FILTER}
                        isClearAll={isClearAll}
                        queryFilter={urlQuery}
                        defaultLabel={'Current visibility'}
                        defaultValue={String(state?.weatherInfo?.visibility)}
                        filterParams={filterParams}
                    />
                    <DropdownFilter
                        onClear={handleClearAll}
                        hasParams={isClear}
                        setFilterValue={setFilterValue}
                        filterType="Wind"
                        nameFilter={'Wind'}
                        itemFilter={WIND_FILTER}
                        isClearAll={isClearAll}
                        queryFilter={urlQuery}
                        defaultLabel={'Current wind speed'}
                        defaultValue={String(state?.weatherInfo?.wind)}
                        filterParams={filterParams}
                    />
                </div> */}
                <h4 style={{ margin: '10px 0 8px 0' }}>Audience</h4>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                    <DropdownFilter
                        onClear={handleClearAll}
                        hasParams={isClear}
                        setFilterValue={setFilterValue}
                        filterType="People"
                        nameFilter={'People'}
                        itemFilter={PEOPLE_FILTER}
                        searchField={true}
                        isClearAll={isClearAll}
                        queryFilter={urlQuery}
                    />
                    <DropdownFilter
                        onClear={handleClearAll}
                        hasParams={isClear}
                        setFilterValue={setFilterValue}
                        filterType="Age"
                        nameFilter={'Age'}
                        itemFilter={AGE_FILTER}
                        isClearAll={isClearAll}
                        queryFilter={urlQuery}
                        defaultLabel={'My Age'}
                        defaultValue={getAge()}
                        filterParams={filterParams}
                    />
                </div>
            </div>
            {/* <div className={styles.bottom_button_group}>
                <StyledButton bg={'none'} type="submit" onClick={handleClearAll}>
                    Clear
                </StyledButton>
                <StyledButton bg={'#3B83B8'} type="submit" onClick={handleApplyFilter}>
                    Apply
                </StyledButton>
            </div> */}
        </aside>
    );
};

export default FilterMenu;
