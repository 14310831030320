export const goalsTags = [
    'Enjoy',
    'Conversation',
    'Helping',
    'Fitness',
    'Fun',
    'Learning',
    'Relaxation',
    'Action',
    'Beauty',
    'Experience',
    'Health',
    'Expression',
    'Wealth',
    'Business',
];

export const disabilitiesTags = [
    'blind',
    'deaf',
    'wheelchair',
    'fear of height',
    'cruthes',
    'overweight',
];

export const locationTags = [
    'acting school',
    'airfield',
    'airport',
    'amphibious',
    'animal sanctuarx',
    'animal sanctuary',
    'antarctic expeditions',
    'antiquary',
    'aquarium',
    'archery school',
    'arctic cruise',
    'art school',
    'auction',
    'autocross',
    'Autonomous vehicle testing',
    'bakery',
    'ballet',
    'balloon',
    'baloon',
    'bar',
    'bar school',
    'baseball',
    'basketball',
    'beach',
    'beauty salon',
    'billard',
    'blacksmith',
    'boat tour',
    'bookbinding',
    'botanical garden',
    'bowling',
    'brewery',
    'bumper car',
    'bungee jumping',
    'cable car',
    'cablecar',
    'cafe',
    'calligraphy school',
    'canoe',
    'canoe school',
    'canoe tour',
    'canyon swing',
    'carousel',
    'carriage',
    'carriage ride',
    'casino',
    'castle',
    'catle',
    'cave',
    'cemetery',
    'church',
    'cinema',
    'circus',
    'city',
    'cliff jump',
    'climbing',
    'climbing school',
    'clinic',
    'clown',
    'coach',
    'coah',
    'coaster',
    'comedy',
    'computer',
    'concert',
    'cooking school',
    'cricket',
    'cruise',
    'cultural tour',
    'culture',
    'curling',
    'dance',
    'dance school',
    'dancing school',
    'dart',
    'desert',
    'design school',
    'dicing school',
    'discuss',
    'diving',
    'diving school',
    'dolphines',
    'dragon boat',
    'drawing',
    'dricing school',
    'driving school',
    'event',
    'fair',
    'farm',
    'farmer',
    'fashion stores',
    'fashionshow',
    'fasting',
    'fencing',
    'feng shui school',
    'ferris wheel',
    'fitness center',
    'flightsimulator',
    'floating',
    'florist',
    'food tour',
    'football',
    'forest',
    'funsport',
    'gallery',
    'garage',
    'garden',
    'gardening school',
    'ghost tour',
    'go kart',
    'gokart',
    'golf',
    'golf school',
    'graffiti',
    'graffiti school',
    'guide',
    'gym',
    'hairdresser',
    'handball',
    'hanggliding',
    'hot tube',
    'hotel',
    'ice hockey event',
    'ice hockey stadion',
    'ice hockey stafion',
    'ice sculptures',
    'icehockey',
    'iceskating',
    'island',
    'island tour',
    'jacuzzi',
    'jeep tour',
    'jodo school',
    'kayak school',
    'kickboxing',
    'kitchen',
    'lake',
    'language school',
    'livegame',
    'mall',
    'market',
    'martial arts',
    'massage',
    'massage school',
    'meditation',
    'mini golf',
    'minigolf',
    'motorbike tour',
    'mountain',
    'mountainrailway',
    'museum',
    'music school',
    'music studio',
    'musical',
    'naturepark',
    'nightclub',
    'ocean',
    'offroad tour',
    'opera',
    'paintball',
    'para sailing',
    'parfume',
    'pedicure',
    'peninsula',
    'photography school',
    'piercing',
    'planetarium',
    'playground',
    'polo club',
    'polo school',
    'pool',
    'pool table',
    'post',
    'pottery',
    'pub',
    'race',
    'racetrack',
    'rafting',
    'railway',
    'restaurant',
    'retreat',
    'riding',
    'riding school',
    'rikscha',
    'river',
    'ropepark',
    'rowing',
    'safari',
    'sailing school',
    'saloon',
    'salsa dance',
    'sauna',
    'school',
    'schoole',
    'scooter tour',
    'sea',
    'seal eatching',
    'seal watching',
    'seaport',
    'segway',
    'self defence',
    'seminar',
    'sewing',
    'shark watching',
    'shooting',
    'shooting range',
    'shop',
    'sightseeing',
    'sightseeing tour',
    'singing school',
    'skateboarding school',
    'ski school',
    'skydiving',
    'sledding',
    'sleigh rides',
    'snorkling tour',
    'snowboard school',
    'snowmobil',
    'soccer',
    'solarium',
    'spa',
    'sport',
    'squash',
    'stadion',
    'stage',
    'steam train',
    'steambath',
    'street',
    'street art',
    'studio',
    'surgin school',
    'survival shool',
    'swimming school',
    'tabletennis',
    'tatoo',
    'tennis',
    'tennis school',
    'theater',
    'theatre',
    'theatre school',
    'themepark',
    'tobogga',
    'toboggan',
    'tombo',
    'tombola',
    'train station',
    'trampolin',
    'trampoline',
    'tree',
    'treehouse',
    'trekking',
    'vineyard',
    'Volcano',
    'volleybal',
    'volleyball',
    'walking tour',
    'water park',
    'waterfall',
    'whale tour',
    'whale watching',
    'whirlpool',
    'wine',
    'wine tour',
    'writing workshop',
    'yacht tour',
    'yoga retreat',
    'zip line',
    'zoo',
    'bumper boats',
    'leisre',
    'leisurepark',
    'cat cafe',
    'airsoft',
    'car racing',
    'blood bank',
    'home',
    'fire brigade',
];

export const equipmentsTags = [
    'swimsuit',
    'game',
    'tools',
    'wood',
    'dog',
    'pumpkin',
    'hunting',
    'icehockey',
    'sportswear',
    'speaker',
    'paint',
    'puzzle',
    'juggles',
    'knife',
    'computer',
    'paper',
    'pencil',
    'groceries',
    'instrument',
    'tea',
    'tea leaf',
    'mobile',
    'trampoline',
    'frisbee',
    'unicyle',
    'vr',
    'compass',
    'car',
    'tv',
    'yacht',
    'football',
    'fish rod',
    'wine',
    'grill',
    'kitchen',
    'liquor',
    'champagne',
    'avocado',
    'beer',
    'badminton',
    'baseball',
    'basketball',
    'internet',
    'book',
    'plant',
    'erotic literature',
    'bicycle',
    'airplane',
    'skydive',
    'phone',
    'camera',
    'canvas',
    'paraglide',
    'helicopter',
    'hockey',
    'inlineskate',
    'coffee',
    'kayak',
    'cat',
    'laptop',
    'microphone',
    'beamer',
    'movie',
    'notebook',
    'gameconsole',
    'soap opera movie',
    'costume',
    'ski',
    'limousine',
    'makeup',
    'boat',
    'ice',
    'sudoku',
    'pet',
    'beauty',
    'wakeboard',
    'volleyball',
    'tabeltennis',
    'tennis',
    'diving',
    'bike',
    'motorbike',
    'paintball',
    'parfume',
    'pedalo',
    'quad',
    'sledd',
    'snowshoe',
    'skateboard',
    'snowboard',
    'surfing',
    'toy',
    'golf',
    'fireworks',
    'trottinett',
    'handcraft',
    'Action figures',
    'drum',
    'cloth',
    'sewing',
    'radio',
    'ax',
    'autopgraph',
    'vehicle',
    'chess',
    'backpack',
    'meat',
    'scissors',
    'fishing',
    'camping',
    'beestack',
    'tabletennis',
    'jewelry',
    'bath',
    'gardening',
    'aquarium',
    'candle',
    'canoe',
    'capm',
    'lights',
    'cigar',
    'knitting',
    'flower',
    'iceskate',
    'boardgame',
    'chocolate',
    'cheese',
    'bubble team',
    'whiskey',
    'sweets',
    'confiserie',
    'sangria',
    'card game',
    'board game',
    'films',
    'leather',
    'lamp',
    'lego',
    'microscop',
    'miniature golf',
    'soap',
    'metal detector',
    'mystery novel',
    'manicure',
    'nose flute',
    'seon sign',
    'plants',
    'newspaper',
    'fishih',
    'western movie',
    'orchid',
    'harmonica',
    'animal',
    'parrot',
    'trees',
    'squash',
    'ukulele',
    'palm',
    'saddle',
    'saxophone',
    'skin care',
    'stone',
    'safe',
    'sneaker',
    'slackline',
    'thimble',
    'tombola',
    'textile',
    'vinyl',
    'voucher',
    'violin',
    'drinks',
    'drone',
    'duckpin bowling',
    'pedicure',
    'gemstone',
    'gold pan',
    'glass',
    'berimbai',
    'vacuum cleaner',
    'antiquity',
    'alcohol ink',
    'banjo',
    'electric guitar',
    'french horn',
    'harp',
    'spanish guitar',
    'properties',
    'capital',
    'perfume',
    'erotic art',
    'pedal go kart',
    'rowing boat',
    'sailing boat',
    'trimmer',
    'bag',
    'windsurfing',
    'metal',
    'bow',
    'arrow',
    'airsoft',
    'fabric',
    'needle',
    'beads',
    'cord',
    'bones',
    'boot',
    'boots',
    'horse',
    'bucking bronco',
    'butter',
    'sofa',
    'keyboard',
    'yarn',
    'rope',
    'koi pond',
    'kool-aid',
    'kora',
    'kubbey',
    'archery',
    'cotton',
    'silk',
    'sky lantern',
    'solar',
    'spices',
    'string instrument',
    'yurt',
    'bagpipes',
];

export const environmentsOptions = ['indoor', 'outdoor', 'all'];
