import React, { useCallback, useState } from 'react';
import { CustomModal } from 'components/common/Modal';
import styles from './editListItem.module.scss';
import {
    Button,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    styled,
} from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import { fetchPlace } from 'helper/fetchPlace';
import debounce from 'lodash.debounce';

const StyledTextField = styled(TextField)`
    .MuiInputBase-root {
        background-color: #f2f2f2;
        border: none;
        border-radius: 5px;
        padding: 0px 10px;
        -webkit-appearance: none;
        box-sizing: border-box;
        min-height: 41px;
        width: 100%;

        /* &:focus {
            outline: none;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
        } */
    }

    & .MuiOutlinedInput-root {
        fieldset {
            border: none;
        }
        &:hover fieldset {
            border: none;
        }
        &.Mui-focused fieldset {
            border-color: #fff;
            outline: none;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
        }
    }

    .MuiInputBase-input {
        padding: 0;
        font-family: 'Arimo', sans-serif;
        font-size: 16px;
    }

    .Mui-error {
        font-family: 'Arimo', sans-serif;
        color: #ec4453;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
    }
`;

const StyledButton = styled(Button)(({ bg }) => ({
    background: bg,
    textTransform: 'Capitalize',
    color: bg === 'none' ? '#1F62AF' : '#fff',
    borderRadius: '5px',
    width: 'fit-content',
    height: '41px',
    fontSize: 18,
    fontWeight: 700,
    '&:hover': {
        background: bg,
    },
    '@media (max-width: 476px)': {
        fontSize: '15px !important',
    },
}));

const StyledSelect = styled(Select)`
    width: 100%;
    font-family: 'Arimo', sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    background-color: #f2f2f2;
    padding: 0px 10px;
    -webkit-appearance: none;
    box-sizing: border-box;
    min-height: 41px !important;

    .MuiInputBase-input {
        padding: 0;
        font-family: 'Arimo', sans-serif;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0;
        background-color: #f2f2f2;
        border: none;
        border-radius: 5px;
        padding: 0px 10px;
        -webkit-appearance: none;
        box-sizing: border-box;
        min-height: 41px !important;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .MuiOutlinedInput-root {
        width: 100%;
    }

    & .MuiOutlinedInput-notchedOutline {
        border: none;
        fieldset {
            border: none;
        }
        &:hover fieldset {
            border: none;
        }
        &.Mui-focused fieldset {
            border-color: #fff;
            outline: none;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
        }
    }

    .Mui-error {
        font-family: 'Arimo', sans-serif;
        color: #ec4453;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
    }
`;

const EditListItemModal = ({ open, setOpen }) => {
    const [place, setPlace] = useState('');
    const [autocompleteCities, setAutocompleteCities] = useState([]);
    const [autocompleteErr, setAutocompleteErr] = useState('');

    const debounceBack = useCallback(
        debounce(async (newValue) => {
            const res = await fetchPlace(newValue);
            console.log(res);
            !autocompleteCities.includes(newValue) &&
                res.features &&
                setAutocompleteCities(res.features.map((place) => place.place_name));
            res.error ? setAutocompleteErr(res.error) : setAutocompleteErr('');
        }, 1000),
        [],
    );

    const handleCityChange = (e) => {
        setPlace(e.target.value);
        debounceBack(e.target.value);
    };

    const onClose = () => {
        setOpen(false);
    };
    return (
        <CustomModal open={open} onClose={onClose} title={'Edit item'}>
            <div className={styles.createList__Box}>
                <form>
                    <div className={styles.form__field}>
                        <StyledSelect name="description" sx={{ width: '100%' }}>
                            <MenuItem selected>Only you can edit</MenuItem>
                            <MenuItem>Only friends can edit</MenuItem>
                            <MenuItem>Everyone can edit</MenuItem>
                        </StyledSelect>
                    </div>

                    <div className={styles.form__field}>
                        <StyledTextField
                            name="place"
                            placeholder="Place"
                            value={place}
                            onChange={handleCityChange}
                            sx={{ width: '100%' }}
                            // error={formik.touched.location && Boolean(formik.errors.location)}
                            // helperText={formik.touched.location && formik.errors.location}
                        />
                    </div>
                    {autocompleteCities?.length > 0 && (
                        <div className={styles.form__field}>
                            <p className={styles.text__primary}>Choose a place:</p>
                            <div className={styles.Links__Action}>
                                <RadioGroup
                                    aria-labelledby="placesList"
                                    name="placesList"
                                    // value={formik.values.ipfsHashFlag}
                                    onChange={(e) => setPlace(e.target.value)}
                                    sx={{ flexDirection: 'row' }}
                                >
                                    {autocompleteCities?.map((x, i) => (
                                        <FormControlLabel
                                            key={i}
                                            className={styles.form__labelSecondary}
                                            value={x}
                                            control={<Radio />}
                                            label={x}
                                        />
                                    ))}
                                </RadioGroup>
                            </div>
                        </div>
                    )}

                    <div className={styles.form__actions}>
                        <StyledButton bg={'none'} type="submit">
                            Save
                        </StyledButton>
                    </div>
                </form>
            </div>
        </CustomModal>
    );
};

export default EditListItemModal;
