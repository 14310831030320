import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './messages.module.scss';
import Message from 'components/Message';
import { onSnapshot, collection, query, orderBy, addDoc } from 'firebase/firestore';
import { StoreContext } from 'store/useStore';
import { db, getMessages } from 'helper/firebase';

const Messages = ({ receiverData }) => {
    const { state } = useContext(StoreContext);
    const { user } = state;
    console.log(user, ':user');
    const [allMessages, setAllMessages] = useState(null);
    const messageEndRef = useRef(null);

    useEffect(() => {
        if (receiverData) {
            getMessages(user, receiverData, setAllMessages);
        }
    }, [receiverData]);

    useEffect(() => {
        messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [allMessages]);

    console.log(allMessages, ':messages');

    return (
        <div className={styles.Message__Container}>
            {allMessages &&
                allMessages.map((message) => (
                    <Message key={message.id} owner={user.id === message?.uid} message={message} />
                ))}
            <div ref={messageEndRef}></div>
        </div>
    );
};

export default Messages;
