import React from 'react';
import css from './sectionTitle.module.scss';

const SectionTitle = ({ text, styles, classname }) => {
    return (
        <h3
            className={[css.section__title, classname ? classname : ''].join(' ')}
            style={styles && styles}
        >
            {text}
        </h3>
    );
};

export default SectionTitle;
