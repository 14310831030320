export const DATE_FILTER = [];

const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
};

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
    const month = monthIndex + 1;
    const year = new Date().getFullYear();
    const daysInMonth = getDaysInMonth(month, year);

    for (let day = 1; day <= daysInMonth; day++) {
        const label = `${day} ${months[monthIndex]}`;
        const value = (month - 1) * 31 + day;
        DATE_FILTER.push({ label, value });
    }
}
