import React, { useState } from 'react';
import styles from './chat.module.scss';
import Chats from 'components/Chats';
import ChatUsers from 'components/ChatUsers';
import ChatInput from 'components/ChatInput';

const ChatPage = () => {
    const [receiverData, setReceiverData] = useState(null);

    return (
        <div className={styles.Chat}>
            <div className={styles.Chat__Container}>
                <div className={styles.Chat__Grid}>
                    {/* Message section */}
                    <div className={styles.Chat__Area}>
                        <Chats receiverData={receiverData} />
                        <ChatInput receiverData={receiverData} />
                    </div>
                    {/* users section */}
                    <ChatUsers receiverData={receiverData} setReceiverData={setReceiverData} />
                </div>
            </div>
        </div>
    );
};

export default ChatPage;
