import React from 'react';
import { CustomModal } from 'components/common/Modal';
import styles from './editSection.module.scss';
import { Button, MenuItem, Select, TextField, styled } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';

const StyledTextField = styled(TextField)`
    .MuiInputBase-root {
        background-color: #f2f2f2;
        border: none;
        border-radius: 5px;
        padding: 0px 10px;
        -webkit-appearance: none;
        box-sizing: border-box;
        min-height: 41px;
        width: 100%;

        /* &:focus {
            outline: none;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
        } */
    }

    & .MuiOutlinedInput-root {
        fieldset {
            border: none;
        }
        &:hover fieldset {
            border: none;
        }
        &.Mui-focused fieldset {
            border-color: #fff;
            outline: none;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
        }
    }

    .MuiInputBase-input {
        padding: 0;
        font-family: 'Arimo', sans-serif;
        font-size: 16px;
    }

    .Mui-error {
        font-family: 'Arimo', sans-serif;
        color: #ec4453;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
    }
`;

const StyledButton = styled(Button)(({ bg }) => ({
    background: bg,
    textTransform: 'Capitalize',
    color: bg === 'none' ? '#1F62AF' : '#fff',
    borderRadius: '5px',
    width: 'fit-content',
    height: '41px',
    fontSize: 18,
    fontWeight: 700,
    '&:hover': {
        background: bg,
    },
    '@media (max-width: 476px)': {
        fontSize: '15px !important',
    },
}));

const StyledSelect = styled(Select)`
    width: 100%;
    font-family: 'Arimo', sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    background-color: #f2f2f2;
    padding: 0px 10px;
    -webkit-appearance: none;
    box-sizing: border-box;
    min-height: 41px !important;

    .MuiInputBase-input {
        padding: 0;
        font-family: 'Arimo', sans-serif;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0;
        background-color: #f2f2f2;
        border: none;
        border-radius: 5px;
        padding: 0px 10px;
        -webkit-appearance: none;
        box-sizing: border-box;
        min-height: 41px !important;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .MuiOutlinedInput-root {
        width: 100%;
    }

    & .MuiOutlinedInput-notchedOutline {
        border: none;
        fieldset {
            border: none;
        }
        &:hover fieldset {
            border: none;
        }
        &.Mui-focused fieldset {
            border-color: #fff;
            outline: none;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
        }
    }

    .Mui-error {
        font-family: 'Arimo', sans-serif;
        color: #ec4453;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
    }
`;

const EditListSectionModal = ({ open, setOpen }) => {
    const onClose = () => {
        setOpen(false);
    };
    return (
        <CustomModal open={open} onClose={onClose} title={'Edit section'}>
            <div className={styles.createList__Box}>
                <form>
                    <div className={styles.form__field}>
                        <StyledTextField
                            name="title"
                            placeholder="Title*"
                            // value={formik.values.name}
                            // onChange={formik.handleChange}
                            sx={{ width: '100%' }}
                            // error={formik.touched.name && Boolean(formik.errors.name)}
                            // helperText={formik.touched.name && formik.errors.name}
                        />
                    </div>
                    <div className={styles.form__field}>
                        <StyledSelect name="description" sx={{ width: '100%' }}>
                            <MenuItem selected>Participants can vote</MenuItem>
                            <MenuItem>Only friends can vote</MenuItem>
                        </StyledSelect>
                    </div>

                    <div className={styles.form__field}>
                        <StyledSelect name="description" sx={{ width: '100%' }}>
                            <MenuItem selected>1 items</MenuItem>
                            <MenuItem>2 items</MenuItem>
                            <MenuItem>3 items</MenuItem>
                        </StyledSelect>
                    </div>

                    <div className={styles.form__actions}>
                        <StyledButton bg={'none'} type="submit">
                            Save
                        </StyledButton>
                    </div>
                </form>
            </div>
        </CustomModal>
    );
};

export default EditListSectionModal;
