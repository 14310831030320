import React from 'react';
import { FaChevronLeft } from 'react-icons/fa6';
import styles from './mobileHeader.module.scss';
import { useNavigate } from 'react-router-dom';

const MobileHeader = ({ title, isHide, setTab }) => {
    const navigate = useNavigate();
    return (
        <div className={styles.mobileHeader}>
            <FaChevronLeft
                onClick={() => {
                    if (isHide) {
                        setTab(1);
                    } else {
                        navigate(-1);
                    }
                }}
                color="#000"
                fontSize={'18px'}
            />
            {!isHide && <h3>{title}</h3>}
        </div>
    );
};

export default MobileHeader;
