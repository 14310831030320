import React, { useContext } from 'react';
import styles from './cards.module.scss';
import { StoreContext } from 'store/useStore';
import Card from 'components/Card';

const Cards = () => {
    const { state, dispatch } = useContext(StoreContext);
    const { cardsData, searchedData } = state;
    const renderCardsData = () => {
        return (
            <>
                {cardsData && cardsData?.length ? (
                    cardsData?.map((card, idx) => <Card key={idx} data={card} />)
                ) : (
                    <p>No Records Found</p>
                )}
            </>
        );
    };

    const renderSearchData = () => {
        return (
            <>
                {searchedData && searchedData?.length ? (
                    searchedData?.map((card, idx) => <Card key={idx} data={card} />)
                ) : (
                    <p>No Records Found</p>
                )}
            </>
        );
    };
    return (
        <div className={styles.cards__container}>
            {searchedData ? renderSearchData() : renderCardsData()}
            {/* {cardsData && cardsData?.length ?
                cardsData?.map((card, idx) => (
                    <Card key={idx} data={card} />
                )) : (
                    <p>No Records Found</p>
            )} */}
        </div>
    );
};

export default Cards;
