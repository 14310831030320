export const getErrorMsg = (error) => {
    let match = JSON.stringify(error.toString()).match(/\((.*?)\)/); // extracts text inside parentheses
    let errorMsg = match[1]; // gets the extracted text

    // convert error string to "Email Already In Use" format
    let errorMsgFormatted = errorMsg
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    return errorMsgFormatted;
};
