import React, { useEffect, useState } from 'react';
import styles from './availableUser.module.scss';
import avatar from 'assets/images/avatar1.png';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ProfilePicture from 'components/ProfilePicture';

const AvailableUser = ({ userInfo, setReceiverData, receiverData }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get('user');
    // console.log(userId);
    // let { id } = useParams();

    const openChat = () => {
        setReceiverData({
            username: userInfo?.username,
            userId: userInfo?.userId,
        });

        const userId = userInfo?.userId;
        const newUrl = `/chat?users=${userId}`;
        navigate(newUrl);
    };

    useEffect(() => {
        console.log(userInfo?.userId);
        if (userId !== null && userInfo?.userId == userId) {
            setReceiverData({
                username: userInfo.username,
                userId: userInfo.userId,
            });
        }
    }, [userInfo]);

    return (
        <div
            className={`${styles.User} ${
                receiverData?.userId === userInfo?.userId && styles.selected
            }`}
            onClick={openChat}
        >
            <div className={styles.User__Avatar}>
                {/* <img src={avatar} alt="" /> */}
                {/* <PersonOutlineIcon /> */}
                <ProfilePicture userId={userInfo?.userId} />
            </div>
            <div className={styles.User__Info}>
                <h5>{userInfo?.username}</h5>
                <p>Hey how are you</p>
            </div>
        </div>
    );
};

export default AvailableUser;
