import React, { useContext, useState } from 'react';
import styles from './chatInput.module.scss';
import { StoreContext } from 'store/useStore';
import { addDoc, collection } from 'firebase/firestore';
import { db, sendMessage } from 'helper/firebase';

const ChatInput = ({ receiverData }) => {
    const { state } = useContext(StoreContext);
    const [inputMessage, setInputMessage] = useState('');
    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         if (state.user && receiverData) {
    //             await addDoc(
    //                 collection(
    //                     db,
    //                     'users',
    //                     state.user.uid,
    //                     'chatUsers',
    //                     receiverData.userId,
    //                     'messages',
    //                 ),
    //                 {
    //                     username: state.user.displayName,
    //                     messageUserId: state.user.uid,
    //                     message: inputMessage,
    //                     timestamp: new Date(),
    //                 },
    //             );
    //             await addDoc(
    //                 collection(
    //                     db,
    //                     'users',
    //                     receiverData.userId,
    //                     'chatUsers',
    //                     state.user.uid,
    //                     'messages',
    //                 ),
    //                 {
    //                     username: state.user.displayName,
    //                     messageUserId: state.user.uid,
    //                     message: inputMessage,
    //                     timestamp: new Date(),
    //                 },
    //             );
    //             setInputMessage('');
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!inputMessage || inputMessage === '') return;
            if (!state.user || !receiverData) return;
            const sender = {
                uid: parseInt(state.user.id),
                displayName: state.user.username,
            };
            sendMessage(state.user, receiverData, inputMessage);
            setInputMessage('');
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div className={styles.ChatInput}>
            <div className={styles.hrLine}></div>
            <form onSubmit={handleSubmit}>
                <div className={styles.form__field}>
                    <input
                        type="text"
                        placeholder="Type a message"
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                        disabled={!receiverData}
                    />
                </div>
            </form>
        </div>
    );
};

export default ChatInput;
