import { CustomModal } from 'components/common/Modal';
import styles from './addActivity.module.scss';
import { Button, styled } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';

const StyledButton = styled(Button)(({ bg }) => ({
    background: bg,
    textTransform: 'Capitalize',
    color: bg === 'none' ? '#1F62AF' : '#fff',
    borderRadius: '5px',
    width: 'fit-content',
    height: '41px',
    fontSize: 18,
    fontWeight: 700,
    '&:hover': {
        background: bg,
    },
    '@media (max-width: 476px)': {
        fontSize: '15px !important',
    },
}));

const AddActivityModal = ({ open, setOpen }) => {
    const onClose = () => setOpen(false);
    return (
        <CustomModal open={open} onClose={onClose} title={'Add activity'}>
            <div className={styles.actions__box}>
                <div className={styles.activity__actions}>
                    <div className={styles.input__field}>
                        <input type="text" placeholder="Name of new activity" />
                        <div className={styles.search__icon}>
                            <SearchOutlined sx={{ width: '23px', height: '23px' }} />
                        </div>
                    </div>

                    <div className={styles.activity__suggestions}>
                        <p className={styles.suggestions__info}>Do you mean one of these?</p>
                        <div className={styles.suggestion__list}>
                            <p>Building sand caste</p>
                            <p>Walking on the beach</p>
                            <p>Swimming at thea beach</p>
                            <p>Visit historical castle</p>
                            <p>Architectual phootography</p>
                        </div>
                    </div>

                    <div className={styles.term__checked}>
                        <input type="checkbox" name="term" id="" />
                        <p>No, it’s none of the above, create a new activity now (Guidelines).</p>
                    </div>
                    <div className={styles.submitBtn}>
                        <StyledButton bg="none">Add</StyledButton>
                    </div>
                </div>
            </div>
        </CustomModal>
    );
};

export default AddActivityModal;
