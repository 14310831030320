import React from 'react';
import styles from './chatUsers.module.scss';
import AvailableUsers from 'components/AvailableUsers';

const ChatUsers = ({ receiverData, setReceiverData }) => {
    return (
        <div className={styles.Users__Container}>
            <h4 className={styles.header}>Chats</h4>
            <AvailableUsers receiverData={receiverData} setReceiverData={setReceiverData} />
        </div>
    );
};

export default ChatUsers;
